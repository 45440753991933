import React from 'react';
import Section from '../../Components/Section';
import styles from './index.module.scss';
import { TabProps } from '../shared/types';

const ForeseeableEventsTab = ({ idx, answers, setAnswers, stabilityPlanType }: TabProps) => {
    const {
        foreseeableEvents1,
        foreseeableEvents2,
        foreseeableEventsActions1,
        foreseeableEventsActions2,
    } = answers;

    return (
        <Section
            number={idx}
            title="Foreseeable Events"
            tooltip="As you take the next steps towards feeling better, what are two foreseeable events or changes that could make you feel overwhelmed, suicidal, or put you into crisis?"
        >
            <div className={styles.container}>
                {stabilityPlanType === 'stanley brown' && (
                    <>
                        <div className={styles.row}>
                            <div className={styles.question}>
                                As you take the next steps towards feeling better, what are two
                                forseeable events or changes that could make you feel overwhelmed,
                                suicidal, or put you into a crisis?
                            </div>
                        </div>
                        <div className={styles.row}>
                            1.{' '}
                            <input
                                value={foreseeableEvents1}
                                onChange={(e) => {
                                    setAnswers((answers) => ({
                                        ...answers,
                                        foreseeableEvents1: e.target.value,
                                    }));
                                }}
                                placeholder="Type your response here"
                            />
                        </div>
                        <div className={styles.row}>
                            <div className={styles.question}>
                                What can you or someone else do if this happens?
                            </div>
                            <input
                                value={foreseeableEventsActions1}
                                onChange={(e) => {
                                    setAnswers((answers) => ({
                                        ...answers,
                                        foreseeableEventsActions1: e.target.value,
                                    }));
                                }}
                                placeholder="Type your response here"
                            />
                        </div>
                        <div className={styles.row}>
                            2.{' '}
                            <input
                                value={foreseeableEvents2}
                                onChange={(e) => {
                                    setAnswers((answers) => ({
                                        ...answers,
                                        foreseeableEvents2: e.target.value,
                                    }));
                                }}
                                placeholder="Type your response here"
                            />
                        </div>
                        <div className={styles.row}>
                            <div className={styles.question}>
                                What can you or someone else do if this happens?
                            </div>
                            <input
                                value={foreseeableEventsActions2}
                                onChange={(e) => {
                                    setAnswers((answers) => ({
                                        ...answers,
                                        foreseeableEventsActions2: e.target.value,
                                    }));
                                }}
                                placeholder="Type your response here"
                            />
                        </div>
                    </>
                )}
            </div>
        </Section>
    );
};

export default ForeseeableEventsTab;
