const TrainingIcon = ({ width = '23' }) => {
    return (
        <svg
            width={width}
            height={width}
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="11.5" cy="11.5" r="11.5" fill="#117383" />
            <path
                d="M10.5728 18V9.104H7.54881V7.536H15.4528V9.104H12.4288V18H10.5728Z"
                fill="white"
            />
        </svg>
    );
};

export default TrainingIcon;
