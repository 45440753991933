import Button from 'components/Button';
import styles from './index.module.scss';
import { QuestionProps } from '../../question';

type AssessmentLockQuestionProps = Pick<
    QuestionProps,
    'setAnswered' | 'answered' | 'next' | 'activities' | 'lockActivity'
>;

const AssessmentLockQuestion = ({
    next,
    activities,
    lockActivity,
}: AssessmentLockQuestionProps): JSX.Element => {
    const outro = activities.find((activity) => activity.type === 'outro');

    const done = () => {
        if (outro) {
            lockActivity(outro);
        }
    };

    return (
        <div className={styles.buttons}>
            <Button onClick={done} dark>
                I'm done
            </Button>
        </div>
    );
};

export { AssessmentLockQuestion };
export default AssessmentLockQuestion;
