const Skills = ({
    color = '#ffffff',
    height = 20,
    ariaHidden = false,
}: {
    color?: string;
    height?: number;
    ariaHidden?: boolean;
}) => {
    const width = height * 0.913;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 42 46"
            fill="none"
            aria-hidden={ariaHidden ? 'true' : 'false'}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.8144 23.7864C34.2371 23.4719 33.6392 23.2163 33.0412 23C33.6392 22.7837 34.2371 22.5281 34.8144 22.2136C38.7732 20.0313 40.9794 16.0795 41 12.0098C37.3093 9.98481 32.6082 9.82752 28.6289 12.0098C28.0515 12.3244 27.5155 12.6979 27.0206 13.0715C27.1237 12.462 27.1856 11.8329 27.1856 11.2038C27.1856 6.83914 24.6907 3.04468 21 1C17.3093 3.04468 14.8144 6.83914 14.8144 11.2038C14.8144 11.8329 14.8763 12.462 14.9794 13.0715C14.4845 12.6783 13.9485 12.3047 13.3711 11.9902C9.41237 9.80786 4.71134 9.96515 1 11.9902C1 16.0599 3.20619 20.0116 7.18557 22.1939C7.76289 22.5085 8.36083 22.7641 8.95876 22.9803C8.36083 23.1966 7.76289 23.4522 7.18557 23.7668C3.2268 25.9491 1.02062 29.9008 1 33.9705C4.69072 35.9955 9.39175 36.1528 13.3711 33.9705C13.9485 33.6559 14.4845 33.2824 14.9794 32.9088C14.8763 33.538 14.8144 34.1671 14.8144 34.7962C14.8144 39.1609 17.3093 42.9553 21 45C24.6907 42.9553 27.1856 39.1609 27.1856 34.7962C27.1856 34.1671 27.1237 33.538 27.0206 32.9285C27.5155 33.3217 28.0515 33.6756 28.6289 33.9902C32.5876 36.1725 37.2887 36.0152 41 33.9902C40.9794 29.9205 38.7732 25.9687 34.8144 23.7864V23.7864Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 30C17.1325 30 14 26.8675 14 23C14 19.1325 17.1325 16 21 16C24.8675 16 28 19.1325 28 23C28 26.8675 24.8675 30 21 30V30Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Skills;
