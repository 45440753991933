import { useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import StoreContext from 'state/context/store';
import { logout, triggerHeartbeat } from 'state/actions/user';

interface useTechnicianIdleTimerReturn {
    activate: () => void;
    pause: () => void;
}

const useTechnicianIdleTimer = (): useTechnicianIdleTimerReturn => {
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { authenticated } = user;

    const onIdle = () => {
        logout(dispatch, 'technician', false);
        pause();
    };

    const onActive = () => {
        // onActive will only be called if `reset()` is called while `isPrompted() is true
        if (authenticated) {
            triggerHeartbeat(dispatch, 'technician');
        }
    };

    const onAction = () => {
        // onAction will trigger when a watched event is triggered
        if (authenticated) {
            triggerHeartbeat(dispatch, 'technician');
        }
    };

    const { activate, pause } = useIdleTimer({
        // NOTE: workerTimers is not compatible with ie11.  Keep disabled until ie11 support is officially dropped
        //timers: workerTimers,
        timeout: 5 * 1_000 * 60, // 5 minutes,
        onIdle,
        onAction,
        onActive,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'visibilitychange',
            'MSPointerDown', // Remove when dropping ie11 support
            'MSPointerMove', // Remove when dropping ie11 support
        ],
        throttle: 60_000,
        eventsThrottle: 200,
        startOnMount: true,
    });

    return {
        activate,
        pause,
    };
};

export default useTechnicianIdleTimer;
