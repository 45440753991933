import { useContext, useState } from 'react';
import Button from 'components/Button';
import StoreContext from 'state/context/store';
import styles from './index.module.scss';
import ScaleButtons from 'components/ConversationalUi/questions/scalebuttons';
import { AnswerContext } from 'components/ConversationalUi/context';
import { saveAnswers } from 'state/actions/assessment';
import { Patient } from 'state/types';
import { AssessmentConstants } from 'state/constants';
import useAnswers from 'lib/useAnswers';

interface CheckinProps {
    close: () => void;
}

const Checkin = ({ close }: CheckinProps) => {
    const [store, dispatch] = useContext(StoreContext);
    const [screen, setScreen] = useState<'intro' | 'distress' | 'agitation' | 'finish'>('intro');
    const [answered, setAnswered] = useState(false);
    const [updatedAnswers, setUpdatedAnswers] = useState<{
        csFrustration1?: number;
        csDistress1?: number;
    }>({});
    const { isDemoPatient } = store.user as Patient;
    const [answers] = useAnswers();

    const back = () => {
        if (screen === 'finish') {
            setScreen('agitation');
        } else if (screen === 'agitation') {
            setScreen('distress');
        } else if (screen === 'distress') {
            setScreen('intro');
        } else if (screen === 'intro') {
            close();
        }
    };

    const next = () => {
        if (screen === 'intro') {
            setScreen('distress');
        } else if (screen === 'distress') {
            setScreen('agitation');
        } else if (screen === 'agitation') {
            setAnswered(true);
            setScreen('finish');
        } else if (screen === 'finish') {
            if (!isDemoPatient) {
                saveAnswers(dispatch, updatedAnswers);
            } else {
                // Save answers locally when operating as a demo
                dispatch({
                    type: AssessmentConstants.UPDATE_ANSWERS,
                    answers: { ...answers, ...updatedAnswers },
                });
            }
            close();
        }
    };

    return (
        <AnswerContext.Provider
            value={{
                answers: updatedAnswers,
                updateAnswers: setUpdatedAnswers,
            }}
        >
            <div className={styles.container}>
                <div
                    className={styles.screen}
                    style={{ display: screen === 'intro' ? 'flex' : 'none' }}
                >
                    <p>
                        We'd like to check in with how you're feeling, are you at a spot to pause
                        and checkin?
                    </p>
                </div>
                <div
                    className={styles.screen}
                    style={{ display: screen === 'distress' ? 'flex' : 'none' }}
                >
                    <p>How would you rate your current level of distress?</p>
                    <ScaleButtons
                        answered={answered}
                        setAnswered={setAnswered}
                        answerKey="csDistress1"
                        min={1}
                        minLabel="Low Distress"
                        max={10}
                        maxLabel="High Distress"
                        sizeOverride={45}
                        answers={answers}
                    />
                </div>
                <div
                    className={styles.screen}
                    style={{ display: screen === 'agitation' ? 'flex' : 'none' }}
                >
                    <p>How would you rate your current level of frustration and agitation?</p>
                    <ScaleButtons
                        answered={answered}
                        setAnswered={setAnswered}
                        answerKey="csFrustration1"
                        min={1}
                        minLabel="Low Frustration"
                        max={10}
                        maxLabel="High Frustration"
                        sizeOverride={45}
                        answers={answers}
                    />
                </div>
                <div
                    className={styles.screen}
                    style={{ display: screen === 'finish' ? 'flex' : 'none' }}
                >
                    <p>
                        Thanks for taking the time to let us know how you're doing and just so you
                        know, you may see these questions again while using Jaspr.
                    </p>
                </div>
                <div className={styles.buttons}>
                    <Button variant="secondary" onClick={back}>
                        {screen === 'intro' ? 'Not Now' : 'Go Back'}
                    </Button>
                    <Button dark onClick={next}>
                        {(screen === 'intro' && 'Continue') || ''}
                        {((screen === 'distress' || screen === 'agitation') && 'Next') || ''}
                        {(screen === 'finish' && 'Done') || ''}
                    </Button>
                </div>
            </div>
        </AnswerContext.Provider>
    );
};

export default Checkin;
