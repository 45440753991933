import axios, { AxiosError, AxiosResponse } from 'axios';
import { errorInterceptor } from 'lib/api';
import config from 'config';
import { UserConstants } from 'state/constants';
import { Dispatch } from 'state/types';
import { PostResponse as TechnicianPostResponse } from 'state/types/api/technician/heartbeat';
import { PostResponse as PatientPostResponse } from 'state/types/api/patient/heartbeat';

const triggerPatientHeartbeat = async (dispatch: Dispatch): Promise<AxiosResponse | undefined> => {
    try {
        const response = await axios.post<PatientPostResponse>(
            `${config.apiRoot}/patient/heartbeat`,
            {},
        );
        dispatch({
            type: UserConstants.HEARTBEAT,
        });
        return response;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
            return axiosError.response;
        }
    }
};

const triggerTechnicianHeartbeat = async (
    dispatch: Dispatch,
): Promise<AxiosResponse | undefined> => {
    try {
        const response = await axios.post<TechnicianPostResponse>(
            `${config.apiRoot}/technician/heartbeat`,
            {},
        );
        dispatch({
            type: UserConstants.HEARTBEAT,
        });
        return response;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
            return axiosError.response;
        }
    }
};

export const triggerHeartbeat = async (
    dispatch: Dispatch,
    userType?: 'technician' | 'patient' | '',
): Promise<AxiosResponse | undefined> => {
    axios.interceptors.response.use(...errorInterceptor(dispatch));
    if (userType === 'technician') {
        return triggerTechnicianHeartbeat(dispatch);
    } else if (userType === 'patient') {
        return triggerPatientHeartbeat(dispatch);
    }
    // Unknown user type
    return await new Promise((resolve, reject) => reject());
};
