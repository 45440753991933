import axios, { AxiosError, AxiosResponse } from 'axios';
import { errorInterceptor } from 'lib/api';
import config from 'config';
import { Dispatch } from 'state/types';

export const setCurrentEncounterConsent = async (
    dispatch: Dispatch,
    isMinorConsent: boolean,
): Promise<AxiosResponse | undefined> => {
    axios.interceptors.response.use(...errorInterceptor(dispatch));
    try {
        const response = await axios.post<any>(
            `${config.apiRoot}/patient/current-encounter-consent`,
            {
                isMinorConsent: isMinorConsent,
            },
        );

        return response;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
            return axiosError.response;
        }
    }
};
