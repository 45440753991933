import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import logo from 'assets/logo.svg';
import Button from 'components/Button';
import styles from './index.module.scss';
import { PatientSerializer } from 'state/types/api/sharedSerializers';

import StoreContext from 'state/context/store';

import * as Sentry from '@sentry/browser';
import { activatePatientFromPin, activateTrainingPatientFromPin } from 'state/actions/user';
import { AxiosError } from 'axios';
import { PostErrorResponse } from 'state/types/api/patient/tablet-pin';

import { Device } from 'state/types';
import { setDevice } from 'state/actions/device';
import Segment, { AnalyticNames } from 'lib/segment';

const PIN_LENGTH = 6;

interface PinProps {
    setPatient: (patient: PatientSerializer) => void;
    setTechnicianOperated: (technicianOperated: boolean) => void;
}

const Pin = ({ setPatient, setTechnicianOperated }: PinProps) => {
    const routeMatch = useRouteMatch<{ codeType: 'department' | 'system'; code: string }>();
    const { code: codeParam, codeType: codeTypeParam } = routeMatch.params;
    const [config, setConfig] = useState({ codeType: '', code: '' });
    const [showDiagnostics, setShowDiagnostics] = useState(false);
    // IOS Only.  Will return false in all other browsers, regardless of install state
    const appInstalled = ('standalone' in window.navigator &&
        (window.navigator as any).standalone) as boolean;

    const inputRef = useRef<HTMLInputElement | null>(null);

    const history = useHistory();
    const location = useLocation();

    const [store, dispatch] = useContext(StoreContext);
    const [pin, setPin] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const setConfiguration = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (config.codeType && config.code) {
            // We want to ensure the browser reloads so that the manifest gets set and loaded within the 5 second browser limit
            window.location.href = `${window.location.origin}/start-patient-session/${config.codeType}/${config.code}`;
        }
    };

    const resetSubmission = () => {
        setSubmitting(false);
        setPin('');
        setError('');
    };

    const handleSubmissionError = (error) => {
        const axiosError = error as AxiosError<PostErrorResponse>;

        // Connection issues with the server (CORS, timeout, no internet)
        setSubmitting(false);
        const code = store.device?.code ?? null;
        const codeType = store.device?.codeType ?? null;
        const response = axiosError.response;

        if (axiosError.code === 'ECONNABORTED') {
            setError('Request timed out waiting for the server.');
        } else if (response === undefined) {
            setError('Unable to connect to server.');
        } else if (response && response.status === 401) {
            Sentry.captureException(
                `There was an error submitting pin ${pin} for ${codeType} ${code}`,
            );
            setError(`Tablet not configured for ${codeType} ${code}`);
            setSubmitting(false);
            setPin('');
        } else if (response && response.data) {
            const { departmentCode, pinCode, nonFieldErrors } = response.data;
            const error = departmentCode?.[0] || pinCode?.[0] || nonFieldErrors?.[0];
            if (error) {
                setError(error);
            } else {
                // A valid but unknown response from the server (e.g. an unaccounted for 400 error)
                Sentry.captureException(
                    `There was an error submitting pin ${pin} for department ${codeType} ${code} with response ${
                        response.status
                    } ${response.data.toString()}`,
                );
                setError(`There was an unknown error with code: ${response.status}`);
            }

            setSubmitting(false);
            setPin('');
        } else {
            Sentry.captureException(
                `There was an error submitting pin ${pin} for ${codeType} with code ${code} ${axiosError.code} and message ${axiosError.message}`,
            );
            setError('There was an unexpected error.');
        }
    };

    const handleTrainingPin = (pin: string) => {
        let patient;
        let technicianOperated = false;

        if (pin === '000000') {
            patient = activateTrainingPatientFromPin(dispatch, false);
        } else if (pin === '111111') {
            technicianOperated = true;
            patient = activateTrainingPatientFromPin(dispatch, true);
        }

        setPatient(patient);
        setTechnicianOperated(technicianOperated);
        resetSubmission();
    };

    const handleSubmit = async (pin) => {
        try {
            const response = await activatePatientFromPin(dispatch, codeTypeParam, codeParam, pin);
            setPatient(response.data.patient);
            setTechnicianOperated(response.data.technicianOperated);
            resetSubmission();
        } catch (error) {
            handleSubmissionError(error);
        }
    };

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (pin.length !== 6) {
            setError('Please enter the full 6-digit PIN');
            return;
        }

        setError('');
        setSubmitting(true);

        // Directly handle training PINs within the submit function
        if (pin === '000000' || pin === '111111') {
            handleTrainingPin(pin);
        } else {
            await handleSubmit(pin);
        }
    };

    const handleClick = () => {
        // Set focus to the PIN input box
        inputRef.current?.focus();
    };

    /** Activate focus on PIN input wherever on the page the user might click */
    useEffect(() => {
        // Add event listener to the document
        document.addEventListener('click', handleClick);

        // Remove event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    /** If there is an application update and the user has not begun to interact with the app
     * then automatically update to the latest version
     */
    useEffect(() => {
        if (store.device.updateAvailable && !pin && !error) {
            window.location.reload();
        }
    }, [error, pin, store.device.updateAvailable]);

    useEffect(() => {
        if (codeParam && codeTypeParam) {
            const device: Partial<Device> = {
                isTablet: true,
                code: codeParam,
                codeType: codeTypeParam,
            };
            setDevice(dispatch, device);
            Segment.track(AnalyticNames.SET_DEVICE, device);
            window.sessionStorage.setItem('code', codeParam);
            window.sessionStorage.setItem('codeType', codeTypeParam);
        }
    }, [dispatch, codeParam, codeTypeParam]);

    useEffect(() => {
        if (store.user.authenticated && store.user.userType === 'patient' && !submitting) {
            // If user refreshes page after logging in, don't show PIN page
            history.replace('/');
        }
    }, [history, store.user.authenticated, store.user.userType, submitting]);

    useEffect(() => {
        // Disable scrolling on IOS when the keyboard is open
        const scrollReset = () => {
            window.scrollTo(0, 0);
        };
        window.addEventListener('scroll', scrollReset);

        return () => {
            window.removeEventListener('scroll', scrollReset);
        };
    }, []);

    return (
        <div className={styles.pinEntry}>
            <img src={logo} alt="" />
            {codeTypeParam && codeParam && (
                <form className={styles.center} aria-labelledby="pinEntryLabel" onSubmit={submit}>
                    <span
                        id="pinEntryLabel"
                        className={styles.instructions}
                        style={{ visibility: submitting ? 'hidden' : 'visible' }}
                    >
                        Enter in 6-digit code:
                    </span>
                    <input
                        autoComplete="off"
                        autoCapitalize="characters"
                        // IOS Safari does not support opening the keyboard without an initial interaction.
                        // Since this is the starting page for the PWA, they will always have to click
                        // to open the keyboard.  There is no way around it as of 5/2022
                        autoFocus
                        ref={inputRef}
                        maxLength={PIN_LENGTH}
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        pattern={`[A-Za-z0-9]{${PIN_LENGTH}}`}
                        tabIndex={0}
                        aria-required="true"
                        aria-invalid={!!error}
                        aria-describedby="pin-error" // associates the error message with the input
                    />

                    {!submitting && (
                        <Button aria-label="Submit PIN" type="submit">
                            Submit
                        </Button>
                    )}
                    {submitting && (
                        <span className={styles.validating} role="status" aria-live="polite">
                            Validating Pin...
                        </span>
                    )}
                    {!store.user?.online && (
                        <div className={styles.error}>
                            Your device is not connected to the internet. Unable to submit PIN.
                        </div>
                    )}

                    <div id="pin-error" className={styles.error} role="alert">
                        {error}
                    </div>
                </form>
            )}
            {(!codeTypeParam || !codeParam) && (
                <div className={styles.notConfigured}>
                    <span className={styles.setupInstructions}>
                        Your device is not configured for PIN activations. Please contact your JASPR
                        IT support person. If you know your configuration CODE, you may enter it
                        below.
                    </span>
                    <form onSubmit={setConfiguration}>
                        <label>
                            Code Type
                            <select
                                value={config.codeType}
                                onChange={(e) =>
                                    setConfig((config) => ({
                                        ...config,
                                        codeType: e.target.value,
                                    }))
                                }
                            >
                                <option></option>
                                <option value="system">System</option>
                                <option value="department">Department</option>
                            </select>
                        </label>
                        <label>
                            Code
                            <input
                                type="text"
                                value={config.code}
                                onChange={(e) =>
                                    setConfig((config) => ({
                                        ...config,
                                        code: e.target.value,
                                    }))
                                }
                            />
                        </label>
                        <Button type="submit">Set Configuration</Button>
                    </form>
                </div>
            )}
            <Link to={{ pathname: '/login', search: location.search }} className={styles.goLogin}>
                Go to Login
            </Link>
            <div
                className={styles.diagnostics}
                style={{ display: showDiagnostics ? 'flex' : 'none' }}
            >
                <span>Tablet: {(store.device?.isTablet || '').toString()}</span>
                <span>App Installed: {appInstalled ? 'True' : 'False'}</span>
                <span>Code: {store.device?.code}</span>
                <span>Code Type: {store.device?.codeType}</span>
                <span>
                    Version:
                    {` ${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_BUILD_NUMBER}`}
                </span>
            </div>
            <span
                className={styles.infoIcon}
                onClick={() => {
                    setShowDiagnostics((showDiagnostics) => !showDiagnostics);
                }}
            >
                ⓘ
            </span>
        </div>
    );
};

export default Pin;
