import axios, { AxiosError, AxiosResponse } from 'axios';
import { errorInterceptor } from 'lib/api';
import config from 'config';
import { Dispatch } from 'state/types';
import { SkillsConstants } from 'state/constants';
import { GetResponse } from 'state/types/api/skills';

export const getSkills = async (dispatch: Dispatch): Promise<AxiosResponse | undefined> => {
    axios.interceptors.response.use(...errorInterceptor(dispatch));
    try {
        const response = await axios.get<GetResponse>(`${config.apiRoot}/skills`);
        const json = response.data;
        dispatch({
            type: SkillsConstants.SET_SKILLS,
            skills: json,
        });
        return response;
    } catch (error) {
        const axiosError = error as AxiosError;
        const { response } = axiosError;
        // No extra error handling
        return response;
    }
};
