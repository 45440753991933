import { SkillsConstants } from 'state/constants';
import { Skills } from 'state/types';
import { ActionSetSkills } from 'state/types/actions';

export type SkillReducerState = Skills;

export type SkillsReducerType = ActionSetSkills;

const initialState: SkillReducerState = [];

const SkillsReducer = (
    state: SkillReducerState = initialState,
    action: SkillsReducerType,
): SkillReducerState => {
    switch (action.type) {
        case SkillsConstants.SET_SKILLS:
            return [...action.skills.sort((a, b) => a.order - b.order)];
        default:
            return state;
    }
};

export { SkillsReducer, initialState };
