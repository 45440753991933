import React from 'react';
import styles from './index.module.scss';
import { AssessmentAnswers } from 'state/types';

type StabilityCardProps = {
    empty?: boolean;
    stabilityPlanLabel: string;
    contactGroups: any;
    answers: AssessmentAnswers;
};

const Section = ({ title, children }: { title: string; children: React.ReactNode }) => {
    return (
        <div className={styles.section}>
            <div className={styles.heading}>{title}</div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};

const StanleyBrownStabilityCard = ({
    empty = false,
    answers = {},
    stabilityPlanLabel,
    contactGroups,
}: StabilityCardProps) => {
    const {
        copingTop = [],
        reasonsLive = [],
        wsTop = [],
        placesDistract,
        supportivePeople,
    } = answers;

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.header}>My {stabilityPlanLabel}</div>
                <Section title="Step 1: Warning Signs">
                    <ol>
                        {Array.isArray(wsTop) && wsTop.map((sign) => <li key={sign}>{sign}</li>)}
                    </ol>
                </Section>
                <Section title="Step 2: Internal Coping Skills">
                    <ol>
                        {Array.isArray(copingTop) &&
                            copingTop.map((sign) => <li key={sign}>{sign}</li>)}
                    </ol>
                </Section>
                <Section title="Step 3: People and Places">
                    <>
                        {Array.isArray(supportivePeople) &&
                            supportivePeople
                                .filter((person) => person.type === 'distract')
                                .map((person) => (
                                    <div className={styles.row}>
                                        <span className={styles.column}>Name: {person.name}</span>
                                        <span className={styles.column}>
                                            Number: {person.phone}
                                        </span>
                                    </div>
                                ))}
                        {Array.isArray(placesDistract) &&
                            placesDistract.map((place) => (
                                <div className={styles.row}>Place: {place}</div>
                            ))}
                    </>
                </Section>
                <Section title="Step 4: Supportive People">
                    {Array.isArray(supportivePeople) &&
                        supportivePeople
                            .filter((person) => person.type === 'family')
                            .map((person) => (
                                <div className={styles.row}>
                                    <span className={styles.column}>Name: {person.name}</span>
                                    <span className={styles.column}>Number: {person.phone}</span>
                                </div>
                            ))}
                </Section>
                <Section title="Step 5: Professionals or Agencies">
                    {Array.isArray(supportivePeople) &&
                        supportivePeople
                            .filter((person) => person.type === 'professional')
                            .map((person) => (
                                <div className={styles.row}>
                                    <span className={styles.column}>Name: {person.name}</span>
                                    <span className={styles.column}>Number: {person.phone}</span>
                                </div>
                            ))}
                    {contactGroups && contactGroups.length > 0 && (
                        <div className={styles.contactGroups}>
                            <div className={styles.title}>Additional Resources</div>
                            {contactGroups.map((contactGroup) => (
                                <div className={styles.group}>
                                    <div className={styles.name}>{contactGroup?.name}</div>
                                    {contactGroup?.contacts?.map((contact) => (
                                        <div className={styles.contact} key={contact.name}>
                                            <span className={styles.name}>{contact.name}</span>{' '}
                                            <span className={styles.phoneNumber}>
                                                {contact.phoneNumber}
                                            </span>
                                            {contact.address && (
                                                <>
                                                    <br />
                                                    <span>{contact.address}</span>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                            <div className={styles.contact}>
                                <span className={styles.name}>Suicide Prevention Lifeline</span>{' '}
                                <span className={styles.phoneNumber}>1-800-273-TALK (8255)</span>
                            </div>
                        </div>
                    )}
                </Section>
                <Section title="Step 6: Making the Environment Safe">
                    <>
                        <div className={styles.textBlock}>To protect myself I plan to:</div>
                        <ul className={styles.bulletedList}>
                            {(answers.strategiesGeneral || []).map((strategy, idx) => (
                                <li key={`${strategy}-${idx}`}>
                                    <span className={styles.textBlock}>{strategy}</span>
                                </li>
                            ))}
                        </ul>
                        {['Firearm', 'Medicine', 'Places', 'Other', 'Custom'].map(
                            (strategyCategory) => {
                                const strategies: string[] =
                                    answers[`strategies${strategyCategory}`] || [];
                                if (!strategies.length) return null;
                                return (
                                    <React.Fragment key={strategyCategory}>
                                        <div className={styles.textBlock}>{strategyCategory}:</div>
                                        <ul className={styles.bulletedList}>
                                            {(answers[`strategies${strategyCategory}`] || []).map(
                                                (strategy: string, idx: number) => (
                                                    <li key={`${strategy}-${idx}`}>
                                                        <span className={styles.textBlock}>
                                                            {strategy}
                                                        </span>
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    </React.Fragment>
                                );
                            },
                        )}
                        {answers.meansSupportYesNo && answers.meansSupportWho && (
                            <div className={styles.textBlock}>
                                I will ask{' '}
                                <span className={styles.boldTextBlock}>
                                    {answers.meansSupportWho}
                                </span>{' '}
                                for help with this plan.
                            </div>
                        )}
                    </>
                </Section>
                <Section title="Step 7: Remembering My Reasons for Living">
                    <ol>
                        {(reasonsLive || []).map((reason) => (
                            <li key={reason}>{reason}</li>
                        ))}
                    </ol>
                </Section>
            </div>
        </div>
    );
};

export { StanleyBrownStabilityCard };
export default StanleyBrownStabilityCard;
