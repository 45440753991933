import { useContext, useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import Segment, { AnalyticNames } from 'lib/segment';
import StoreContext from 'state/context/store';
import { lockSession, logout, triggerHeartbeat } from 'state/actions/user';
import { Patient } from 'state/types';

interface usePatientIdleTimerReturn {
    activate: () => void;
    pause: () => void;
    reset: () => void;
    lock: () => void;
    showTimeoutModal: boolean;
}

const usePatientIdleTimer = (): usePatientIdleTimerReturn => {
    const location = useLocation();
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const {
        activities = { csp: false, csa: false, skills: false },
        authenticated,
        hasSecuritySteps,
        isDemoPatient,
        userType,
    } = user as Patient;

    const [showTimeoutModal, setShowTimeoutModal] = useState(false);

    const getTimeout = (): number => {
        const shortTimeoutRoutes = ['/question'];
        const isPath3 = !activities.csa && !activities.csp;

        if (isPath3) {
            return 30 * 1_000 * 60; // 30 minutes
        } else if (!hasSecuritySteps) {
            // Still in onboarding
            return 30 * 1_000 * 60; // 30 minutes
        } else if (shortTimeoutRoutes.includes(location.pathname)) {
            return 4 * 1_000 * 60; // 4 minutes
        }
        return 9 * 1_000 * 60; // 9 minutes
    };

    const lock = useCallback(() => {
        setShowTimeoutModal(false);
        if (hasSecuritySteps && userType === 'patient') {
            Segment.track(AnalyticNames.SESSION_LOCKED);
            lockSession(dispatch);
        } else {
            Segment.track(AnalyticNames.SESSION_TIMEOUT, { hasSecuritySteps });
            logout(dispatch, userType, false);
        }
    }, [dispatch, hasSecuritySteps, setShowTimeoutModal, userType]);

    const onPrompt = () => {
        // onPrompt will be called after the timeout value is reached
        setShowTimeoutModal(true);
    };

    const onIdle = () => {
        // onIdle will be called after the promptTimeout is reached.
        lock();
        pause();
    };

    const onActive = () => {
        // onActive will only be called if `reset()` is called while `isPrompted() is true
        setShowTimeoutModal(false);
        if (authenticated && !isDemoPatient) {
            triggerHeartbeat(dispatch, 'patient');
        }
    };

    const onAction = () => {
        // onAction will trigger when a watched event is triggered
        if (authenticated && !isDemoPatient) {
            triggerHeartbeat(dispatch, 'patient');
        }
    };

    const { activate, pause, reset } = useIdleTimer({
        timers: workerTimers,
        timeout: getTimeout(),
        promptTimeout: 1_000 * 60, // 1 minute
        onAction,
        onActive,
        onIdle,
        onPrompt,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'visibilitychange',
        ],
        throttle: 60_000,
        eventsThrottle: 200,
        startOnMount: true,
    });

    return {
        activate,
        pause,
        reset,
        lock,
        showTimeoutModal,
    };
};

export default usePatientIdleTimer;
