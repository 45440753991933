import React from 'react';
import { useContext } from 'react';
import StoreContext from 'state/context/store';
import Section from '../../Components/Section';
import BubbleQuestion from '../../Components/BubbleQuestion';
import HelpfulPersonQuestion from './HelpfulPersonQuestion';
import styles from './index.module.scss';
import LethalMeansQuestions from './LethalMeansQuestions';
import { Patient } from 'state/types';
import { TabProps } from '../shared/types';

const MakeHomeSaferTab = ({ answers, questions, setAnswers, idx }: TabProps) => {
    const [store] = useContext(StoreContext);
    const { user } = store;
    const { technicianOperated, stabilityPlanType } = user as Patient;

    return (
        <Section
            number={idx}
            title="Immediate steps to Make Home Safer"
            tooltip="Limiting access to dangerous objects can save your life. How can you secure or remove means of potentially harming yourself?"
        >
            <div className={styles.questions}>
                {technicianOperated && stabilityPlanType !== 'stanley brown' && (
                    <LethalMeansQuestions answers={answers} setAnswers={setAnswers} />
                )}

                <BubbleQuestion
                    title="To protect myself I plan to:"
                    answers={answers}
                    setAnswers={setAnswers}
                    uid="strategiesGeneral"
                    answerKey="strategiesGeneral"
                    questions={questions}
                />

                <BubbleQuestion
                    title="Firearm"
                    answers={answers}
                    setAnswers={setAnswers}
                    uid="additionalStrategies"
                    answerKey="strategiesFirearm"
                    questions={questions}
                />

                <BubbleQuestion
                    title="Medicine"
                    answers={answers}
                    setAnswers={setAnswers}
                    uid="additionalStrategies"
                    answerKey="strategiesMedicine"
                    questions={questions}
                />

                <BubbleQuestion
                    title="Dangerous Places"
                    answers={answers}
                    setAnswers={setAnswers}
                    uid="additionalStrategies"
                    answerKey="strategiesPlaces"
                    questions={questions}
                />

                <BubbleQuestion
                    title="Other Hazards"
                    answers={answers}
                    setAnswers={setAnswers}
                    uid="additionalStrategies"
                    answerKey="strategiesOther"
                    questions={questions}
                />

                <BubbleQuestion
                    title="Custom"
                    answers={answers}
                    setAnswers={setAnswers}
                    uid="additionalStrategies"
                    answerKey="strategiesCustom"
                    questions={questions}
                    allowCustom
                />

                <HelpfulPersonQuestion answers={answers} setAnswers={setAnswers} />
            </div>
        </Section>
    );
};

export default MakeHomeSaferTab;
