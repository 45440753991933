import { useMemo } from 'react';
import {
    AnswerKeyType,
    Questions,
    ActionType,
    TextType,
    UIDType,
} from 'components/ConversationalUi/questions';
import { AssessmentAnswers } from 'state/types';
import styles from './text.module.scss';

interface ChoiceQuestionProps {
    title: string;
    answers: Partial<AssessmentAnswers>;
    setAnswers: (answers: Partial<AssessmentAnswers>) => void;
    uid: UIDType;
    answerKey: AnswerKeyType;
    questions: Questions;
}

const ChoiceQuestion = ({
    title,
    answers,
    setAnswers,
    uid,
    answerKey,
    questions,
}: ChoiceQuestionProps) => {
    const matchedAction = useMemo(() => {
        const question = questions.find((question) => question.uid === uid);
        if (!question) {
            return undefined;
        }

        const isTextType = (action: ActionType): action is TextType => action.type === 'text';

        return question.actions.find(
            (action) => isTextType(action) && action.answerKey === answerKey,
        ) as TextType | undefined;
    }, [answerKey, questions, uid]);

    const answer = answers[answerKey];

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <input
                className={styles.text}
                value={answer}
                onChange={(e) => setAnswers({ ...answers, [answerKey]: e.target.value })}
                maxLength={matchedAction?.maxLength}
                placeholder="Type your response here"
            />
        </div>
    );
};

export { ChoiceQuestion };
export default ChoiceQuestion;
