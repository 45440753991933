import { AssignedActivities, AssignedActivity } from 'components/ConversationalUi/questions';
import { useContext, useEffect } from 'react';
import { getQuestions, lockActivity } from 'state/actions/assessment';
import { AssessmentConstants } from 'state/constants';
import StoreContext from 'state/context/store';
import { Patient } from 'state/types';
import SampleInterview from 'lib/useInterview/sampleInterview.json';

type IUseInterviewReturn = {
    activities: AssignedActivities | [];
    lockActivity: (activity: AssignedActivity) => void;
};

// TODO Rename interview / questions / activities to make more sense throughout
// the app
const useInterview = (): IUseInterviewReturn => {
    const [store, dispatch] = useContext(StoreContext);
    const { authenticated, isDemoPatient } = store.user as Patient;
    const { activities = [] } = store.assessment;

    const lock = (activity: AssignedActivity) => {
        if (!isDemoPatient) {
            lockActivity(dispatch, activity);
        } else {
            dispatch({
                type: AssessmentConstants.UPDATE_ACTIVITIES,
                activity: { ...activity, locked: true },
            });
        }
    };

    useEffect(() => {
        if (authenticated && activities?.length === 0) {
            if (!isDemoPatient) {
                getQuestions(dispatch);
            } else {
                // @ts-ignore
                dispatch({ type: AssessmentConstants.SET_ACTIVITIES, activities: SampleInterview });
            }
        }
    }, [dispatch, activities?.length, isDemoPatient, authenticated]);

    return { activities, lockActivity: lock };
};

export default useInterview;
