import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Code from 'pages/activation/code';
import ActivatePhone from 'pages/activation/phone';
import SetPassword from 'pages/activation/account';
import Debug from 'pages/admin/debug';
import ForgotPassword from 'pages/reset-password/forgot-password';
import ResetPasswordSuccess from 'pages/reset-password/reset-password-success';
import TechActivation from 'pages/technician/activation';

// Epic OAuth
import EpicRedirect from 'pages/technician/epic/redirect';
import EhrTimeout from 'pages/technician/ehrTimeout';
import Login from 'pages/login';
import { useContext } from 'react';
import StoreContext from 'state/context/store';

const AnonymousRouter = () => {
    const location = useLocation();
    const [store] = useContext(StoreContext);
    const { isTablet, code, codeType } = store.device;

    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/technician/activate" component={TechActivation} />
            <Route path="/activate-tools-to-go" component={ActivatePhone} />
            <Route path="/activate-code" component={Code} />
            <Route path="/set-password" component={SetPassword} />

            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/password-reset/success" component={ResetPasswordSuccess} />

            <Route
                path="/reset-password/confirm"
                render={() => {
                    if (location.hash.includes('userType=Technician')) {
                        return <SetPassword />;
                    }
                    return <ActivatePhone />;
                }}
            />

            <Route path="/reset-password/activate-code" component={Code} />
            <Route path="/reset-password/set-password" component={SetPassword} />
            <Route path="/epic/redirect" component={EpicRedirect} />
            <Route path="/ehr-timeout" component={EhrTimeout} />
            <Route path="/ebpi-debug" component={Debug} />
            {/* /start-patient-session is handled higher up since it crosses user boundaries
            Match it here to prevent the catchall from redirecting */}
            <Route path="/start-patient-session" render={() => null} />
            <Route
                path="*"
                render={() => (
                    <Redirect
                        to={{
                            pathname: isTablet
                                ? `/start-patient-session/${codeType}/${code}`
                                : '/login',
                            search: location.search,
                        }}
                    />
                )}
            />
        </Switch>
    );
};

export default AnonymousRouter;
