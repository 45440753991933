import axios, { AxiosError, AxiosResponse } from 'axios';
import { errorInterceptor } from 'lib/api';
import config from 'config';
import { Dispatch } from 'state/types';
import { InteractionsConstants } from 'state/constants';
import { GetResponse } from 'state/types/api/patient/patient-interaction';

export const getInteractions = async (dispatch: Dispatch): Promise<AxiosResponse | undefined> => {
    axios.interceptors.response.use(...errorInterceptor(dispatch));
    try {
        const response = await axios.get<GetResponse>(
            `${config.apiRoot}/patient/patient-interactions`,
        );
        const json = response.data;
        dispatch({
            type: InteractionsConstants.SET_INTERACTIONS,
            interactions: json,
        });

        return response;
    } catch (error) {
        const axiosError = error as AxiosError;
        const { response } = axiosError;
        // No extra error handling
        return response;
    }
};
