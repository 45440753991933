import { useContext, useEffect } from 'react';
import { getPrivacyImages } from 'state/actions/user';
import { UserConstants } from 'state/constants';
import StoreContext from 'state/context/store';
import { Patient, PrivacyImages } from 'state/types';

type IUsePrivacyImagesReturn = PrivacyImages;

const usePrivacyImages = (): IUsePrivacyImagesReturn => {
    const [store, dispatch] = useContext(StoreContext);
    const { isDemoPatient, privacyImages = [] } = store.user as Patient;

    useEffect(() => {
        if (!isDemoPatient) {
            getPrivacyImages(dispatch);
        } else {
            dispatch({
                type: UserConstants.SET_PRIVACY_IMAGES,
                privacyImages: [
                    { id: 8, url: 'https://media.jasprhealth.com/Pineapple3x_1.png' },
                    { id: 2, url: 'https://media.jasprhealth.com/Berries3x_1.png' },
                    { id: 7, url: 'https://media.jasprhealth.com/Mountain3x_1.png' },
                ],
            });
        }
    }, [dispatch, isDemoPatient]);

    return privacyImages;
};

export default usePrivacyImages;
