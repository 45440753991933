import Storage from 'lib/storage';
import { AssessmentReducer, AssessmentReducerState } from './assessment';
import { DeviceReducer, DeviceReducerState } from './device';
import { InteractionsReducer, InteractionReducerState } from './interactions';
import { SkillsReducer, SkillReducerState } from './skills';
import { StoriesReducer, StoriesReducerState } from './stories';
import { UserReducer, UserReducerState } from './user';
import { MediaReducer, MediaReducerState } from './media';
import { ErrorReducer, ErrorReducerState } from './error';
import { UserConstants } from '../constants';
import { Actions } from '../types';

import { initialState as initialAssessment } from './assessment';
import { initialState as initialDevice } from './device';
import { initialState as initialInteractions } from './interactions';
import { initialState as initialError } from './error';
import { initialState as initialMedia } from './media';
import { initialState as initialSkills } from './skills';
import { initialState as initialStories } from './stories';
import { initialState as initialUser } from './user';

export type CombinedReducerState = {
    assessment: AssessmentReducerState;
    device: DeviceReducerState;
    interactions: InteractionReducerState;
    skills: SkillReducerState;
    stories: StoriesReducerState;
    user: UserReducerState;
    media: MediaReducerState;
    error: ErrorReducerState;
};

export type CombinedReducer = {
    assessment: typeof AssessmentReducer;
    device: typeof DeviceReducer;
    interactions: typeof InteractionsReducer;
    skills: typeof SkillsReducer;
    stories: typeof StoriesReducer;
    user: typeof UserReducer;
    media: typeof MediaReducer;
    error: typeof ErrorReducer;
};

export const initialState: CombinedReducerState = {
    assessment: initialAssessment,
    interactions: initialInteractions,
    device: initialDevice,
    error: initialError,
    media: initialMedia,
    skills: initialSkills,
    stories: initialStories,
    user: initialUser,
};

const combineReducers = (
    slices: Partial<CombinedReducer> | undefined,
): ((state: CombinedReducerState | undefined, action: Actions) => CombinedReducerState) => {
    return (state, action) => {
        if (!state || !slices) {
            return initialState;
        }
        return Object.keys(slices).reduce((acc, prop) => {
            return {
                ...acc,
                [prop]: slices[prop]!(acc[prop], action),
            };
        }, state);
    };
};

const allReducers = combineReducers({
    assessment: AssessmentReducer,
    device: DeviceReducer,
    interactions: InteractionsReducer,
    skills: SkillsReducer,
    stories: StoriesReducer,
    user: UserReducer,
    media: MediaReducer,
    error: ErrorReducer,
});

const rootReducer = (state: CombinedReducerState | undefined, action: Actions) => {
    if (action.type === UserConstants.RESET_APP) {
        const stateReference: CombinedReducerState | undefined = state;

        Storage.removeSecureItem('token');

        if (state && stateReference) {
            state = {
                // Preserve device specific metadata across app resets
                ...initialState,
                device: {
                    ...initialDevice,
                    loaded: stateReference['device']['loaded'],
                    code: stateReference['device']['code'],
                    codeType: stateReference['device']['codeType'],
                    isTablet: stateReference['device']['isTablet'],
                },
                // Don't clear out static media.
                // It does not serve different content for different users or user types
                media: stateReference.media,
            };
        } else {
            state = undefined;
        }
    }
    return allReducers(state, action);
};

export default rootReducer;
