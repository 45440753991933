import React, { useContext, useState } from 'react';
import Segment, { AnalyticNames } from 'lib/segment';
import { useHistory } from 'react-router-dom';
import StoreContext from 'state/context/store';
import { Patient } from 'state/types';
import styles from './index.module.scss';
import PrivacyPolicyModal from 'components/PrivacyPolicyModal';

const Consent = () => {
    const history = useHistory();
    const [store] = useContext(StoreContext);
    const { user } = store;
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
    const {
        consentLanguage = `Jaspr is not a replacement for your care team nor is it an emergency service. If you need urgent help, please speak to your care team now.\n
        Everything you do on Jaspr will remain confidential. To give you the best experience, some of your information will be stored, in keeping with our privacy practices.\n
        Your care team will have access to your Jaspr data. They may use the information to improve the care you receive. They may add some Jaspr data to your medical record. Your healthcare system will follow its own Notice of Privacy Practices for this information.\n
        Jaspr is an investigational device backed by science and supported by federal research. The Jaspr Health Team wishes to continue to improve it. You do not need to use it. By using this device, you are agreeing to use Jaspr under the supervision of your care team.\n
        If you are under 18 years of age, your care team has followed local laws regarding parent/guardian permission for your use of Jaspr.`,
    } = user as Patient;

    const activities = (user as Patient).activities ?? { csa: false, csp: false };

    const onClickPrivacyPractices = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setPrivacyModalOpen(() => !privacyModalOpen);
    };

    const renderConsentHtml = () => {
        /* eslint-disable */
        return (
            <div aria-label="Jaspr Consent Text">
                <h1>Before we begin, please note:</h1>
                {consentLanguage.split(/(privacy practices)/).map((part) => {
                    if (part === 'privacy practices') {
                        return (
                            <a
                                role="button"
                                aria-label="Read Jaspr Health's Privacy Practices"
                                onClick={onClickPrivacyPractices}
                            >
                                {part}
                            </a>
                        );
                    }
                    return part;
                })}
            </div>
        );
        /* eslint-enable */
    };

    const next = () => {
        Segment.track(AnalyticNames.PATIENT_CONSENTED_DURING_ONBOARDING);
        if (!activities.csp && !activities.csa) {
            // Path 3 is an abridged onboarding
            history.push('/baseline');
        } else {
            history.push('/intro-video');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.consent}>{renderConsentHtml()}</div>

            <button aria-label="Button to Accept Consent" type="button" onClick={next}>
                I understand and agree to take part in this research
            </button>
            <PrivacyPolicyModal
                open={privacyModalOpen}
                onClickClose={() => setPrivacyModalOpen(false)}
            />
        </div>
    );
};

export default Consent;
