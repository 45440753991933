const QuestioMarkCircle = ({
    color = '#ffffff',
    height = 20,
    ariaHidden = false,
}: {
    color?: string;
    height?: number;
    ariaHidden?: boolean;
}) => {
    const width = height;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 17 17"
            fill="none"
            aria-hidden={ariaHidden ? 'true' : 'false'}
        >
            <circle cx="8.5" cy="8.5" r="7.75" stroke={color} strokeWidth="1.5" />
            <path
                d="M7.65291 10.205C7.60958 9.893 7.61825 9.607 7.67891 9.347C7.74825 9.087 7.84358 8.84867 7.96491 8.632C8.09491 8.41533 8.23791 8.216 8.39391 8.034C8.55858 7.84333 8.71025 7.66133 8.84891 7.488C8.99625 7.306 9.11758 7.12833 9.21291 6.955C9.30825 6.78167 9.35591 6.59533 9.35591 6.396C9.35591 6.084 9.26058 5.83267 9.06991 5.642C8.88791 5.45133 8.63225 5.356 8.30291 5.356C8.03425 5.356 7.79158 5.41667 7.57491 5.538C7.35825 5.65933 7.15458 5.824 6.96391 6.032L6.13191 5.265C6.41791 4.93567 6.75591 4.667 7.14591 4.459C7.54458 4.24233 7.99091 4.134 8.48491 4.134C8.82291 4.134 9.13491 4.18167 9.42091 4.277C9.70691 4.36367 9.94958 4.498 10.1489 4.68C10.3569 4.862 10.5172 5.08733 10.6299 5.356C10.7512 5.62467 10.8119 5.93667 10.8119 6.292C10.8119 6.56933 10.7599 6.82067 10.6559 7.046C10.5606 7.26267 10.4392 7.47067 10.2919 7.67C10.1446 7.86933 9.98425 8.06 9.81091 8.242C9.64625 8.424 9.49458 8.61467 9.35591 8.814C9.22591 9.01333 9.11758 9.22567 9.03091 9.451C8.95291 9.67633 8.93125 9.92767 8.96591 10.205H7.65291ZM8.31591 13.156C8.03858 13.156 7.80891 13.0607 7.62691 12.87C7.44491 12.6793 7.35391 12.4367 7.35391 12.142C7.35391 11.8473 7.44491 11.6047 7.62691 11.414C7.80891 11.2233 8.03858 11.128 8.31591 11.128C8.59325 11.128 8.82291 11.2233 9.00491 11.414C9.19558 11.6047 9.29091 11.8473 9.29091 12.142C9.29091 12.4367 9.19558 12.6793 9.00491 12.87C8.82291 13.0607 8.59325 13.156 8.31591 13.156Z"
                fill={color}
            />
        </svg>
    );
};

export default QuestioMarkCircle;
