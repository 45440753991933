import { useRef, useState } from 'react';

import { PatientSerializer } from 'state/types/api/sharedSerializers';
import styles from './index.module.scss';

import Confirmation from './confirmation';
import Pin from './pin';

const StartPatientSession = () => {
    const [patient, setPatient] = useState<PatientSerializer | null>(null);
    const [technicianOperated, setTechnicianOperated] = useState(false);

    const containerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className={styles.startPatientSession} ref={containerRef}>
            {patient && (
                <Confirmation
                    patient={patient}
                    setPatient={setPatient}
                    technicianOperated={technicianOperated}
                />
            )}
            {!patient && (
                <Pin setPatient={setPatient} setTechnicianOperated={setTechnicianOperated} />
            )}
        </div>
    );
};

export default StartPatientSession;
