import { StoriesConstants } from 'state/constants';
import { Video, Stories } from 'state/types';
import { ActionSetStories, ActionSetStoriesFetching } from 'state/types/actions';

export interface StoriesReducerState {
    people: {
        id: number;
        labelColor: string; // TODO Remove
        name: string;
        order: number;
        thumbnail: string;
        videos: Video[];
    }[];
    topics: {
        id: number;
        labelColor: string; // TODO Remove
        name: string;
        order: number;
        videos: Video[];
    }[];
    videos: Video[];
    tags: { [tag: string]: Video[] };
    storiesFetched: boolean;
}

export type StoriesReducerType = ActionSetStories | ActionSetStoriesFetching;

const initialState: StoriesReducerState = {
    people: [],
    topics: [],
    videos: [],
    tags: {},
    storiesFetched: false,
};

const organizeStories = (
    state: StoriesReducerState,
    stories: Stories = [],
): StoriesReducerState => {
    const people = stories
        .map((story) => {
            const { image1x, image2x, image3x, ...rest } = story.person;
            return {
                ...rest,
                thumbnail: story.person['image3x'],
                videos: [] as Video[],
            };
        })
        .filter((item, pos, self) => self.findIndex((person) => person.id === item.id) === pos)
        .sort((a, b) => a.order - b.order);

    const topics = stories
        .map((story) => ({ ...story.topic, videos: [] as Video[] }))
        .filter((item, pos, self) => self.findIndex((topic) => topic.id === item.id) === pos)
        .sort((a, b) => a.order - b.order);

    const videos = stories.map((story) => ({
        ...story.video,
        thumbnail: story.video.thumbnail || story.person['image3x'],
    }));

    stories.forEach((story) => {
        const person = people.find((person) => story.person.id === person.id);
        if (person) {
            person.videos = [...person.videos, story.video];
        }

        const topic = topics.find((topic) => story.topic.id === topic.id);
        if (topic) {
            topic.videos = [...topic.videos, story.video];
        }
    });
    return { ...state, people, topics, videos, storiesFetched: true };
};

const StoriesReducer = (
    state: StoriesReducerState = initialState,
    action: StoriesReducerType,
): StoriesReducerState => {
    switch (action.type) {
        case StoriesConstants.SET_STORIES:
            return organizeStories(state, action.stories);
        case StoriesConstants.SET_STORIES_FETCHING:
            return { ...state, storiesFetched: true };
        default:
            return state;
    }
};

export { StoriesReducer, initialState };
