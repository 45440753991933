import styles from './index.module.scss';
import { AssessmentAnswers } from 'state/types';

interface HelpfulPersonQuestionProps {
    answers: Partial<AssessmentAnswers>;
    setAnswers: (
        answers: AssessmentAnswers | ((answers: AssessmentAnswers) => AssessmentAnswers),
    ) => void;
}

const LethalMeansQuestions = ({ answers, setAnswers }: HelpfulPersonQuestionProps) => {
    const { meansYesNo, meansYesNoDescribe, firearmsYesNo, firearmsYesNoDescribe, meansWilling } =
        answers;

    const toggleYesNo = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = currentTarget;
        const answerKey = currentTarget.getAttribute('data-answer-key');

        if (answerKey) {
            if ((value === 'true' && !checked) || (value === 'false' && !checked)) {
                setAnswers((answers) => ({ ...answers, [answerKey]: null }));
            } else if (value === 'true' && checked) {
                setAnswers((answers) => ({ ...answers, [answerKey]: true }));
            } else if (value === 'false' && checked) {
                setAnswers((answers) => ({ ...answers, [answerKey]: false }));
            }
        }
    };

    const toggleAnswer = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = currentTarget;
        const answerKey = currentTarget.getAttribute('data-answer-key');
        if (answerKey) {
            if (checked) {
                setAnswers((answers) => ({ ...answers, [answerKey]: value }));
            } else {
                setAnswers((answers) => ({ ...answers, [answerKey]: null }));
            }
        }
    };

    return (
        <div>
            <div className={styles.row}>
                <div className={styles.question}>
                    Do you have access to the means to take your life? (e.g. stash of pills, guns,
                    or any other method)
                </div>
                <div className={styles.radio}>
                    <label
                        className={`${styles.label} ${meansYesNo === true ? styles.checked : ''}`}
                    >
                        Yes
                        <input
                            className={styles.checkbox}
                            type="checkbox"
                            onChange={toggleYesNo}
                            checked={meansYesNo === true}
                            data-answer-key="meansYesNo"
                            value="true"
                        />
                    </label>
                    <label
                        className={`${styles.label} ${meansYesNo === false ? styles.checked : ''}`}
                    >
                        No
                        <input
                            className={styles.checkbox}
                            type="checkbox"
                            onChange={toggleYesNo}
                            checked={meansYesNo === false} // Not null
                            data-answer-key="meansYesNo"
                            value="false"
                        />
                    </label>
                </div>
            </div>
            <div className={styles.row}>
                <div className={`${styles.question} ${styles.describe}`}>Describe</div>
                <input
                    className={styles.describeText}
                    value={meansYesNoDescribe ? meansYesNoDescribe : ''}
                    onChange={({ target }) =>
                        setAnswers((answers) => ({ ...answers, meansYesNoDescribe: target.value }))
                    }
                    placeholder="Type your response here"
                />
            </div>

            <div className={styles.row}>
                <div className={styles.question}>Do you have access to a firearm?</div>
                <div className={styles.radio}>
                    <label
                        className={`${styles.label} ${
                            firearmsYesNo === true ? styles.checked : ''
                        }`}
                    >
                        Yes
                        <input
                            className={styles.checkbox}
                            type="checkbox"
                            onChange={toggleYesNo}
                            checked={firearmsYesNo === true}
                            data-answer-key="firearmsYesNo"
                            value="true"
                        />
                    </label>
                    <label
                        className={`${styles.label} ${
                            firearmsYesNo === false ? styles.checked : ''
                        }`}
                    >
                        No
                        <input
                            className={styles.checkbox}
                            type="checkbox"
                            onChange={toggleYesNo}
                            checked={firearmsYesNo === false} // Not null
                            data-answer-key="firearmsYesNo"
                            value="false"
                        />
                    </label>
                </div>
            </div>
            <div className={styles.row}>
                <div className={`${styles.question} ${styles.describe}`}>Describe</div>
                <input
                    className={styles.describeText}
                    value={firearmsYesNoDescribe ? firearmsYesNoDescribe : ''}
                    onChange={({ target }) =>
                        setAnswers((answers) => ({
                            ...answers,
                            firearmsYesNoDescribe: target.value,
                        }))
                    }
                    placeholder="Type your response here"
                />
            </div>
            <div className={styles.row}>
                <div className={styles.question}>
                    How willing are you to secure your means with the steps you identified?
                </div>
                <div className={styles.radio}>
                    <label
                        className={`${styles.label} ${
                            meansWilling === 'Not willing' ? styles.checked : ''
                        }`}
                    >
                        Not willing
                        <input
                            className={styles.checkbox}
                            type="checkbox"
                            onChange={toggleAnswer}
                            checked={meansWilling === 'Not willing'}
                            data-answer-key="meansWilling"
                            value="Not willing"
                        />
                    </label>
                    <label
                        className={`${styles.label} ${
                            meansWilling === 'Mixed/Unsure' ? styles.checked : ''
                        }`}
                    >
                        Mixed / Unsure
                        <input
                            className={styles.checkbox}
                            type="checkbox"
                            onChange={toggleAnswer}
                            checked={meansWilling === 'Mixed/Unsure'}
                            data-answer-key="meansWilling"
                            value="Mixed/Unsure"
                        />
                    </label>
                    <label
                        className={`${styles.label} ${
                            meansWilling === 'Very willing' ? styles.checked : ''
                        }`}
                    >
                        Very willing
                        <input
                            className={styles.checkbox}
                            type="checkbox"
                            onChange={toggleAnswer}
                            checked={meansWilling === 'Very willing'}
                            data-answer-key="meansWilling"
                            value="Very willing"
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default LethalMeansQuestions;
