import Section from '../../Components/Section';
import SupportivePeopleGroup from '../shared/SupportivePeopleGroup';
import styles from './index.module.scss';
import { TabProps } from '../shared/types';

const SupportivePeopleTab = ({
    idx,
    answers,
    setAnswers,
    questions,
    stabilityPlanType,
    contactGroups,
}: TabProps) => {
    const { supportivePeople = [{ name: '', phone: '', type: 'family' }] } = answers;

    return (
        <Section
            number={idx}
            title="Professional Resources"
            tooltip="Any professionals who can help when things are hardest?"
        >
            <div className={styles.questions}>
                <>
                    <div className={styles.title}>Professional People</div>
                    <SupportivePeopleGroup
                        setAnswers={setAnswers}
                        type="professional"
                        supportivePeople={supportivePeople}
                    />
                    {contactGroups && contactGroups.length > 0 && (
                        <div className={styles.contactGroups}>
                            <div className={styles.title}>Additional Resources</div>
                            {contactGroups.map((contactGroup, idx) => (
                                <div className={styles.group} key={contactGroup?.label || idx}>
                                    <div className={styles.name}>{contactGroup?.label}</div>
                                    {contactGroup?.contacts?.map((contact) => (
                                        <div className={styles.contact} key={contact.name}>
                                            <span className={styles.name}>{contact.name}</span>{' '}
                                            <span className={styles.phoneNumber}>
                                                {contact.phoneNumber}
                                            </span>
                                            {contact.address && (
                                                <>
                                                    <br />
                                                    <span>{contact.address}</span>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                            <div className={styles.contact}>
                                <span className={styles.name}>Suicide Prevention Lifeline</span>{' '}
                                <span className={styles.phoneNumber}>1-800-273-TALK (8255)</span>
                            </div>
                        </div>
                    )}
                </>
            </div>
        </Section>
    );
};

export default SupportivePeopleTab;
