import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    getQuestions,
    saveAnswers,
    setCurrentSectionUid,
    startAssessmentLockCounter,
} from 'state/actions/assessment';
import Menu from 'components/Menu';
import CUI from 'components/ConversationalUi';
import ProgressBar from './progressbar';
import Summaries from './summaries';
import styles from './index.module.scss';
import circleCheckedIcon from 'assets/circle-check.svg';
import jazIcon from 'assets/jazz.png';
import jasperIcon from 'assets/jasper.png';
import Button from 'components/Button';
import StoreContext from 'state/context/store';
import { AssessmentAnswers, Dispatch, Patient } from 'state/types';
import useActions from 'lib/useActions';
import useInterview from 'lib/useInterview';
import useAnswers from 'lib/useAnswers';

const Interview = () => {
    const history = useHistory();
    const [addAction, actionNames] = useActions();
    const [store, dispatch] = useContext(StoreContext);
    const { assessment, user } = store;
    const { assessmentLocked, currentSectionUid } = assessment;
    const { activities, lockActivity } = useInterview();
    const { assessmentLockTimer, isDemoPatient, timeSinceCheckin, guide } = user as Patient;
    const [showSummaries, setShowSummaries] = useState(false);
    const [answers, answersFetched] = useAnswers();

    const assessmentActivities = activities
        .filter((activity) => activity.type !== 'intro')
        .filter((activity) => activity?.questions?.length !== 0); // comfort_and_skills

    const toggleSummaries = () => {
        if (!showSummaries) {
            addAction(actionNames.SUMMARIES_OPEN);
        } else {
            addAction(actionNames.SUMMARIES_CLOSED);
        }
        setShowSummaries(!showSummaries);
    };

    const outro = activities.find((activity) => activity.type === 'outro')!;

    const done = () => {
        lockActivity(outro);
    };

    const notDone = () => {
        startAssessmentLockCounter(dispatch, assessmentLockTimer || 0 + 1);
    };

    const confirmLock = Boolean(assessmentLockTimer) && timeSinceCheckin && timeSinceCheckin >= 5;

    const firstQuestionUID = assessmentActivities
        .map((activity) => activity.questions)
        .flat()
        .filter(
            (question) =>
                !question?.actions.some((action) => ['section-change'].includes(action.type)),
        )
        .find((question) => question?.uid)?.uid;

    const saveAnswersCheck = (dispatch: Dispatch, newAnswers: Partial<AssessmentAnswers>) => {
        if (!isDemoPatient) {
            saveAnswers(dispatch, newAnswers);
        }
    };

    return (
        <div className={styles.container}>
            <Menu selectedItem="cams" />
            {confirmLock && !assessmentLocked && (
                <div className={styles.lockOuter}>
                    <div className={styles.lockConfirm}>
                        <div className={styles.row}>
                            <img
                                className={styles.guide}
                                src={guide === 'Jaz' ? jazIcon : jasperIcon}
                                alt={`Your guide ${guide || 'Jaz'}`}
                            />
                            <span>
                                I've noticed that you haven't updated your Interview in a little
                                bit. If you're done with it, I'll let your provider know.
                            </span>
                        </div>
                        <div className={styles.buttons}>
                            <Button variant="tertiary" onClick={notDone}>
                                I'm still working on it
                            </Button>
                            <Button onClick={done}>I'm done</Button>
                        </div>
                    </div>
                </div>
            )}
            {assessmentLocked && (
                <div className={styles.lockOuter}>
                    <div className={styles.popup}>
                        <img src={circleCheckedIcon} alt="Checked" />
                        <p>You have completed the interview.</p>

                        {activities.some((activity) =>
                            ['stability_plan', 'comfort_and_skills'].includes(activity.type),
                        ) && <Button onClick={() => history.replace('/')}>Close</Button>}
                    </div>
                </div>
            )}
            {!assessmentLocked && !confirmLock && activities.length && (
                <div className={styles.innerContainer}>
                    <CUI
                        activities={assessmentActivities}
                        firstQuestionUID={firstQuestionUID}
                        lockActivity={lockActivity}
                        currentSectionUid={currentSectionUid}
                        setCurrentSectionUid={setCurrentSectionUid}
                        ready={answersFetched}
                        answers={answers}
                        getQuestions={getQuestions}
                        saveAnswers={saveAnswersCheck}
                    />
                    {!showSummaries && (
                        <ProgressBar
                            activities={assessmentActivities}
                            toggleSummaries={toggleSummaries}
                        />
                    )}
                    {showSummaries && <Summaries toggleSummaries={toggleSummaries} />}
                </div>
            )}
        </div>
    );
};

export default Interview;
