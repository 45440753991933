import { UserConstants } from 'state/constants';
import { Dispatch } from 'state/types';
import { PatientSerializer } from 'state/types/api/sharedSerializers';

export const activateTrainingPatientFromPin = (
    dispatch: Dispatch,
    technicianOperated: boolean,
): PatientSerializer => {
    const token = 'FAKETRAININGTOKEN';
    const patient: PatientSerializer = {
        activities: { csp: true, csa: false, skills: true },
        analyticsToken: technicianOperated
            ? 'TRAINING_TECHNICIAN_OPERATED'
            : 'TRAINING_PATIENT_OPERATED',
        dateOfBirth: '1975-12-12',
        currentWalkthroughStep: null,
        currentWalkthroughStepChanged: '2020-06-30T16:10:39.240000-05:00',
        firstName: 'JasprTest',
        guide: 'Jaz',
        email: 'trainingpatient@jasprhealth.com', // TODO Review
        hasSecuritySteps: false,
        id: 9999999999,
        inEr: true,
        lastName: 'Patient',
        location: {
            system: { id: 9999999999, name: 'Training System' },
            clinic: { id: 9999999999, name: 'Training Clinic' },
            department: { id: 9999999999, name: 'Training Department' },
        },
        mobilePhone: '',
        mrn: '220100764',
        onboarded: false,
        toolsToGoStatus: 'Not Started',
        tourComplete: false,
        userType: 'patient',
        acquiredConsentAt: null,
        requiresMinorConsent: false, // Leave false for demo patients or API request will fail and force logout
        minorConsentLanguage: 'Lorem Ipsum',
    };

    dispatch({ type: 'RESET_APP' });

    // axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    // TOOD indicate to Axios that this is a training patient / use offline mocks?
    // TODO tell segment this is a training patient
    // Segment.track(AnalyticNames.PATIENT_ACTIVATED_BY_PIN);

    dispatch({
        type: UserConstants.SET_TOKEN,
        token: token,
    });

    dispatch({
        type: UserConstants.ACTIVATE_PATIENT,
        token: token,
        patient,
        technicianOperated,
        isDemoPatient: true,
    });

    return patient;
};
