import React, { useContext } from 'react';
import StoreContext from 'state/context/store';
import logo from 'assets/logo.png';
import styles from './index.module.scss';
import { Patient } from 'state/types';

interface BannerProps {
    openAccountSetup: () => void;
}

const Banner = ({ openAccountSetup }: BannerProps) => {
    const [store] = useContext(StoreContext);
    const { stabilityPlanLabel } = store.user as Patient;

    return (
        <div className={styles.banner}>
            <div>
                <img src={logo} alt="Jaspr at Home" />
                <span>Jaspr at Home</span>
            </div>
            <div>
                <p>
                    Access your {stabilityPlanLabel}, Shared Stories, and Comfort &amp; Skills
                    online anytime
                </p>
            </div>
            <div>
                <div className={styles.button} onClick={openAccountSetup}>
                    Setup Account
                </div>
            </div>
        </div>
    );
};

export default Banner;
