import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import { AssessmentAnswers, ContactGroups } from 'state/types';
import { Questions } from 'components/ConversationalUi/questions';
import Pencil from 'assets/icons/Pencil';
import NeedToTalkImage from './need_to_talk.png';
import SuicideHotlineImage from './suicide_hotline.png';

interface StabilityPlanProps {
    edit?: boolean;
    answers: AssessmentAnswers;
    questions?: Questions;
    stabilityPlanType: 'standard' | 'stanley brown';
    contactGroups?: ContactGroups;
    stabilityPlanLabel?: string;
    print?: boolean;
}

interface SectionProps {
    idx: number;
}

const StabilityPlan = ({
    edit = false,
    answers,
    questions = [],
    stabilityPlanType,
    contactGroups,
    stabilityPlanLabel = 'Stability Plan',
    print = false,
}: StabilityPlanProps) => {
    const history = useHistory();
    const Section = ({
        idx,
        title,
        children,
    }: SectionProps & { title: string; children: React.ReactNode }) => (
        <div className={styles.stabilityPlanListItem}>
            <div className={styles.stabilityPlanListItemNumber}>
                <div>{idx + 1}</div>
            </div>
            <div className={styles.stabilityPlanListItemContent}>
                <div className={styles.contentHeader}>
                    {title}
                    {edit && (
                        <div
                            className={styles.editButton}
                            onClick={() => history.push(`/takeaway/stability-plan/${idx}`)}
                        >
                            <span className={styles.editLabel}>Edit</span>
                            <Pencil color="#000000" height={13} />
                        </div>
                    )}
                </div>
                {children}
            </div>
        </div>
    );

    const ImmediateSteps = ({ idx }: SectionProps) => (
        <Section idx={idx} title="Immediate Steps To Take">
            <>
                <div className={styles.textBlock}>To protect myself I plan to:</div>
                <ul className={styles.bulletedList}>
                    {(answers.strategiesGeneral || []).map((strategy, idx) => (
                        <li key={`${strategy}-${idx}`}>
                            <span className={styles.textBlock}>{strategy}</span>
                        </li>
                    ))}
                </ul>
                {['Firearm', 'Medicine', 'Places', 'Other', 'Custom'].map((strategyCategory) => {
                    const strategies: string[] = answers[`strategies${strategyCategory}`] || [];
                    if (!strategies.length) return null;
                    return (
                        <React.Fragment key={strategyCategory}>
                            <div className={styles.textBlock}>{strategyCategory}:</div>
                            <ul className={styles.bulletedList}>
                                {(answers[`strategies${strategyCategory}`] || []).map(
                                    (strategy: string, idx: number) => (
                                        <li key={`${strategy}-${idx}`}>
                                            <span className={styles.textBlock}>{strategy}</span>
                                        </li>
                                    ),
                                )}
                            </ul>
                        </React.Fragment>
                    );
                })}
                {answers.meansSupportYesNo && answers.meansSupportWho && (
                    <div className={styles.textBlock}>
                        I will ask{' '}
                        <span className={styles.boldTextBlock}>{answers.meansSupportWho}</span> for
                        help with this plan.
                    </div>
                )}
            </>
        </Section>
    );

    const WarningSigns = ({ idx }: SectionProps) => (
        <Section idx={idx} title="Warning Signs">
            <>
                <div className={styles.textBlock}>
                    {stabilityPlanType === 'stanley brown'
                        ? 'Warning signs that a crisis might be developing:'
                        : "I know I'm about to be in a crisis when I experience:"}
                </div>
                <ul className={styles.unorderedList}>
                    {['Actions', 'Feelings', 'Thoughts', 'Stressors'].map((word) => {
                        /**
                List all the selected items in each group.
                Items that have been placed in the top warning signs list should be bolded.
                Items that are custom items generated by the patient should be in quotes.
                If an item is both in the top warning signs list and is custom, it should be bolded and quoted.
                */

                        const answerKey = `ws${word}`;
                        const warningSigns: string[] = answers[answerKey] || [];
                        const question = questions.find((question) =>
                            question.actions.some(
                                (action) => 'answerKey' in action && action.answerKey === answerKey,
                            ),
                        );
                        if (!question) return null;
                        const action = question.actions.find(
                            (action) => 'answerKey' in action && action.answerKey === answerKey,
                        );
                        const choices = action && 'choices' in action ? action.choices : [];

                        if (!warningSigns.length) return null;
                        return (
                            <li key={word}>
                                <span className={styles.boldTextBlock}>{word}:</span>{' '}
                                {warningSigns.map((warningSign, idx) => {
                                    return (
                                        <React.Fragment key={`${warningSign}-{idx}`}>
                                            <span
                                                className={
                                                    (answers.wsTop || []).includes(warningSign)
                                                        ? styles.boldTextBlock
                                                        : styles.textBlock
                                                }
                                            >
                                                {choices.includes(warningSign)
                                                    ? warningSign
                                                    : `"${warningSign}"`}
                                            </span>
                                            {warningSigns.length !== idx + 1 && <span>{', '}</span>}
                                        </React.Fragment>
                                    );
                                })}
                            </li>
                        );
                    })}
                </ul>
            </>
        </Section>
    );

    const CopingSkills = ({ idx }: SectionProps) => (
        <Section idx={idx} title="Coping Strategies">
            <>
                <div className={styles.textBlock}>
                    {stabilityPlanType === 'stanley brown'
                        ? 'Things that I can do to take my mind off my problems without contacting another person:'
                        : 'The top strategies that help me cope:'}
                </div>
                {['Body', 'Distract', 'Help Others', 'Courage', 'Senses'].map((copingCategory) => {
                    /**
                    List all the selected items in each group.
                    Items that have been placed in the top coping strategies list should be bolded.
                    Items that are custom items generated by the patient should be in quotes.
                    If an item is both in the top coping strategies list and is custom, it should be bolded and quoted.
                    */

                    const answerKey = `coping${copingCategory.replace(/\s+/, '')}`;
                    const copingStrategies: string[] = answers[answerKey] || [];
                    const question = questions.find((question) =>
                        question.actions.some(
                            (action) => 'answerKey' in action && action.answerKey === answerKey,
                        ),
                    );
                    if (!question) return null;
                    const action = question.actions.find(
                        (action) => 'answerKey' in action && action.answerKey === answerKey,
                    );
                    const choices = action && 'choices' in action ? action.choices : [];
                    if (!copingStrategies.length) return null;
                    return (
                        <React.Fragment key={copingCategory}>
                            <div className={styles.textBlock}>{copingCategory}:</div>
                            <ul className={styles.bulletedList}>
                                {copingStrategies.map((copingStrategy, idx) => (
                                    <li key={`${copingStrategy}-${idx}`}>
                                        <span
                                            className={
                                                (answers.copingTop || []).includes(copingStrategy)
                                                    ? styles.boldTextBlock
                                                    : styles.textBlock
                                            }
                                        >
                                            {choices.includes(copingStrategy)
                                                ? copingStrategy
                                                : `"${copingStrategy}"`}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </React.Fragment>
                    );
                })}
                <div className={styles.textBlock}>Other:</div>
                <ul className={styles.bulletedList}>
                    <li>Watch Jaspr Videos</li>
                    <li>24/7 National Hotline, call 1-800-273-8255, text 741741</li>
                </ul>
            </>
        </Section>
    );

    const PeoplePlaces = ({ idx }: SectionProps) => (
        <Section idx={idx} title="People and Places">
            <>
                <div className={styles.textBlock}>
                    {stabilityPlanType === 'stanley brown'
                        ? 'People and social settings that provide distraction:'
                        : ''}
                </div>
                {Array.isArray(answers.supportivePeople) &&
                    answers.supportivePeople
                        .filter((person) => person.type === 'distract')
                        .map((person) => (
                            <div className={styles.row} key={`${person.name} ${person.phone}`}>
                                <span className={styles.column}>Name: {person.name}</span>
                                <span className={styles.column}>Number: {person.phone}</span>
                            </div>
                        ))}
                {Array.isArray(answers.placesDistract) &&
                    answers.placesDistract?.map((place) => (
                        <div key={place} className={styles.row}>
                            Place: {place}
                        </div>
                    ))}
            </>
        </Section>
    );

    const SupportivePeople = ({ idx }: SectionProps) => (
        <Section idx={idx} title="Supportive People">
            <div className={styles.textBlock}>
                {stabilityPlanType === 'stanley brown' ? 'People whom I can ask for help:' : ''}
            </div>
            <div className={styles.textBlock}>My list of contacts who can help:</div>
            <ul className={styles.unorderedList}>
                {(answers.supportivePeople || []).map((obj) => (
                    <li key={`${obj.name}: ${obj.phone}`}>
                        <span className={styles.boldTextBlock}>{obj.name}:</span> {obj.phone}
                    </li>
                ))}
            </ul>
        </Section>
    );

    const Professionals = ({ idx }: SectionProps) => (
        <Section idx={idx} title="Professionals">
            <div className={styles.textBlock}>
                {stabilityPlanType === 'stanley brown'
                    ? 'Professionals or agencies I can contact during a crisis:'
                    : ''}
            </div>
            {Array.isArray(answers.supportivePeople) &&
                answers.supportivePeople
                    .filter((person) => person.type === 'professional')
                    .map((person) => (
                        <div className={styles.row}>
                            <span className={styles.column}>Name: {person.name}</span>
                            <span className={styles.column}>Number: {person.phone}</span>
                        </div>
                    ))}
            {contactGroups && contactGroups.length > 0 && (
                <div className={styles.contactGroups}>
                    <div className={styles.title}>Additional Resources</div>
                    {contactGroups.map((contactGroup) => (
                        <div className={styles.group}>
                            <div className={styles.name}>{contactGroup?.label}</div>
                            {contactGroup?.contacts?.map((contact) => (
                                <div className={styles.contact} key={contact.name}>
                                    <span className={styles.name}>{contact.name}</span>{' '}
                                    <span className={styles.phoneNumber}>
                                        {contact.phoneNumber}
                                    </span>
                                    {contact.address && (
                                        <>
                                            <br />
                                            <span>{contact.address}</span>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </Section>
    );

    const MakingSafe = ({ idx }: SectionProps) => (
        <Section idx={idx} title="Making Safer">
            <>
                <div className={styles.textBlock}>To protect myself I plan to:</div>
                <ul className={styles.bulletedList}>
                    {(answers.strategiesGeneral || []).map((strategy, idx) => (
                        <li key={`${strategy}-${idx}`}>
                            <span className={styles.textBlock}>{strategy}</span>
                        </li>
                    ))}
                </ul>
                {['Firearm', 'Medicine', 'Places', 'Other', 'Custom'].map((strategyCategory) => {
                    const strategies: string[] = answers[`strategies${strategyCategory}`] || [];
                    if (!strategies.length) return null;
                    return (
                        <React.Fragment key={strategyCategory}>
                            <div className={styles.textBlock}>{strategyCategory}:</div>
                            <ul className={styles.bulletedList}>
                                {(answers[`strategies${strategyCategory}`] || []).map(
                                    (strategy: string, idx: number) => (
                                        <li key={`${strategy}-${idx}`}>
                                            <span className={styles.textBlock}>{strategy}</span>
                                        </li>
                                    ),
                                )}
                            </ul>
                        </React.Fragment>
                    );
                })}
                {answers.meansSupportYesNo && answers.meansSupportWho && (
                    <div className={styles.textBlock}>
                        I will ask{' '}
                        <span className={styles.boldTextBlock}>{answers.meansSupportWho}</span> for
                        help with this plan.
                    </div>
                )}
            </>
        </Section>
    );

    const ReasonsLive = ({ idx }: SectionProps) => (
        <Section idx={idx} title="Reasons for Living">
            <>
                <ol className={styles.orderedList}>
                    {(answers?.reasonsLive || []).map((reason, idx) => (
                        <li key={idx} className={styles.italicTextBlock}>
                            {reason}
                        </li>
                    ))}
                </ol>
            </>
        </Section>
    );

    const ForeseeableEvents = ({ idx }) => (
        <Section idx={idx} title="Foreseeable Events">
            <div className={styles.textBlock}>
                As you take the next steps towards feeling better, what are two foreseeable events
                or changes that could make you feel overwhelmed, suicidal, or put you into crisis?
            </div>
            <ol className={styles.orderedList}>
                {(answers?.foreseeableEvents1 || answers?.foreseeableEventsActions1) && (
                    <li>
                        {answers?.foreseeableEvents1}
                        <div className={styles.indent}>
                            <em>What can you or someone else do if this happens?</em>
                        </div>
                        <div className={styles.indent}>{answers?.foreseeableEventsActions1}</div>
                    </li>
                )}
                {(answers?.foreseeableEvents2 || answers?.foreseeableEventsActions2) && (
                    <li>
                        {answers?.foreseeableEvents2}
                        <div className={styles.indent}>
                            <em>What can you or someone else do if this happens?</em>
                        </div>
                        <div className={styles.indent}>{answers?.foreseeableEventsActions2}</div>
                    </li>
                )}
            </ol>
        </Section>
    );

    const HotlineImages = () => (
        <div className={styles.hotlineImages}>
            <img
                src={NeedToTalkImage}
                alt="Need to Talk?  We've got time to listen.  Text Got5 to 741741 to start a conversation"
            />
            <img
                src={SuicideHotlineImage}
                alt="Suicide Prevention Lifeline 1-800-273-TALK (8255)"
            />
        </div>
    );

    const standard = [ImmediateSteps, SupportivePeople, ReasonsLive, WarningSigns, CopingSkills];

    const stanleyBrown = [
        WarningSigns,
        CopingSkills,
        PeoplePlaces,
        SupportivePeople,
        Professionals,
        MakingSafe,
        ReasonsLive,
        ForeseeableEvents,
    ];

    if (print) {
        stanleyBrown.push(HotlineImages);
    }

    return (
        <div className={styles.stabilityPlanList}>
            {print && <h2>My {stabilityPlanLabel}</h2>}

            {stabilityPlanType === 'standard' &&
                standard.map((Component, idx) => <Component key={Component.name} idx={idx} />)}
            {stabilityPlanType === 'stanley brown' &&
                stanleyBrown.map((Component, idx) => <Component key={Component.name} idx={idx} />)}
        </div>
    );
};

export { StabilityPlan };
export default StabilityPlan;
