import React from 'react';
import Section from '../../Components/Section';
import SupportivePeopleGroup from '../shared/SupportivePeopleGroup';
import styles from './index.module.scss';
import { TabProps } from '../shared/types';

const SupportivePeopleTab = ({
    idx,
    answers,
    setAnswers,
    questions,
    stabilityPlanType,
    contactGroups,
}: TabProps) => {
    const { supportivePeople = [{ name: '', phone: '', type: 'family' }] } = answers;

    return (
        <Section
            number={idx}
            title="Supportive People"
            tooltip="Are there people who help you feel better when you're upset? Any professionals who can help when things are hardest?"
        >
            <div className={styles.questions}>
                {stabilityPlanType === 'stanley brown' && (
                    <div className={styles.title}>Supportive Friends and Family</div>
                )}
                <SupportivePeopleGroup
                    setAnswers={setAnswers}
                    type="family"
                    supportivePeople={supportivePeople}
                />
            </div>
        </Section>
    );
};

export default SupportivePeopleTab;
