import { useContext } from 'react';
import StabilityCard from 'components/StabilityCard/Standard';
import StanleyBrownStabilityCard from 'components/StabilityCard/StanleyBrown';
import styles from './index.module.scss';
import { QuestionProps } from '../../question';
import StoreContext from 'state/context/store';
import { Patient } from 'state/types';

type StabilityCardQuestionProps = Pick<QuestionProps, 'answered' | 'answers'> & {
    empty?: boolean;
    stabilityPlanType?: 'standard' | 'stanley brown';
};

const StabilityCardQuestion = (props: StabilityCardQuestionProps) => {
    const { answered, empty = false, answers, stabilityPlanType = 'standard' } = props;
    const [store] = useContext(StoreContext);
    const { stabilityPlanLabel, contactGroups } = store.user as Patient;

    return (
        <div className={styles.container} style={{ display: !answered ? 'flex' : 'none' }}>
            {stabilityPlanType === 'stanley brown' && (
                <StanleyBrownStabilityCard
                    empty={empty}
                    answers={answers}
                    stabilityPlanLabel={stabilityPlanLabel}
                    contactGroups={contactGroups}
                />
            )}
            {stabilityPlanType !== 'stanley brown' && (
                <StabilityCard
                    empty={empty}
                    answers={answers}
                    stabilityPlanLabel={stabilityPlanLabel}
                />
            )}
        </div>
    );
};

export { StabilityCardQuestion };
export default StabilityCardQuestion;
