import { useContext } from 'react';
import { Route } from 'react-router-dom';
import StoreContext from 'state/context/store';
import StartPatientSession from 'pages/start-patient-session';
import AnonymousRouter from './anonymous';
import PatientRouter from './patient';
import TechnicianRouter from './technician';

const Routers = () => {
    const [store] = useContext(StoreContext);
    const { user } = store;
    return (
        <>
            <Route
                path="/start-patient-session/:codeType?/:code?"
                component={StartPatientSession}
            />
            {!user.authenticated && <AnonymousRouter />}
            {user.authenticated && (
                <>
                    {user.userType === 'technician' && <TechnicianRouter />}
                    {user.userType === 'patient' && <PatientRouter />}
                </>
            )}
        </>
    );
};

export default Routers;
