import styles from './index.module.scss';
import SavedSharedStoriesListItem from '../savedSharedStoriesListItem';
import SavedSharedStoriesListItemAdd from '../savedSharedStoriesListItemAdd';

import { Video } from 'state/types';

interface SavedSharedStoriesListProps {
    videos?: Video[];
    onAdd?: () => void;
    editMode: boolean;
}

const SavedSharedStoriesList = (props: SavedSharedStoriesListProps) => {
    const { videos, onAdd, editMode } = props;
    return (
        <ul className={styles.list}>
            {videos?.map((video) => (
                <SavedSharedStoriesListItem video={video} editMode={editMode} key={video.id} />
            ))}
            {onAdd && <SavedSharedStoriesListItemAdd onAdd={onAdd} />}
        </ul>
    );
};

export default SavedSharedStoriesList;
