import Section from '../../Components/Section';
import BubbleQuestion from '../../Components/BubbleQuestion';
import styles from './index.module.scss';
import SupportivePeopleGroup from '../shared/SupportivePeopleGroup';
import { TabProps } from '../shared/types';

const PeoplePlaceTab = ({
    idx,
    answers,
    setAnswers,
    questions,
    stabilityPlanType,
    contactGroups,
}: TabProps) => {
    const { supportivePeople = [{ name: '', phone: '', type: 'family' }] } = answers;

    return (
        <Section number={idx} title="Supportive People">
            <div className={styles.questions}>
                <>
                    <div className={styles.title}>
                        People and social settings that provide distraction:
                    </div>
                    <SupportivePeopleGroup
                        setAnswers={setAnswers}
                        type="distract"
                        supportivePeople={supportivePeople}
                    />

                    <BubbleQuestion
                        title="Places"
                        answers={answers}
                        setAnswers={setAnswers}
                        uid="placesDistract"
                        answerKey="placesDistract"
                        questions={questions}
                    />
                </>
            </div>
        </Section>
    );
};

export default PeoplePlaceTab;
