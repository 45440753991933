import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import Modal, { Styles } from 'react-modal';
import Video from 'components/Videos';
import CrossedPlus from 'components/CrossedPlus';
import zIndexHelper from 'lib/zIndexHelper';
import { Skill } from 'state/types';
import useInteraction from 'lib/useInteraction';

const modalStyle: Styles = {
    overlay: {
        display: 'flex',
        justifyContent: 'space-evenly',
        backgroundColor: 'rgba(45, 44, 63, 0.85)',
        zIndex: zIndexHelper('patient.skill-item'),
    },
    content: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        padding: 0,
        borderRadius: 0,
    },
};

type SavedSkillsListItemProps = {
    skill: Skill;
    editMode: boolean;
};

const SavedSkillsListItem = (props: SavedSkillsListItemProps) => {
    const history = useHistory();
    const { skill, editMode } = props;
    const { video } = skill;
    const [showVideo, setShowVideo] = useState(false);
    const [, saveInteraction] = useInteraction(
        video ? video.id.toString() : 'breathe',
        video ? 'video' : 'breathe',
    );

    const onClickRemove = () => {
        if (editMode) {
            saveInteraction({ saveForLater: false });
        }
    };

    const goToSkill = () => {
        if (editMode) return;
        if (video) {
            setShowVideo(true);
        } else if (skill.targetUrl) {
            history.push({
                pathname: skill.targetUrl,
                state: {
                    from: history.location.pathname,
                },
            });
        }
    };

    return (
        <li className={styles.item}>
            {editMode && <CrossedPlus onClick={onClickRemove} size={24} />}
            <div
                className={styles.name}
                onClick={goToSkill}
                style={{ cursor: video ? 'pointer' : 'default' }}
            >
                {skill.name}
            </div>
            <img
                src={skill.thumbnailImage || undefined}
                alt={skill.name}
                className={styles.image}
                onClick={goToSkill}
                style={{ cursor: video ? 'pointer' : 'default' }}
            />
            {video && (
                <Modal isOpen={showVideo} style={modalStyle}>
                    <Video
                        video={video}
                        type="skill"
                        skill={skill}
                        autoplay
                        back={() => {
                            setShowVideo(false);
                        }}
                    />
                </Modal>
            )}
        </li>
    );
};

export default SavedSkillsListItem;
