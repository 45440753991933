import Segment, { AnalyticNames } from 'lib/segment';
import StoreContext from 'state/context/store';
import { useContext, useEffect } from 'react';
import modalStyles from '../layouts/modals/index.module.scss';
import { logout } from 'state/actions/user';

interface TimeoutModalProps {
    resetTimer: () => void;
    lockout: () => void;
}

const TimeoutModal = (props: TimeoutModalProps): JSX.Element => {
    const { resetTimer, lockout } = props;
    const [, dispatch] = useContext(StoreContext);

    useEffect(() => {
        const timer = setTimeout(lockout, 60 * 1000);
        return () => clearTimeout(timer);
    }, [lockout]);

    return (
        <div className={modalStyles.modal}>
            <h2>Your session is about to expire</h2>
            <p>
                You've been inactive for a while. For your security, you will be logged out in
                approximately <strong>60 seconds</strong>.
            </p>
            <p>Tap "Continue" to stay logged in.</p>
            <div className={modalStyles.buttonGroup}>
                <div
                    className={modalStyles.outlinedButton}
                    onClick={() => {
                        Segment.track(AnalyticNames.LOG_OUT_BY_USER);
                        logout(dispatch, 'patient', true);
                    }}
                >
                    Log out
                </div>
                <div className={modalStyles.filledButton} onClick={resetTimer}>
                    Continue
                </div>
            </div>
        </div>
    );
};

export default TimeoutModal;
