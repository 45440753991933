import { AssessmentAnswers } from 'state/types';

import styles from './index.module.scss';
import { AnswerKeyType } from 'components/ConversationalUi/questions';

interface QuestionScaleButtonsProps {
    action: {
        answerKey: AnswerKeyType;
    };
    question: { providerLabel?: string };
    assessment: AssessmentAnswers;
}

const QuestionScaleButtons = ({ action, assessment, question }: QuestionScaleButtonsProps) => {
    return (
        <div className={styles.questionScaleButtons}>
            <div className={styles.question}>{question.providerLabel}</div>
            <div className={styles.answer}>{assessment[action.answerKey]}</div>
        </div>
    );
};

export default QuestionScaleButtons;
