import Modal from 'react-modal';
import { BrowserRouter } from 'react-router-dom';
import Store from 'state/store';
import rootReducer from 'state/reducers';
import ScrollToTop from 'lib/scrollTop';
import ServiceWorker from 'lib/serviceWorker';
import OnlineDetector from 'components/OnlineDetector';
import PageRouteMonitor from 'components/PageRouteMonitor';
import MaintenanceNotice from 'components/MaintenanceNotice';
import StaticMedia from 'lib/staticMedia';
import ClinicMonitor from 'lib/clinicMonitor';
import UserMonitor from 'lib/userMonitor';
import Toaster from 'components/Toaster';
import Routers from 'routers';

Modal.setAppElement('#root');

const Jaspr = () => {
    return (
        <Store rootReducer={rootReducer}>
            <StaticMedia />
            <MaintenanceNotice />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    backgroundColor: '#2f3251',
                }}
            >
                <BrowserRouter>
                    <ScrollToTop />
                    <PageRouteMonitor />
                    <ClinicMonitor />
                    <UserMonitor />
                    <ServiceWorker />
                    <Toaster />
                    <Routers />
                    <OnlineDetector />
                </BrowserRouter>
            </div>
        </Store>
    );
};

export default Jaspr;
