import Modal, { Styles as ModalStyles } from 'react-modal';
import styles from './index.module.scss';

const privacyPolicyModalStyles: ModalStyles = {
    overlay: {
        display: 'flex',
        justifyContent: 'space-evenly',
        backgroundColor: 'rgba(45, 44, 63, 0.85)',
        zIndex: 10,
    },
    content: {
        position: 'static',
        display: 'flex',
        width: '90vw',
        minWidth: '800px',
        height: '90vh',
        minHeight: '523px',
        alignSelf: 'center',
    },
};

type Props = {
    open: boolean;
    onClickClose: () => void;
};

const PrivacyPolicyModal = (props: Props) => {
    const { open, onClickClose } = props;

    return (
        <Modal
            isOpen={open}
            style={privacyPolicyModalStyles}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            aria={{
                labelledby: 'privacyModalHeading',
                describedby: 'privacyModalFullContent',
            }}
        >
            <div>
                <h1 id="privacyModalHeading">Privacy Policy</h1>
                <div id="privacyModalFullContent">
                    <p>
                        <strong>Last Updated:</strong> Mar 19, 2024
                    </p>
                    <p>
                        The privacy of your personal information is important to us. This Privacy
                        Policy describes what information is collected by Jaspr Health, how it is
                        gathered, how it is used, to whom it is disclosed, and how it is secured,
                        updated and deactivated.
                    </p>
                    <h3>What Information Does Jaspr Health Collect?</h3>
                    <p>
                        Jaspr Health collects personal information (such as contact information and
                        date of birth), Protected Health Information (such as data about health
                        status, thoughts and behaviors pertaining to suicide, and provision of
                        healthcare), and non-personally identifiable information (i.e., information
                        that is not traced back to any individual and is not used on its own to
                        identify an individual) about your use of the application through, by way of
                        example and without limitation, the use of cookies, even if you have not
                        registered, including the referring website, if applicable, the type of
                        browser you use, and the time and date that you accessed the application and
                        that users voluntarily submit by:
                    </p>
                    <ul>
                        <li>
                            Typing in data (such as when you register for our Services, type a
                            message to send to us, type a search request, or complete a measure or
                            other form);
                        </li>
                        <li>Uploading a document, image or other data file;</li>
                        <li>
                            Authorizing us to retrieve and import information from another user or
                            other third party on your behalf, including, without limitation, from
                            your provider or patient or electronic health record;
                        </li>
                        <li>
                            Authorizing us to export information on your or your provider's or
                            provider organization's behalf to your provider or patient or electronic
                            health record;
                        </li>
                        <li>
                            Jaspr Health does not knowingly collect any information from anyone
                            under 13 years of age.
                        </li>
                    </ul>
                    <h3>Who Can Access Information?</h3>
                    <p>
                        Information inputted by patients and reports compiled by Jaspr Health using
                        this information may be viewed by patients, the healthcare organization
                        providing treatment, providers at the healthcare organization and Jaspr
                        Health. Jaspr Health information and reports may be added to the patient's
                        electronic health record, becoming part of the record shared by healthcare
                        and other organizations as part of the patient's treatment.
                    </p>
                    <p>
                        Information inputted by providers and other healthcare organization
                        representatives may be viewed by subject patients and other healthcare
                        organization representatives, depending on their administrative permissions,
                        and access to patient electronic health records within those organizations.
                        Jaspr Health does not control and is not responsible for the administrative
                        permissions of healthcare organizations.
                    </p>
                    <p>
                        Protected health information collected by Jaspr Health is used and disclosed
                        only as is permitted by the Health Insurance Portability and Accountability
                        Act (HIPAA) and the Health Information Technology for Economic and Clinical
                        Health (HITECH) Act. Only persons explicitly authorized by patients to have
                        access to their healthcare information will be provided access.
                    </p>
                    <p>
                        We do not sell or rent your personal information to third parties. We may
                        disclose your information to third parties as described previously. We may
                        disclose personal information to our service providers and vendors in order
                        to provide the services you have requested from us, such as use of our
                        software applications if you authenticate through a third-party service.
                        These third parties are obligated to protect your personal information in
                        strict accordance with our policies, HIPAA, and HITECH.
                    </p>
                    <p>
                        We may at times be required to disclose personal information you provide us
                        as required by a legal obligation, such as in response to a court order or
                        applicable statute. In the event of a reorganization, merger, sale, joint
                        venture, assignment, transfer or other disposition of all or any portion of
                        our business, assets or stock (including in connection with any bankruptcy
                        or similar proceedings), we may have a legitimate interest in disclosing or
                        transferring your information to a third party — such as an acquiring entity
                        and its advisers.
                    </p>
                    <h3>Why Do We Gather Your Personal Information?</h3>
                    <p>We gather personal information for the following purposes:</p>
                    <ul>
                        <li>
                            Personal and healthcare information inputted by patients is gathered by
                            healthcare providers and healthcare organizations for purposes of
                            allowing a healthcare provider to plan and deliver care to patients
                        </li>
                        <li>
                            Information inputted by healthcare providers and healthcare
                            organizations regarding patients, which may include healthcare
                            information and personal information, is gathered for purposes of health
                            care delivery
                        </li>
                        <li>
                            Administrative announcements about features, functionality, terms, or
                            other aspects of our Services
                        </li>
                        <li>
                            Research purposes—anonymized data (stripped of all personally
                            identifying information) is aggregated with the anonymous data of other
                            users into a data repository for data analysis and clinical research to
                            better understand behavioral and other health problems and improve
                            health care; anonymous data may be shared with other third party
                            recipients for the purposes of research (depending on our agreement with
                            the third party, Jaspr Health may or may not charge for this
                            information)
                        </li>
                        <li>Product development purposes and improvement activities</li>
                        <li>Any other purpose described in this Policy or the Terms of Service</li>
                    </ul>
                    <h3>How Is Information Viewed, Updated, and Deactivated?</h3>
                    <p>
                        Upon written request by the account holder, an account will be deactivated
                        and archived. We retain archived information for a period of five (5) years
                        (or longer if required by applicable law or regulation) as necessary to
                        comply with legal obligations, resolve disputes, enforce our agreements and
                        other authorized uses under this Policy. Unless otherwise required by law,
                        Jaspr Health shall be under no obligation to retain any of your account
                        information and may delete the same immediately following deactivation of
                        your account.
                    </p>
                    <p>
                        HIPAA grants patients certain rights to access and correct certain health
                        information their healthcare providers retain about them. Patients should
                        submit requests to access or correct their health information directly to
                        their healthcare providers. Certain personal information can also be
                        corrected within our application or through our webpage. If you believe that
                        we have inaccurate personal information, and are unable to correct it
                        through the application or webpage, please contact us at:
                        privacy@jaspr.health.com
                    </p>
                    <p>
                        Please note that de-identified health information is stored indefinitely in
                        our anonymized data repository.
                    </p>
                    <h3>How We Protect Your Personal Information</h3>
                    <p>
                        Jaspr Health takes administrative, technical, and physical measures to
                        safeguard your personal information against loss, theft, and misuse, as well
                        was unauthorized access, disclosure, and destruction.
                    </p>
                    <h3>Changes to this Policy</h3>
                    <p>
                        Revisions of our Privacy Policy will be posted on this webpage, within the
                        app(s) and/or sent to you via email to the last email address you provided
                        to us (if any). By your continued use of Jaspr Health Services following the
                        new effective date will constitute your acceptance of such changes or
                        modifications.
                    </p>
                    <h3>Cookies</h3>
                    <p>
                        As is standard practice on many webpages, Jaspr Health uses “cookies” and
                        other technologies to help us understand how our users interact with our
                        website. Cookies contain information that is transferred to your computer's
                        hard-drive. These cookies are used to store information, such as the time
                        that the current visit to our webpage occurred, whether you have visited our
                        webpage before, and what third party page, if any, referred you to our
                        webpage.
                    </p>
                    <p>
                        If you prefer not to enable cookies, you can disable them in your browser.
                        Please note that certain features of our webpage and application may not be
                        available once cookies are disabled. As is true of most webpages, we gather
                        certain information automatically and store it in log files. This
                        information includes Internet Protocol (IP) addresses, browser type,
                        Internet Service Provider (ISP), referring/exit pages, operating system,
                        date/time stamp, and clickstream data.
                    </p>
                    <h3>Children's Privacy</h3>
                    <p>
                        Jaspr Health is committed to protecting the privacy of children and abiding
                        by the provisions of the Children's Online Privacy Protection Act (COPPA).
                        Our website and services are not designed or intended to attract children
                        under the age of 13. In the event that Jaspr Health is notified or becomes
                        aware that the site or services have been used by a child under the age of
                        13 to store information of that child without parental consent, Jaspr Health
                        shall be and is authorized to delete, in its entirety, any of the
                        information stored by that child. Jaspr Health also reserves the right to
                        revoke any license to use the site and service which is being used or has
                        been used by a child under the age of 13.
                    </p>
                    <h3>Data Privacy for California Residents</h3>
                    <p>
                        This section applies solely to visitors and users of our Site and Services
                        who reside in the State of California. We have adopted this notice to comply
                        with the California Consumer Privacy Act of 2018 (the “CCPA”) and the
                        California Online Privacy Protection Act (“CalOPPA”), and any terms defined
                        in the CCPA or CalOPPA have the same meaning when used in this notice.
                    </p>
                    <p>
                        For the purposes of this section “California Data Subject” shall mean: (1)
                        an individual who is in the State of California for other than a temporary
                        or transitory purpose, and (2) an individual who is domiciled in the State
                        of California who is outside the State of California for a temporary or
                        transitory purpose.
                    </p>
                    <p>
                        <strong>
                            <u>Information We Collect</u>
                        </strong>
                        <br />
                        Jaspr Health collects information that identifies, relates to, describes,
                        references, is capable of being associated with, or could reasonably be
                        linked, directly or indirectly, with a particular California Data Subject or
                        device (&#8220;personal information&#8221;). In particular, Jaspr Health has
                        collected the following categories of personal information from California
                        Data Subjects within the last twelve (12) months:
                    </p>

                    <p>
                        <strong>
                            <u>Sources of Personal Information</u>
                        </strong>
                        <br />
                        Jaspr Health obtains the personal information listed above from the
                        following sources:
                    </p>

                    <p>
                        <strong>
                            <u>Use of Personal Information</u>
                        </strong>
                        <br />
                        We may use or disclose the personal information we collect for one or more
                        of the following business purposes:
                    </p>
                    <ul>
                        <li>
                            To fulfill the purpose for which you provided the information. For
                            example, if you share your name and contact information to create an
                            account on our site or services, we will use that personal information
                            to establish the account. If you provide your personal information to
                            purchase a product or service, we will use that information to process
                            your payment and facilitate delivery. We may also save your information
                            to facilitate new product orders or process returns. In addition, we may
                            use the above information:
                            <ul>
                                <li>
                                    To provide, support, personalize, and develop our websites,
                                    products, and/or services;
                                </li>
                                <li>
                                    To create, maintain, customize, and secure your account with us;
                                </li>
                                <li>
                                    To provide you with support and to respond to your inquiries,
                                    including to investigate and address your concerns and monitor
                                    and improve our responses;
                                </li>
                                <li>
                                    To help maintain the safety, security, and integrity of our
                                    Website, products and services, databases and other technology
                                    assets, and business;
                                </li>
                                <li>
                                    To respond to law enforcement requests and as required by
                                    applicable law, court order, or governmental regulations; and
                                </li>
                                <li>
                                    As described to you when collecting your personal information or
                                    as otherwise set forth in the CCPA.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        Jaspr Health will not collect additional categories of personal information
                        or use the personal information we collected for materially different,
                        unrelated, or incompatible purposes without providing you notice.
                    </p>
                    <p>
                        Jaspr Health Jaspr Health does share personal information with our third
                        party service providers and vendors in order to provide you the Service.
                    </p>
                    <p>
                        <strong>
                            <u>Your Rights and Choices</u>
                        </strong>
                        <br />
                        This section describes your CCPA rights and explains how to exercise those
                        rights.
                    </p>
                    <p>
                        You have the right to request that we disclose certain information to you
                        about our collection and use of your personal information over the past 12
                        months. Once we receive and verify your request, we will disclose to you:
                    </p>
                    <ul>
                        <li>The categories of personal information we collected about you;</li>
                        <li>
                            The categories of sources for the personal information we collected
                            about you;
                        </li>
                        <li>
                            Our business or commercial purpose for collecting or selling that
                            personal information;
                        </li>
                        <li>
                            The categories of third parties with whom we share that personal
                            information;
                        </li>
                        <li>
                            The specific pieces of personal information we collected about you (also
                            called a data portability request);
                        </li>
                        <li>
                            If we sold or disclosed your personal information for a business
                            purpose, two separate lists disclosing:
                            <ul>
                                <li>
                                    sales, identifying the personal information categories that each
                                    category of recipient purchased; and
                                </li>
                                <li>
                                    disclosures for a business purpose, identifying the personal
                                    information categories that each category of recipient obtained.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        You have the right to request that we delete any of your personal
                        information that we collected from you and retained, subject to certain
                        exceptions. Once we receive and verify your request, we will delete (and
                        direct our service providers to delete) your personal information from our
                        records, unless an exception applies.
                    </p>
                    <p>
                        We may deny your deletion request if retaining the information is necessary
                        for us or our service providers to:
                    </p>
                    <ul>
                        <li>
                            Complete the transaction for which we collected the personal
                            information, provide a good or service that you requested, take actions
                            reasonably anticipated within the context of our ongoing business
                            relationship with you, or otherwise perform our contract with you;
                        </li>
                        <li>
                            Detect security incidents, protect against malicious, deceptive,
                            fraudulent, or illegal activity, or prosecute those responsible for such
                            activities;
                        </li>
                        <li>
                            Debug products to identify and repair errors that impair existing
                            intended functionality;
                        </li>
                        <li>
                            Exercise free speech, ensure the right of another California Data
                            Subject to exercise their free speech rights, or exercise another right
                            provided for by law;
                        </li>
                        <li>
                            Comply with the California Electronic Communications Privacy Act (Cal.
                            Penal Code § 1546 seq.);
                        </li>
                        <li>
                            Engage in public or peer-reviewed scientific, historical, or statistical
                            research in the public interest that adheres to all other applicable
                            ethics and privacy laws, when the information&#8217;s deletion may
                            likely render impossible or seriously impair the research&#8217;s
                            achievement, if you previously provided informed consent;
                        </li>
                        <li>
                            Enable solely internal uses that are reasonably aligned with California
                            Data Subject expectations based on your relationship with us;
                        </li>
                        <li>Comply with a legal obligation; and</li>
                        <li>
                            Make other internal and lawful uses of that information that are
                            compatible with the context in which you provided it.
                        </li>
                    </ul>
                    <p>
                        To exercise the access, data portability, and deletion rights described
                        above, please submit a verifiable California Data Subject request to us by
                        sending us an email at privacy@jasprhealth.com or calling us toll free at
                        (800) 275-1343 .
                    </p>
                    <p>
                        Only you or a person registered with the California Secretary of State, that
                        you authorize to act on your behalf, may make a verifiable California Data
                        Subject request related to your personal information. You may also make a
                        verifiable California Data Subject request on behalf of your minor child.
                    </p>
                    <p>
                        You may only make a verifiable California Data Subject request for access or
                        data portability twice within a twelve (12) month period. The verifiable
                        California Data Subject request must:
                    </p>
                    <ol>
                        <li>
                            Provide sufficient information that allows us to reasonably verify you
                            are the person about whom we collected personal information or an
                            authorized representative; and
                        </li>
                        <li>
                            Describe your request with sufficient detail that allows us to properly
                            understand, evaluate, and respond to it.
                        </li>
                    </ol>
                    <p>
                        We cannot respond to your request or provide you with personal information
                        if we cannot verify your identity or authority to make the request and
                        confirm the personal information relates to you. Making a verifiable
                        California Data Subject request does not require you to create an account
                        with us. We will only use personal information provided in a verifiable
                        California Data Subject request to verify the requestor&#8217;s identity or
                        authority to make the request.
                    </p>
                    <p>
                        We aspire to respond to a verifiable California Data Subject request within
                        forty five (45) days of receipt of the request. If we require more time (up
                        to ninety (90) days) we will inform you of the reason(s) why an extension is
                        needed and how long we anticipate the period to be. Any disclosure we
                        provide will only cover the twelve (12) month period preceding the receipt
                        of your request. If applicable, the response may provide the reasons why we
                        cannot comply with your request. For data portability requests, we will
                        select a format to provide your personal information that is readily useable
                        and should allow you to transmit the information from one entity to another
                        entity without hindrance.
                    </p>
                    <p>
                        We do not charge a fee to process or respond to your verifiable California
                        Data Subject request unless it is excessive, repetitive, or manifestly
                        unfounded. If we determine that the request warrants a fee, we will tell you
                        why we made that decision and provide you with a cost estimate before
                        completing your request. We reserve the right to refuse to respond to
                        verifiable California Data Subject requests that are excessive, repetitive,
                        or manifestly unfounded.
                    </p>
                    <p>
                        <strong>
                            <u>Right of Non-Discrimination</u>
                        </strong>
                        <br />
                        We will not discriminate against you for exercising any of your CCPA rights.
                        We will not take any of the following actions against you in response to an
                        exercise of your rights:
                    </p>
                    <ul>
                        <li>Deny you goods or services.</li>
                        <li>
                            Charge you different prices or rates for goods or services, including
                            through granting discounts or other benefits, or imposing penalties.
                        </li>
                        <li>Provide you a different level or quality of goods or services.</li>
                        <li>
                            Suggest that you may receive a different price or rate for goods or
                            services or a different level or quality of goods or services.
                        </li>
                    </ul>
                    <h3>California Do-Not-Track Disclosures</h3>
                    <p>
                        Our webpage does not track users over time and across third party websites
                        and therefore does not respond to Do Not Track signals. We do not allow
                        third parties to place and trackers or cookies that allow them to track
                        users across websites.
                    </p>
                    <h3>Contact Us</h3>
                    <p>You may contact us at:</p>
                    <p>
                        Jaspr Health
                        <br />
                        9450 SW Gemini Dr
                        <br />
                        PMB 68735
                        <br />
                        Beaverton, Oregon 97008-7105
                    </p>
                    <p>Email address: privacy@jasprhealth.com</p>
                    <div className={styles.buttonGroup}>
                        <div className={styles.outlinedButton} onClick={onClickClose}>
                            close
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export { PrivacyPolicyModal };
export default PrivacyPolicyModal;
