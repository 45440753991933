import React, { useContext, useCallback, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/browser";
import toast from "lib/toast";
import { CSSTransition } from "react-transition-group";
import { AnswerContext } from "../context";
import StoreContext from "state/context/store";
import { AssessmentConstants } from "state/constants";
import styles from "./index.module.scss";
import RankQuestion from "../questions/rank";
import TextQuestion from "../questions/text";
import ListQuestion from "../questions/list";
import ChoiceQuestion from "../questions/choice";
import CounterQuestion from "../questions/counter";
import GiveConsentQuestion from "../questions/giveConsent";
import AssessmentLock from "../questions/assessmentLock";
import InlineMessage from "../questions/message";
import Buttons from "../questions/buttons";
import Video from "../questions/video";
import SecurityImage from "../questions/securityImage";
import SecurityQuestion from "../questions/securityQuestion";
import ScaleButtonsQuestion from "../questions/scalebuttons";
import TabChoiceQuestion from "../questions/tabChoice";
import MeansCustomQuestion from "../questions/meansCustom";
import ComfortSkillsQuestion from "../questions/comfortSkills";
import SharedStoriesQuestion from "../questions/sharedStories";
import CopingStrategiesQuestion from "../questions/copingStrategies";
import SupportivePeopleQuestion from "../questions/supportivePeople";
import RankTopQuestion from "../questions/rankTop";
import StabilityCardQuestion from "../questions/stabilityCard";
import SliderQuestion from "../questions/slider";
import SortEditQuestion from "../questions/sortEdit";
import ListRankQuestion from "../questions/listRank";
import { AssessmentAnswers, Dispatch, Patient } from "state/types";
import {
    ActionType,
    AssignedActivities,
    AssignedActivity,
    ListType,
    Condition,
    ConditionGroup,
    Question as QuestionType,
    Questions as QuestionsType,
    UIDType,
    ShowHideType,
} from "../questions";
import useActions from "lib/useActions";

interface Props {
    questionGroup: QuestionType;
    idx: number;
    activityId: number;
    currentIndex: number;
    lastQuestionRef: React.Ref<HTMLDivElement>;
    next: (advance?: boolean, goTo?: UIDType[], completeActivity?: boolean) => void;
    uid: string;
    disableAnalytics?: boolean;
    questions: QuestionsType;
    answers: AssessmentAnswers;
    activities: AssignedActivities;
    lockActivity: (activity: AssignedActivity) => void;
    isDemoPatient?: boolean;
    getQuestions: (dispatch: Dispatch) => void;
    saveAnswers: (
        dispatch: Dispatch,
        newAnswers: { [answerKey: string]: any },
        isTakeaway: boolean,
        activityId: number,
    ) => void;
}

export type QuestionProps = ActionType & {
    answerKey?: string;
    questions: QuestionsType;
    currentQuestion: boolean;
    currentAnswers: any; // AssessmentAnswers;
    setAnswered: (answered: boolean) => void;
    answered: boolean;
    uid: UIDType;
    next: (goTo?: UIDType[], completeActivity?: boolean) => void;
    isValid: boolean;
    setIsValid: (valid: boolean) => void;
    showValidation: boolean | string;
    setShowValidation: (message: string | boolean) => void;
    validationRequired: boolean;
    validate: React.MutableRefObject<() => Promise<void | boolean> | null>;
    answers: AssessmentAnswers;
    lockActivity: (activity: AssignedActivity) => void;
    activities: AssignedActivities;
    isDemoPatient: Boolean;
};

const Question = (props: Props) => {
    const {
        questionGroup,
        activityId,
        idx,
        currentIndex,
        lastQuestionRef,
        next,
        uid,
        disableAnalytics,
        questions,
        answers,
        activities,
        getQuestions,
        saveAnswers,
        lockActivity,
    } = props;
    const [addAction, actionNames] = useActions();
    const [store, dispatch] = useContext(StoreContext);
    const { stabilityPlanType, isDemoPatient = false } = store.user as Patient;
    const guide = "Jaz";

    const currentQuestion = idx === currentIndex;
    // Set these true by default and change to false in the onMount
    // useEffect so we can get a correctly calculated content height
    const [showActions, setShowActions] = useState<boolean>(true);
    const [answered, setAnswered] = useState<boolean>(currentQuestion);
    const [startAnalyticRecorded, setStartAnalyticRecorded] = useState<boolean>(false);
    const [submissionInProgress, setSubmissionInProgress] = useState<boolean>(false);
    const [updatedAnswers, setUpdatedAnswers] = useState({});

    // Only supports one validation per question
    const [isValid, setIsValid] = useState(false);
    const [showValidation, setShowValidation] = useState<string | boolean>(false);
    const validate = useRef<() => Promise<boolean | void> | null>(null);

    const listActions = questionGroup.actions
        ? questionGroup.actions.filter<ListType>(
              (action: ActionType): action is ListType => action.type === "list",
          )
        : [];

    // Checks if the question is the current question.  When editing a question that was previously
    // answered, we want to save the answer, but not advance the questions
    const checkNext = useCallback(
        async (goTo?: UIDType[], completeActivity: boolean = false) => {
            next(currentQuestion, goTo, completeActivity);
        },
        [currentQuestion, next],
    );

    const submitAnswers = useCallback(
        async (updatedAnswers) => {
            if (!isDemoPatient) {
                const response = await saveAnswers(dispatch, updatedAnswers, false, activityId);

                // @ts-ignore
                if (response?.data?.nonFieldErrors?.includes("activity is locked")) {
                    getQuestions(dispatch);
                    toast.error("This activity has been locked by your provider");
                }
            } else {
                // Save answers locally when operating as a demo
                dispatch({
                    type: AssessmentConstants.UPDATE_ANSWERS,
                    answers: { ...answers, ...updatedAnswers },
                });
            }
        },
        [isDemoPatient, saveAnswers, dispatch, activityId, getQuestions, answers],
    );

    useEffect(() => {
        if (answered && Object.keys(updatedAnswers).length) {
            submitAnswers(updatedAnswers);
            setUpdatedAnswers({});
        }
    }, [answered, submitAnswers, updatedAnswers]);

    const evaluateConditions = useCallback(
        (conditions: ShowHideType | [] | undefined, answers: AssessmentAnswers): boolean => {
            /*
            This is a helper function that takes a condition and a set of answers,
            and returns true if the answer for the specified field matches the value 
            in the condition
            */
            const evaluateCondition = (
                condition: Condition,
                answers: AssessmentAnswers,
            ): boolean => {
                const [field, value] = condition;
                return answers[field] === value;
            };

            /* This is a type guard function that checks if a given condition is 
            a condition group (i.e., it has 'or' or 'and' properties).
            */
            const isConditionGroup = (condition: any): condition is ConditionGroup => {
                return condition?.or || condition?.and;
            };

            if (!Array.isArray(conditions) || conditions.length === 0) {
                // When no conditions are provided, the evaluation defaults to true
                return true;
            }

            if (conditions.length === 2 && !isConditionGroup(conditions)) {
                // Single condition
                return evaluateCondition(conditions, answers);
            }

            /* Multiple conditions
            If conditions is a condition group, it's treated as multiple conditions.
            If it's an 'or' group, the function returns true if at least one of the
            conditions is true. If it's an 'and' group, the function returns true only
            if all conditions are true.
            */
            const condition = conditions[0];
            if (isConditionGroup(condition)) {
                if ("or" in condition) {
                    return condition.or.some((condition) => evaluateCondition(condition, answers));
                } else if ("and" in condition) {
                    return condition.and.every((condition) =>
                        evaluateCondition(condition, answers),
                    );
                }
            }

            // Fallback for unexpected condition structure.  This should never be reached.
            Sentry.captureException("Unexpected condition structure for assessment");
            return true;
        },
        [],
    );

    const shouldShowQuestion = useCallback(
        (questionGroup: QuestionType, answers: AssessmentAnswers): boolean => {
            const { showIf, hideIf } = questionGroup;

            // Evaluate showIf conditions - question should be shown if conditions are truthy or not present
            const showResult = evaluateConditions(showIf, answers);
            // Evaluate hideIf conditions - question should be hidden if conditions are truthy
            const hideResult = !evaluateConditions(hideIf, answers);

            // Show the question if it's not explicitly hidden and either explicitly shown or no conditions are provided
            return showResult && !hideResult;
        },
        [evaluateConditions],
    );

    const shouldSkipQuestion = useCallback(() => {
        if (!currentQuestion) {
            return false;
        }
        return !shouldShowQuestion(questionGroup, answers);
    }, [answers, currentQuestion, questionGroup, shouldShowQuestion]);

    // Record analytic
    useEffect(() => {
        if (
            !disableAnalytics &&
            currentQuestion &&
            !startAnalyticRecorded &&
            questionGroup.uid !== "start" &&
            !shouldSkipQuestion()
        ) {
            setStartAnalyticRecorded(true);
            addAction(actionNames.ARRIVE, { sectionUid: questionGroup.uid });
        }
    }, [
        startAnalyticRecorded,
        currentQuestion,
        questionGroup.uid,
        shouldSkipQuestion,
        disableAnalytics,
        addAction,
        actionNames.ARRIVE,
    ]);

    useEffect(() => {
        if (shouldSkipQuestion()) {
            checkNext();
        }
    }, [shouldSkipQuestion, checkNext]);

    useEffect(() => {
        if (!currentQuestion) {
            setAnswered(true);
            setShowActions(true);
        } else {
            setAnswered(false);
            setShowActions(false);
            const showActionTimer = setTimeout(
                () => setShowActions(true),
                (questionGroup.guide || []).length * 1500,
            );
            return () => clearTimeout(showActionTimer);
        }
    }, [idx, currentQuestion, questionGroup.guide, currentIndex]);

    if (!shouldShowQuestion(questionGroup, answers)) {
        return null;
    }

    return (
        <AnswerContext.Provider
            value={{
                answers: updatedAnswers,
                updateAnswers: setUpdatedAnswers,
            }}
        >
            <CSSTransition
                appear
                in={true}
                style={{ display: "flex", flexDirection: "column" }}
                nodeRef={currentQuestion ? lastQuestionRef : null}
                classNames={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    enterDone: styles.enterDone,
                }}
                timeout={1}
            >
                <div
                    className={styles.question}
                    ref={currentQuestion ? lastQuestionRef : null}
                    style={{ transition: "opacity 2s ease", opacity: 0 }}
                >
                    <div>
                        {questionGroup.guide.map((message, i) => (
                            <InlineMessage
                                key={message}
                                message={message}
                                index={i}
                                currentQuestion={currentQuestion}
                            />
                        ))}
                    </div>

                    {/* Lists need to be in a Row */}
                    {showActions && Boolean(listActions.length) && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "21px 150px",
                            }}
                        >
                            {listActions.map((action) => (
                                <div
                                    key={action.answerKey}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexBasis: "50%",
                                    }}
                                >
                                    <ListQuestion
                                        {...action}
                                        setAnswered={setAnswered}
                                        answered={answered}
                                        answers={answers}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    {showActions &&
                        Boolean(questionGroup.actions) &&
                        questionGroup.actions
                            .filter(
                                (action) =>
                                    action.type !== "list" &&
                                    (!("guide" in action) || action.guide === guide),
                            )
                            .map((action) => {
                                if (action.type === "buttons") {
                                    return (
                                        <Buttons
                                            key={`button-${questionGroup.uid}`}
                                            {...action}
                                            buttons={action.buttons}
                                            isValid={isValid}
                                            setShowValidation={setShowValidation}
                                            next={checkNext}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            // @ts-ignore
                                            validate={validate}
                                            submissionInProgress={submissionInProgress}
                                            setSubmissionInProgress={setSubmissionInProgress}
                                            answers={answers}
                                            isDemoPatient={isDemoPatient}
                                        />
                                    );
                                } else if (action.type === "scalebuttons") {
                                    return (
                                        <ScaleButtonsQuestion
                                            key={`scalebuttons-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "text") {
                                    return (
                                        <TextQuestion
                                            key={`text-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "rank") {
                                    return (
                                        <div
                                            style={{ margin: "0 168px" }}
                                            key={`rank-${questionGroup.uid}`}
                                        >
                                            <RankQuestion
                                                {...action}
                                                setAnswered={setAnswered}
                                                answered={answered}
                                                answers={answers}
                                            />
                                        </div>
                                    );
                                } else if (action.type === "list-rank") {
                                    return (
                                        <div
                                            style={{ margin: "0 168px" }}
                                            key={`list-rank-${questionGroup.uid}`}
                                        >
                                            <ListRankQuestion
                                                {...action}
                                                setAnswered={setAnswered}
                                                answered={answered}
                                                answers={answers}
                                            />
                                        </div>
                                    );
                                } else if (action.type === "choice") {
                                    return (
                                        <ChoiceQuestion
                                            key={`choice-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            uid={uid}
                                            vertical={Boolean(action.vertical)}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "counter") {
                                    return (
                                        <CounterQuestion
                                            key={`counter-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "video") {
                                    return (
                                        <Video
                                            key={`video-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            next={checkNext}
                                        />
                                    );
                                } else if (action.type === "security-image") {
                                    return (
                                        <SecurityImage
                                            key={`security-image-${questionGroup.uid}`}
                                            currentQuestion={currentQuestion}
                                            isValid={isValid}
                                            setIsValid={setIsValid}
                                            // @ts-ignore
                                            validate={validate}
                                            setShowValidation={setShowValidation}
                                            showValidation={showValidation}
                                            isDemoPatient={isDemoPatient}
                                        />
                                    );
                                } else if (action.type === "security-question") {
                                    return (
                                        <SecurityQuestion
                                            key={`security-question-${questionGroup.uid}`}
                                            answered={answered}
                                            isValid={isValid}
                                            setIsValid={setIsValid}
                                            // @ts-ignore
                                            validate={validate}
                                            setShowValidation={setShowValidation}
                                            showValidation={showValidation}
                                            currentQuestion={currentQuestion}
                                        />
                                    );
                                } else if (action.type === "tab-choice") {
                                    return (
                                        <TabChoiceQuestion
                                            key={`tab-choice-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            uid={uid}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "means-custom") {
                                    return (
                                        <MeansCustomQuestion
                                            key={`means-custom-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "comfort-skills") {
                                    return (
                                        <ComfortSkillsQuestion
                                            key={`comfort-skills-${questionGroup.uid}`}
                                            {...action}
                                        />
                                    );
                                } else if (action.type === "shared-stories") {
                                    return (
                                        <SharedStoriesQuestion
                                            key={`shared-stories-${questionGroup.uid}`}
                                            {...action}
                                        />
                                    );
                                } else if (action.type === "coping-strategy") {
                                    // Do not change coping strategy choices without coordinating with the backend
                                    return (
                                        <CopingStrategiesQuestion
                                            key={`coping-strategies-${questionGroup.uid}`} // TODO Change all of these to UID
                                            {...action}
                                            questions={questions}
                                            currentAnswers={answers}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            isValid={isValid}
                                            setIsValid={setIsValid}
                                            showValidation={showValidation}
                                            setShowValidation={setShowValidation}
                                            // @ts-ignore
                                            validate={validate}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "supportive-people") {
                                    return (
                                        <SupportivePeopleQuestion
                                            key={`supportive-people-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "rank-top") {
                                    return (
                                        <RankTopQuestion
                                            key={`rank-top-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "stability-card") {
                                    return (
                                        <StabilityCardQuestion
                                            key={`stability-card-${questionGroup.uid}`}
                                            {...action}
                                            answered={answered}
                                            answers={answers}
                                            stabilityPlanType={stabilityPlanType}
                                        />
                                    );
                                } else if (action.type === "sort-edit") {
                                    return (
                                        <SortEditQuestion
                                            key={`sort-edit-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "slider") {
                                    return (
                                        <SliderQuestion
                                            key={`slider-${questionGroup.uid}`}
                                            {...action}
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            answers={answers}
                                        />
                                    );
                                } else if (action.type === "give-consent") {
                                    return (
                                        <GiveConsentQuestion
                                            key={`give-consent-${questionGroup.uid}`}
                                            {...action}
                                            answered={answered}
                                            uid={uid}
                                            next={checkNext}
                                            answers={answers}
                                            isDemoPatient={isDemoPatient}
                                        />
                                    );
                                } else if (action.type === "assessment-lock") {
                                    return (
                                        <AssessmentLock
                                            key="assessment_lock"
                                            setAnswered={setAnswered}
                                            answered={answered}
                                            next={checkNext}
                                            activities={activities}
                                            lockActivity={lockActivity}
                                        />
                                    );
                                }
                                Sentry.captureException(
                                    `Tried to render question with unknown type ${action.type}`,
                                );
                                return null; // Question type not found
                            })}
                </div>
            </CSSTransition>
        </AnswerContext.Provider>
    );
};

export default Question;
