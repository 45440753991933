import Section from '../../Components/Section';
import ButtonQuestion from '../../Components/ButtonQuestion';
import TextQuestion from '../../Components/TextQuestion';
import BubbleQuestion from '../../Components/BubbleQuestion';
import styles from './index.module.scss';
import { TabProps } from '../shared/types';
import SliderQuestion from '../../Components/SliderQuestion';

const ReadinessTab = ({ answers, questions, setAnswers, error, idx }: TabProps) => {
    return (
        <Section number={idx} title="Readiness">
            <div className={styles.questions}>
                <div className={styles.error}>{error}</div>

                <SliderQuestion
                    title="How confident are you in your ability to use the strategies you selected?"
                    answers={answers}
                    setAnswers={setAnswers}
                    uid="stabilityConfidence"
                    answerKey="stabilityConfidence"
                    questions={questions}
                />

                <ButtonQuestion
                    title="How ready are you to leave/complete care in this setting?"
                    answers={answers}
                    setAnswers={setAnswers}
                    uid="readiness"
                    answerKey="readiness"
                    questions={questions}
                />

                {answers['readiness'] && answers['readiness'] !== 'Very ready' && (
                    <TextQuestion
                        title="What would make you feel more ready?"
                        answers={answers}
                        setAnswers={setAnswers}
                        uid="readinessNo"
                        answerKey="readinessNo"
                        questions={questions}
                    />
                )}

                {answers['readiness'] && answers['readiness'] === 'Very ready' && (
                    <BubbleQuestion
                        title="What makes you feel very ready? Check all that apply."
                        answers={answers}
                        setAnswers={setAnswers}
                        uid="readinessYesReasons"
                        answerKey="readinessYesReasons"
                        questions={questions}
                    />
                )}

                {answers['readiness'] && answers['readiness'] === 'Very ready' && (
                    <TextQuestion
                        title="What has changed since you arrived in this care setting?"
                        answers={answers}
                        setAnswers={setAnswers}
                        uid="readinessYesChanged"
                        answerKey="readinessYesChanged"
                        questions={questions}
                    />
                )}
            </div>
        </Section>
    );
};

export default ReadinessTab;
