import styles from './index.module.scss';
import SavedSkillsListItem from '../savedSkillsListItem';
import SavedSkillsListItemAdd from '../savedSkillsListItemAdd';

import { Skills } from 'state/types';

type SavedSkillsListProps = {
    skills: Skills;
    onAdd?: () => void;
    editMode: boolean;
};

const SavedSkillsList = (props: SavedSkillsListProps): JSX.Element => {
    const { skills, onAdd, editMode } = props;
    return (
        <ul className={styles.list}>
            {skills.map((skill) => (
                <SavedSkillsListItem skill={skill} editMode={editMode} key={skill.id} />
            ))}
            {onAdd && <SavedSkillsListItemAdd onAdd={onAdd} />}
        </ul>
    );
};

export default SavedSkillsList;
