import Segment from 'lib/segment';
import useInteractions from 'lib/useInteractions';
import { useContext } from 'react';
import { actionNames } from 'state/actionNames';
import { InteractionsConstants } from 'state/constants';
import StoreContext from 'state/context/store';
import { Interaction, Patient } from 'state/types';

type IUseInteractionReturn = [
    interaction: Interaction,
    saveInteraction: (interaction: Omit<Interaction, 'uid' | 'type'>) => void,
];

const useInteraction = (
    uid?: string,
    type: 'video' | 'breathe' = 'video',
): IUseInteractionReturn => {
    const [store, dispatch] = useContext(StoreContext);
    const { isDemoPatient } = store.user as Patient;
    const [interactions, saveInteraction] = useInteractions();

    const defaultInteraction: Interaction = {
        uid,
        saveForLater: false,
        rating: null,
        type,
    };

    const interaction =
        interactions.find((interaction) => interaction.uid === uid) || defaultInteraction;

    const setInteraction = (updatedInteraction: Omit<Interaction, 'uid' | 'type'>) => {
        if (!isDemoPatient) {
            saveInteraction({
                uid,
                type,
                ...updatedInteraction,
            });
        } else {
            dispatch({
                type: InteractionsConstants.SET_INTERACTION,
                interaction: {
                    uid,
                    type,
                    ...updatedInteraction,
                },
            });

            const { id, patient, video, ...interactionProperties } = interaction;
            Segment.track(actionNames.INTERACTION, interactionProperties);
        }
    };

    return [interaction, setInteraction];
};

export default useInteraction;
