import { AssessmentAnswers } from 'state/types';

import styles from './index.module.scss';
import { SupportivePeopleType } from 'components/ConversationalUi/questions';

interface QuestionSupportivePeopleProps {
    action: SupportivePeopleType;
    assessment: AssessmentAnswers;
}

const QuestionSupportivePeople = ({ action, assessment }: QuestionSupportivePeopleProps) => {
    return (
        <div className={styles.questionSupportivePeople}>
            <div className={styles.question} style={{ textTransform: 'capitalize' }}>
                Supportive People {action?.peopleType ? `(${action.peopleType})` : ''}
            </div>
            <div className={styles.contacts}>
                {(assessment[action.answerKey] || [])
                    .filter((person) => person.type === (action?.peopleType || 'family'))
                    .map((supportivePerson: { name: string; phone: string }) => (
                        <div
                            className={styles.contact}
                            key={`${supportivePerson.name}-${supportivePerson.phone}`}
                        >
                            {supportivePerson.name}: {supportivePerson.phone}
                        </div>
                    ))}
                <div className={styles.contact}>24/7 National Hotline: Call 1-800-273-8255</div>
                <div className={styles.contact}>24/7 National Text Line: Text 741741</div>
            </div>
        </div>
    );
};

export default QuestionSupportivePeople;
