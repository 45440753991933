import { useEffect, useCallback } from 'react';
import AddButton from '../../../Components/AddButton';
import styles from './index.module.scss';
import { SupportivePersonType } from 'state/types';
import { SetAnswers } from '../types';

export type SupportivePeople = {
    name: string | null;
    phone: string | null;
    type: SupportivePersonType;
}[];

interface SupportivePeopleGroupProps {
    supportivePeople: SupportivePeople;
    type: SupportivePersonType;
    setAnswers: SetAnswers;
}

const SupportivePeopleGroup = ({
    supportivePeople,
    type,
    setAnswers,
}: SupportivePeopleGroupProps) => {
    const addNewPerson = useCallback(
        (peopleType: SupportivePersonType) => {
            setAnswers((answers) => ({
                ...answers,
                supportivePeople: [
                    ...(answers.supportivePeople || []),
                    { name: '', phone: '', type: peopleType },
                ],
            }));
        },
        [setAnswers],
    );

    const editName = (value: string, idx: number): void => {
        setAnswers((answers) => {
            const supportivePeople = [
                ...(answers.supportivePeople || [{ name: '', phone: '', type }]),
            ];
            supportivePeople[idx].name = value;
            return {
                ...answers,
                supportivePeople,
            };
        });
    };

    const editPhone = (value: string, idx: number): void => {
        setAnswers((answers) => {
            const supportivePeople = [
                ...(answers.supportivePeople || [{ name: '', phone: '', type }]),
            ];
            supportivePeople[idx].phone = value;
            return {
                ...answers,
                supportivePeople,
            };
        });
    };

    const remove = (idx: number): void => {
        setAnswers((answers) => {
            const supportivePeople = [
                ...(answers.supportivePeople || [{ name: '', phone: '', type }]),
            ];
            supportivePeople.splice(idx, 1);
            return {
                ...answers,
                supportivePeople,
            };
        });
    };

    useEffect(() => {
        if (supportivePeople?.filter((person) => person.type === type).length === 0) {
            addNewPerson(type);
        }
    }, [addNewPerson, supportivePeople, type]);

    return (
        <div className={styles.people}>
            <div className={styles.fieldHeaders}>
                <span className={styles.headerName}>NAME</span>
                <span className={styles.headerPhone}>PHONE NUMBER</span>
            </div>

            {(supportivePeople || [{ name: '', phone: '', type }]).map((person, idx) => {
                if (person.type !== type) {
                    return null;
                }
                return (
                    <div className={styles.person} key={idx}>
                        <input
                            className={styles.name}
                            value={person.name || ''}
                            placeholder="Type name here"
                            onChange={({ target }) => editName(target.value, idx)}
                        />
                        <input
                            className={styles.phone}
                            value={person.phone || ''}
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            placeholder="Type number here"
                            onChange={({ target }) => editPhone(target.value, idx)}
                        />
                        <button className={styles.remove} onClick={() => remove(idx)}>
                            &#10006;
                        </button>
                    </div>
                );
            })}
            <AddButton onClick={() => addNewPerson(type)} />
        </div>
    );
};

export default SupportivePeopleGroup;
