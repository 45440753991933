import React, { useMemo, useReducer } from 'react';
import StoreContext from 'state/context/store';
import { CombinedReducerState } from 'state/reducers';
import { Actions, Dispatch } from 'state/types';

interface StoreProps {
    children: React.ReactNode;
    rootReducer: (state: CombinedReducerState | undefined, action: Actions) => CombinedReducerState;
}

const Store = ({ rootReducer, children }: StoreProps) => {
    const initialState = useMemo(
        () => rootReducer(undefined, { type: 'RESET_APP' }),
        [rootReducer],
    );
    const [state, dispatch] = useReducer(rootReducer, initialState);
    const store: [CombinedReducerState, Dispatch] = useMemo(() => [state, dispatch], [state]);
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export default Store;
