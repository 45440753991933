import { AnswerKeyType } from 'components/ConversationalUi/questions';
import { AssessmentAnswers } from 'state/types';
import styles from './index.module.scss';

interface QuestionCounterProps {
    action: {
        answerKey: AnswerKeyType;
        answerKeyCount: AnswerKeyType;
        answerKeyUnit: AnswerKeyType;
        multiple?: boolean;
    };
    assessment: AssessmentAnswers;
    question: { providerLabel?: string };
}

const QuestionCounter = ({ action, assessment, question }: QuestionCounterProps) => {
    return (
        <div className={styles.questionCounter}>
            <div className={styles.question}>{question.providerLabel}</div>
            <div className={styles.answer}>
                {assessment[action.answerKeyCount]}{' '}
                {!isNaN(assessment[action.answerKeyCount]) && assessment[action.answerKeyUnit]
                    ? 'times per'
                    : ''}{' '}
                {assessment[action.answerKeyUnit]}
            </div>
        </div>
    );
};

export default QuestionCounter;
