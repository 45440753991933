import styles from './index.module.scss';
import Tooltip from './Tooltip';

interface SectionProps {
    number: number;
    title: string;
    tooltip?: string;
    children: React.ReactNode;
}

const Section = ({ number, title, tooltip, children }: SectionProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.number}>{number}</div>
                <div className={styles.title}>{title}</div>
                {Boolean(tooltip) && <Tooltip tip={tooltip || ''} />}
            </div>

            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default Section;
