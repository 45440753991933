import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Hamburger from 'components/Hamburger';
import CUI from 'components/ConversationalUi';
import StoreContext from 'state/context/store';
import { formatDate } from 'lib/helpers';
import styles from './index.module.scss';
import { AssessmentAnswers, Dispatch, Patient } from 'state/types';
import useInterview from 'lib/useInterview';
import {
    getQuestions,
    lockActivity,
    saveAnswers,
    setCurrentSectionUid,
} from 'state/actions/assessment';
import useAnswers from 'lib/useAnswers';
import { AssignedActivity } from 'components/ConversationalUi/questions';

const Baseline = () => {
    const history = useHistory();
    const [store, dispatch] = useContext(StoreContext);
    const { user, assessment } = store;
    const { currentSectionUid } = assessment;
    const { firstName, isDemoPatient, lastName, dateOfBirth, mrn, tourComplete } = user as Patient;
    const { activities } = useInterview();
    const [answers, answersFetched] = useAnswers();

    useEffect(() => {
        if (tourComplete) {
            history.push('/question');
        }
    }, [history, tourComplete]);

    const saveAnswersCheck = (dispatch: Dispatch, newAnswers: Partial<AssessmentAnswers>) => {
        if (!isDemoPatient) {
            saveAnswers(dispatch, newAnswers);
        }
    };

    return (
        <div className={styles.container}>
            <Hamburger />
            <CUI
                activities={activities.filter((activity) => activity.type === 'intro')}
                lockActivity={(activity: AssignedActivity) => lockActivity(dispatch, activity)}
                currentSectionUid={currentSectionUid}
                setCurrentSectionUid={setCurrentSectionUid}
                ready={answersFetched}
                answers={answers}
                getQuestions={getQuestions}
                saveAnswers={saveAnswersCheck}
            />

            <div className={styles.footer}>
                <div className={styles.patientInfo}>
                    {lastName}
                    {lastName && firstName ? ', ' : ''} {firstName} {mrn}{' '}
                    {dateOfBirth && <>({formatDate(dateOfBirth)})</>}
                </div>
                <div
                    className={styles.back}
                    onClick={() => {
                        history.replace('/intro-video');
                    }}
                >
                    ‹ Back
                </div>
            </div>
        </div>
    );
};

export default Baseline;
