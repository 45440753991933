import { AnswerKeyType } from 'components/ConversationalUi/questions';
import { AssessmentAnswers } from 'state/types';
import styles from './index.module.scss';

interface QuestionTextProps {
    action: {
        answerKey: AnswerKeyType;
    };
    question: { providerLabel?: string };
    actionIndex: number;
    assessment: AssessmentAnswers;
}

const QuestionText = ({ action, actionIndex, assessment, question }: QuestionTextProps) => {
    return (
        <>
            {actionIndex === 0 && <div className={styles.question}>{question?.providerLabel}</div>}
            <div className={styles.questionText}>
                <div className={styles.answer}>
                    Describe: <span>{assessment[action.answerKey]}</span>
                </div>
            </div>
        </>
    );
};

export default QuestionText;
