import { useMemo } from 'react';
import {
    AnswerKeyType,
    Questions,
    ActionType,
    ButtonType,
    UIDType,
} from 'components/ConversationalUi/questions';
import { AssessmentAnswers } from 'state/types';
import styles from './choice.module.scss';

interface ChoiceQuestionProps {
    title: string;
    answers: Partial<AssessmentAnswers>;
    setAnswers: (answers: Partial<AssessmentAnswers>) => void;
    uid: UIDType;
    answerKey: AnswerKeyType;
    questions: Questions;
}

const ButtonQuestion = ({
    title,
    answers,
    setAnswers,
    uid,
    answerKey,
    questions,
}: ChoiceQuestionProps) => {
    const matchedAction = useMemo(() => {
        const question = questions.find((question) => question.uid === uid);
        if (!question) {
            return undefined;
        }

        const isButtonType = (action: ActionType): action is ButtonType =>
            action.type === 'buttons';

        return question.actions.find(
            (action) => isButtonType(action) && action.answerKey === answerKey,
        ) as ButtonType | undefined;
    }, [answerKey, questions, uid]);

    const answer = answers[answerKey];

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={`${styles.options} ${styles.horizontal}`}>
                {matchedAction?.buttons.map((option) => (
                    <label key={option.label} className={styles.option}>
                        <input
                            type="radio"
                            name={`button-${uid}-${answerKey}`}
                            checked={answer === option.label}
                            onChange={(e) => {
                                setAnswers({
                                    ...answers,
                                    [answerKey]: e.target.value,
                                });
                            }}
                            value={option.label}
                        />
                        <span className={styles.label}>{option.label}</span>
                    </label>
                ))}
            </div>
        </div>
    );
};

export { ButtonQuestion };
export default ButtonQuestion;
