import { AnswerKeyType } from 'components/ConversationalUi/questions';
import { AssessmentAnswers } from 'state/types';
import styles from './index.module.scss';

interface QuestionTabChoiceProps {
    action: {
        groups: {
            label: string;
            options: {
                label: string;
                value: string;
            }[];
            answerKey: AnswerKeyType;
        }[];
    };
    assessment: AssessmentAnswers;
    question: { providerLabel?: string };
}

const QuestionTabChoice = ({ action, assessment, question }: QuestionTabChoiceProps) => {
    return (
        <div className={styles.questionTabChoice}>
            <div className={styles.question}>{question.providerLabel}</div>
            {action?.groups.some((group) => group.answerKey === 'strategiesFirearm') && (
                <div>{assessment['strategiesGeneral']?.join(', ')}</div>
            )}
            <div className={styles.lists}>
                {action?.groups.map((group) => (
                    <div key={group.label} className={styles.list}>
                        <div className={styles.listHeader}>
                            <strong>{group.label}</strong>
                            {(assessment[group.answerKey] || []).map((answer: string) => (
                                <div key={answer}>{answer}</div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuestionTabChoice;
