import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal, { Styles } from 'react-modal';
import Button from 'components/Button';
import zIndexHelper from 'lib/zIndexHelper';
import styles from './index.module.scss';
import CrossedPlus from 'components/CrossedPlus';
import CamsSummary from 'pages/patient/takeaway/camsSummary';
import StabilityPlan from 'components/StabilityPlan';
import StoreContext from 'state/context/store';
import useActions from 'lib/useActions';
import useAnswers from 'lib/useAnswers';
import { Patient } from 'state/types';
import { Question } from 'components/ConversationalUi/questions';

const modalStyle: Styles = {
    overlay: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        zIndex: zIndexHelper('patient.summaries'),
        backgroundColor: 'rgba(255, 255, 255, 1)',
        left: '148px',
        top: '56px',
        right: '24px',
        borderTopRightRadius: '30px',
        borderTopLeftRadius: '30px',
    },
    content: {
        position: 'static',
        display: 'flex',
        alignSelf: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 0,
        padding: 0,
    },
};

type Props = {
    toggleSummaries: () => void;
};

const Summaries = ({ toggleSummaries }: Props) => {
    const [addAction, actionNames] = useActions();
    const [store] = useContext(StoreContext);
    const { assessment } = store;
    const { activities } = assessment;
    const { stabilityPlanLabel, stabilityPlanType } = store.user as Patient;
    const [answers] = useAnswers();

    const [openModal, setOpenModal] = useState<string | null>(null);
    const prevModal = useRef<string | null>(null);

    const questions = activities
        ?.map((activity) => activity.questions)
        .filter((questions) => questions !== undefined)
        .flat()
        .filter((question) => question !== undefined) as Question[];

    useEffect(() => {
        if (openModal === 'stability') {
            addAction(actionNames.STABILITY_PLAN_OPEN);
        } else if (openModal === 'cams') {
            addAction(actionNames.INTERVIEW_SUMMARY_OPEN);
        } else if (!openModal && prevModal.current === 'stability') {
            addAction(actionNames.STABILITY_PLAN_CLOSED);
        } else if (!openModal && prevModal.current === 'cams') {
            addAction(actionNames.INTERVIEW_SUMMARY_CLOSED);
        }
        prevModal.current = openModal;
    }, [actionNames, addAction, openModal]);

    return (
        <div className={styles.popup}>
            <div className={styles.top}>
                <div className={styles.topSpacer} />
                <h3 className={styles.title}>Summaries</h3>
                <CrossedPlus className={styles.crossedPlus} size={48} onClick={toggleSummaries} />
            </div>
            <div className={styles.tiles}>
                <div className={styles.tile}>
                    <h5 className={styles.tileTitle}>Interview Summary</h5>
                    <p className={styles.tileDescription}>
                        Review and edit your answers to the Suicide Status Interview
                    </p>
                    <Button onClick={() => setOpenModal('cams')}>Open</Button>
                </div>
                <div className={styles.tile}>
                    <h5 className={styles.tileTitle}>{stabilityPlanLabel}</h5>
                    <p className={styles.tileDescription}>
                        When you're ready, take a look at the plan you made to keep yourself safe
                    </p>
                    <Button onClick={() => setOpenModal('stability')}>Open</Button>
                </div>
            </div>
            <Modal isOpen={Boolean(openModal)} style={modalStyle}>
                {openModal === 'stability' && (
                    <>
                        <div className={styles.modalTitle}>My {stabilityPlanLabel}</div>
                        <div className={styles.back} onClick={() => setOpenModal(null)}>
                            ‹ Summaries
                        </div>
                        <div style={{ marginTop: '84px', overflowY: 'scroll' }}>
                            <StabilityPlan
                                answers={answers}
                                questions={questions}
                                stabilityPlanType={stabilityPlanType}
                            />
                        </div>
                    </>
                )}
                {openModal === 'cams' && (
                    <>
                        <div className={styles.modalTitle}>Interview Summary</div>
                        <div className={styles.back} onClick={() => setOpenModal(null)}>
                            ‹ Summaries
                        </div>
                        <div style={{ marginTop: '84px', overflowY: 'scroll' }}>
                            <CamsSummary printMode answers={answers} />
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default Summaries;
