import { useContext, useEffect, useRef } from 'react';
import StoreContext from 'state/context/store';
import axios from 'axios';
import Segment from 'lib/segment';
import { getPreferences } from 'state/actions/user/getPreferences';
import Storage from 'lib/storage';
import { getMe, setToken } from 'state/actions/user';
import { setDevice } from 'state/actions/device';

/**
 * Monitors for user changes.
 * @return null
 */
const UserMonitor = (): null => {
    const [store, dispatch] = useContext(StoreContext);
    const previousAnalyticsToken = useRef<string | null>(null);
    const { user } = store;

    // Application startup functions.  Check if the app is loaded and restore state
    useEffect(() => {
        (async () => {
            const isEhrEmbedded = window.sessionStorage.getItem('isEhrEmbedded') === 'true';
            const token = window.sessionStorage.getItem('token');
            /***
             * Do Not rehydrate the token in EHR Embedded environments.  The token
             * can get cached by the EHR, and the user's token is locked to a specific
             * patient in EHR integrated environments.  See JGP-2557.
             */
            if (!isEhrEmbedded && token) {
                // Do not include user here as it may be changing from localstorage.
                // It'll get updated with the getme
                setToken(dispatch, token);
                axios.defaults.headers.common['Authorization'] = `Token ${token}`;
                const response = await getMe(dispatch);
                if (response?.status === 403) {
                    Storage.removeSecureItem('token');
                    delete axios.defaults.headers.common['Authorization'];
                    dispatch({ type: 'RESET_APP' });
                }
            }
            setDevice(dispatch, { loaded: true });
        })();
    }, [dispatch]);

    useEffect(() => {
        if (user.userType === 'patient' && previousAnalyticsToken.current !== user.analyticsToken) {
            Segment.identify(user.analyticsToken, {
                userType: user.userType,
                system: user.location?.system.name,
                systemId: user.location?.system.id,
                clinic: user.location?.clinic?.name,
                clinicId: user.location?.clinic?.id,
                department: user.location?.department?.name,
                departmentId: user.location?.department?.id,
                activities: user.activities,
            });
            previousAnalyticsToken.current = user.analyticsToken;

            if (!user.isDemoPatient) {
                getPreferences(dispatch);
            }
        } else if (
            user.userType === 'technician' &&
            previousAnalyticsToken.current !== user.analyticsToken
        ) {
            Segment.identify(user.analyticsToken, {
                userType: user.userType,
                system: user.location?.system.name,
                systemId: user.location?.system.id,
                role: user.role,
            });
            previousAnalyticsToken.current = user.analyticsToken;
        } else if (user.userType === '') {
            previousAnalyticsToken.current = null;
        }
    }, [dispatch, user]);

    return null;
};

export default UserMonitor;
