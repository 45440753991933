import { useContext, useEffect } from 'react';
import { getSkills } from 'state/actions/skills';
import StoreContext from 'state/context/store';
import { Skills } from 'state/types';

type IUseSkillsReturn = Skills;

const useSkills = (): IUseSkillsReturn => {
    const [store, dispatch] = useContext(StoreContext);
    const { skills } = store;

    useEffect(() => {
        if (skills.length === 0) {
            getSkills(dispatch);
        }
    }, [dispatch, skills.length]);

    return skills;
};

export default useSkills;
