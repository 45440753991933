const Warning = ({
    color = '#ffffff',
    height = 20,
    ariaHidden = false,
}: {
    color?: string;
    height?: number;
    ariaHidden?: boolean;
}) => {
    const width = height * 1.125;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 16"
            fill="none"
            aria-hidden={ariaHidden ? 'true' : 'false'}
        >
            <path
                d="M17.8474 14.1387L10.0622 0.561605C9.63181 -0.187202 8.55597 -0.187202 8.12563 0.561605L0.340408 14.1387C-0.0899306 14.8875 0.438214 15.8333 1.29889 15.8333H16.8693C17.7496 15.8333 18.2777 14.8875 17.8474 14.1387ZM8.84938 4.71946C9.31884 4.60122 9.7883 4.83769 10.0035 5.27121C10.0817 5.42885 10.1013 5.6062 10.0817 5.76385C10.0426 6.25648 10.023 6.74912 10.0035 7.26146C9.96435 8.02997 9.90567 8.81819 9.86654 9.5867C9.84698 9.82317 9.84698 10.0596 9.82742 10.3158C9.80786 10.7296 9.49489 11.0449 9.08411 11.0449C8.69289 11.0449 8.36036 10.7296 8.3408 10.3355C8.28212 9.13348 8.20387 7.93144 8.14519 6.72941C8.12563 6.41412 8.10607 6.07913 8.08651 5.76385C8.08651 5.29092 8.39948 4.83769 8.84938 4.71946ZM9.10367 13.7446C8.55597 13.7446 8.12563 13.2913 8.12563 12.7396C8.12563 12.1878 8.57553 11.7346 9.12323 11.7346C9.67094 11.7346 10.1013 12.1878 10.1013 12.7593C10.0817 13.2913 9.63181 13.7446 9.10367 13.7446Z"
                fill={color}
            />
        </svg>
    );
};

export default Warning;
