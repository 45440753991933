import { createContext } from 'react';

import { initialState as initialAssessment } from 'state/reducers/assessment';
import { initialState as initialDevice } from 'state/reducers/device';
import { initialState as initialInteractions } from 'state/reducers/interactions';
import { initialState as initialError } from 'state/reducers/error';
import { initialState as initialMedia } from 'state/reducers/media';
import { initialState as initialSkills } from 'state/reducers/skills';
import { initialState as initialStories } from 'state/reducers/stories';
import { initialState as initialUser } from 'state/reducers/user';

import { Dispatch } from 'state/types';
import { CombinedReducerState } from 'state/reducers';

const initialState = {
    assessment: initialAssessment,
    device: initialDevice,
    interactions: initialInteractions,
    error: initialError,
    media: initialMedia,
    skills: initialSkills,
    stories: initialStories,
    user: initialUser,
};

export default createContext<[CombinedReducerState, Dispatch]>([initialState, undefined!]);
