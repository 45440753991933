import { useEffect, useState } from 'react';
import config from '../../../config';
import { useLocation } from 'react-router';
import logo from 'assets/logo.png';
import FormField from '../formField';
import Button from 'components/Button';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import PrivacyPolicyModal from 'components/PrivacyPolicyModal';
interface LocationState {
    setPasswordToken: string;
    setupToken: string;
    setupUid: string;
}

const Account = () => {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showErrors, setShowErrors] = useState(false);
    const [error, setError] = useState('');
    const alreadySetUp = history.location.pathname.includes('reset-password');
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    const togglePrivacyPolicy = () => {
        setShowPrivacyPolicy(() => !showPrivacyPolicy);
    };

    useEffect(() => {
        setShowErrors(false);
    }, [password, confirmPassword]);

    const validate = () => {
        if (!password) {
            return setError('Please provide a password.');
        } else if (!confirmPassword) {
            return setError('Please confirm your password.');
        } else if (password !== confirmPassword) {
            return setError('The two passwords do not match.');
        }
        setError('');
        return true;
    };

    const submit = async (): Promise<void> => {
        if (!validate()) {
            setShowErrors(true);
            return;
        }

        let result;
        const { hash = '' } = history.location;
        if (hash.includes('&userType=Technician')) {
            const [uidHash = '', tokenHash = ''] = hash.slice(1).split('&');
            const [, uid] = uidHash.split('=');
            const [, token] = tokenHash.split('=');
            const payload = {
                password,
                token,
                uid,
            };

            result = await fetch(
                `${config.apiRoot}/${
                    alreadySetUp
                        ? 'technician/reset-password/set-password'
                        : 'technician/activate/set-password$'
                }`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                },
            );
        } else {
            const { setPasswordToken, setupToken, setupUid } = location.state;
            const payload = {
                password,
                token: setupToken,
                uid: setupUid,
                setPasswordToken,
            };

            result = await fetch(
                `${config.apiRoot}/${
                    alreadySetUp ? 'patient/reset-password/set-password' : 'patient/set-password'
                }`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                },
            );
        }

        if (result.ok) {
            if (!alreadySetUp) {
                history.push('/');
            } else {
                history.push('/password-reset/success');
            }
        } else {
            const json = await result.json();
            if (json.password) {
                setError(json.password[0]);
            } else if (json.nonFieldErrors) {
                setError(json.nonFieldErrors[0]);
            } else {
                setError('There was an unknown error.  Please contact support');
            }
            setShowErrors(true);
        }
    };

    return (
        <div className={styles.container}>
            <header>
                <img src={logo} alt="Jaspr Health" /> Jaspr Health
            </header>
            <div className={styles.box}>
                <h1>{alreadySetUp ? 'Reset Password' : 'Account Details'}</h1>
                <FormField
                    label="Password"
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <FormField
                    label="Confirm Password"
                    value={confirmPassword}
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {showErrors && <div className={styles.error}>{error}</div>}
                <Button onClick={submit}>Submit</Button>
                {!alreadySetUp && (
                    <p className={styles.disclaimer}>
                        By setting up an account you agree to our{' '}
                        <a
                            href="https://jasprhealth.com/terms-of-service/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Terms and Conditions
                        </a>{' '}
                        and <button onClick={togglePrivacyPolicy}>Privacy Policy</button>
                    </p>
                )}
            </div>
            <PrivacyPolicyModal open={showPrivacyPolicy} onClickClose={togglePrivacyPolicy} />
        </div>
    );
};

export default Account;
