import { useContext, useEffect, useState } from 'react';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';
import Modal, { Styles } from 'react-modal';
import zIndexHelper from 'lib/zIndexHelper';
import TimeoutModal from 'components/TimeoutModal';
import LockoutModal from 'components/LockoutModal';
import StoreContext from 'state/context/store';
import TimerContext from 'state/context/timer';
import Welcome from 'pages/tour/welcome';
import Consent from 'pages/tour/consent';
import IntroVideo from 'pages/tour/introVideo';
import PatientLoadingScreen from 'pages/patient/loading';
import PatientHome from 'pages/patient/home';
import Breathe from 'pages/patient/breathe';
import Stories from 'pages/patient/stories';
import Skills from 'pages/patient/skills';
import Question from 'pages/patient/question';
import TakeawayKit from 'pages/patient/takeaway';
import useInteractions from 'lib/useInteractions';
import useInterview from 'lib/useInterview';
import useAnswers from 'lib/useAnswers';
import useSkills from 'lib/useSkills';
import useSharedStories from 'lib/useSharedStories';

import Baseline from 'pages/patient/baseline';
import { Patient } from 'state/types';
import CheckinMonitor from 'components/CheckinMonitor';
import usePatientIdleTimer from 'lib/idleTimer/patient';
import OfflineModal from 'components/OfflineModal';

const modalTimeoutStyle: Styles = {
    overlay: {
        display: 'flex',
        justifyContent: 'space-evenly',
        backgroundColor: 'rgba(45, 44, 63, 0.85)',
        zIndex: zIndexHelper('patient.timeout'),
    },
    content: {
        position: 'static',
        display: 'flex',
        width: '523px',
        height: '286px',
        alignSelf: 'center',
    },
};

const modalLockStyle: Styles = {
    overlay: {
        display: 'flex',
        backgroundColor: 'rgba(0,0,0,1)',
        zIndex: zIndexHelper('patient.lockout'),
    },
    content: {
        position: 'static',
        display: 'flex',
        alignSelf: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 0,
        padding: 0,
    },
};

const PatientRouter = () => {
    const history = useHistory();
    const [store] = useContext(StoreContext);
    const { user } = store;
    const { tourComplete, hasSecuritySteps, sessionLocked, technicianOperated, online } =
        user as Patient;
    const [checkedForActiveActivity, setCheckedForActiveActivity] = useState(false);
    const { activate, lock, showTimeoutModal } = usePatientIdleTimer();

    useInteractions(); // Prefetch
    useSkills(); // Prefetch
    useSharedStories(); // Prefetch
    const { activities } = useInterview();
    useAnswers();

    const hasActiveActivity = activities?.some(
        (activity) =>
            !activity.locked &&
            ['suicide_assessment', 'lethal_means', 'stability_plan'].includes(activity.type),
    );

    useEffect(() => {
        if (
            !checkedForActiveActivity &&
            !history.location.pathname.startsWith('/start-patient-session')
        ) {
            if (technicianOperated) {
                history.replace('/takeaway/stability-plan/0');
                setCheckedForActiveActivity(true);
            } else if (hasActiveActivity) {
                history.replace('/question');
                setCheckedForActiveActivity(true);
            }
        }
    }, [
        history.location.pathname,
        checkedForActiveActivity,
        hasActiveActivity,
        history,
        technicianOperated,
    ]);

    if (!user.authenticated || user.userType !== 'patient') {
        return null;
    }

    const isPath3 = !activities?.some((activity) =>
        ['stability_plan', 'suicide_assessment'].includes(activity.type),
    );
    const redirectToTour = !(tourComplete || (isPath3 && hasSecuritySteps));

    return (
        <TimerContext.Provider value={{ sendHeartbeat: activate }}>
            <PatientLoadingScreen />
            <CheckinMonitor />
            <Modal isOpen={!online} style={modalTimeoutStyle}>
                <OfflineModal />
            </Modal>
            <Modal isOpen={showTimeoutModal} style={modalTimeoutStyle}>
                <TimeoutModal resetTimer={activate} lockout={lock} />
            </Modal>
            <Modal isOpen={sessionLocked || false} style={modalLockStyle}>
                <LockoutModal resetTimer={activate} />
            </Modal>
            <Switch>
                <Route
                    path="/"
                    exact
                    render={() => (
                        <>
                            {redirectToTour && (
                                <Redirect
                                    to={{
                                        pathname: '/welcome',
                                    }}
                                />
                            )}
                            {!redirectToTour && <PatientHome />}
                        </>
                    )}
                />
                <Route path="/welcome" component={Welcome} />
                <Route path="/consent" component={Consent} />
                <Route path="/intro-video" component={IntroVideo} />
                <Route path="/baseline" component={Baseline} />
                <Route exact path="/breathe" component={Breathe} />
                <Route exact path="/stories" component={Stories} />
                <Route exact path="/skills" component={Skills} />
                <Route
                    path="/question"
                    exact
                    render={() => (
                        <>
                            {redirectToTour && (
                                <>
                                    <Redirect
                                        to={{
                                            pathname: '/welcome',
                                        }}
                                    />
                                </>
                            )}
                            {!redirectToTour && <Question />}
                        </>
                    )}
                />
                <Route path="/takeaway" component={TakeawayKit} />
                {/* /start-patient-session is handled higher up since it crosses user boundaries
                        Match it here to prevent the catchall from redirecting */}
                <Route path="/start-patient-session" render={() => null} />
                <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
        </TimerContext.Provider>
    );
};

export default PatientRouter;
