import { useContext, useEffect } from 'react';
import { getAnswers } from 'state/actions/assessment';
import StoreContext from 'state/context/store';
import { AssessmentAnswers, Patient } from 'state/types';

type IUseAnswersReturn = [AssessmentAnswers, boolean];

const useAnswers = (): IUseAnswersReturn => {
    const [store, dispatch] = useContext(StoreContext);
    const { authenticated, isDemoPatient } = store.user as Patient;
    const { answers, fetched } = store.assessment;

    useEffect(() => {
        if (authenticated && !isDemoPatient && !fetched) {
            getAnswers(dispatch);
        }
    }, [dispatch, authenticated, isDemoPatient, fetched]);

    return [answers, fetched];
};

export default useAnswers;
