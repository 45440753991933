import { useContext } from 'react';
import StoreContext from 'state/context/store';
import { Patient } from 'state/types';
import { addAction, ExtraActionData } from 'state/actions/analytics';
import { actionNames } from 'state/actionNames';

type IUseActionsReturn = [addAction: typeof addAction, actionNames: typeof actionNames];

const useActions = (): IUseActionsReturn => {
    const [store] = useContext(StoreContext);
    const { isDemoPatient } = store.user as Patient;

    const queueAction = async (actionName: string, actionData: ExtraActionData = {}) => {
        if (!isDemoPatient) {
            addAction(actionName, actionData);
        }
    };

    return [queueAction, actionNames];
};

export default useActions;
