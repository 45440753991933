import { useHistory } from 'react-router-dom';
import styles from './skill.module.scss';
import { ReactComponent as Heart } from 'assets/heart.svg';
import { Skill as SkillType, Interactions } from 'state/types';
import useInteraction from 'lib/useInteraction';

type SkillProps = SkillType & {
    inCRP?: boolean;
    setSelectedVideoActivityId: (videoActiviyId: number) => void;
    interactions: Interactions;
};

const Skill = ({
    id,
    targetUrl,
    name,
    thumbnailImage,
    labelColor,
    inCRP,
    video,
    setSelectedVideoActivityId,
}: SkillProps) => {
    const history = useHistory();

    const [interaction, saveInteraction] = useInteraction(
        video ? video.id.toString() : 'breathe',
        video ? 'video' : 'breathe',
    );

    const progress = video ? interaction?.progress || 0 : 0;

    const goToSkill = () => {
        if (video) {
            setSelectedVideoActivityId(id);
        } else if (targetUrl) {
            history.push({
                pathname: targetUrl,
                state: {
                    from: history.location.pathname,
                },
            });
        }
    };

    const toggleOnPlan = (saveForLater: boolean) => {
        saveInteraction({
            saveForLater: !saveForLater,
        });
    };

    return (
        <div
            className={`${styles.container} ${inCRP ? styles.small : ''}`}
            style={{ minHeight: '300px' }}
        >
            <div className={styles.inner} style={{ boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)' }}>
                <div
                    className={`${styles.save} ${interaction?.saveForLater ? styles.saved : ''}`}
                    onClick={() => toggleOnPlan(interaction?.saveForLater || false)}
                    style={{ cursor: 'pointer' }}
                >
                    <span className={styles.saveText}>
                        {interaction?.saveForLater ? 'Remove from favorites' : 'Add to favorites'}
                    </span>
                    <Heart />
                </div>
                {Boolean(video) && (
                    <div className={styles.progress}>
                        <div className={styles.track} style={{ width: `${progress}%` }} />
                    </div>
                )}

                {Boolean(thumbnailImage) && (
                    <img
                        className={styles.thumb}
                        src={thumbnailImage || undefined}
                        onClick={goToSkill}
                        alt={name}
                    />
                )}
                <div
                    className={`${styles.banner} ${styles.bannerText}`}
                    onClick={goToSkill}
                    style={{ backgroundColor: labelColor || undefined }}
                >
                    {name}
                </div>
            </div>
        </div>
    );
};

export default Skill;
