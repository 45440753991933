import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import * as Sentry from '@sentry/browser';
import styles from './index.module.scss';
import Question from './question';
import { AssignedActivities, AssignedActivity, UIDType } from './questions';
import { AssessmentAnswers, Dispatch } from 'state/types';
import StoreContext from 'state/context/store';

interface QuestionsProps {
    disableAnalytics?: boolean;
    activities: AssignedActivities;
    firstQuestionUID?: UIDType;
    currentSectionUid: string;
    lockActivity: (activity: AssignedActivity) => void;
    ready?: boolean;
    setCurrentSectionUid: (dispatch: Dispatch, currentSectionUid: string) => void;
    answers: AssessmentAnswers;
    getQuestions: (dispatch: Dispatch) => void;
    saveAnswers: any;
}

const Questions = (props: QuestionsProps) => {
    const {
        disableAnalytics = false,
        firstQuestionUID,
        activities,
        ready,
        lockActivity,
        currentSectionUid,
        setCurrentSectionUid,
        answers,
        getQuestions,
        saveAnswers,
    } = props;
    const [, dispatch] = useContext(StoreContext);
    const [assessmentResumptionCheck, setAssessmentResumptionCheck] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null!);
    const surveyRef = useRef(null);
    const lastQuestionRef = useRef<HTMLDivElement>(null);
    const questionsRef = useRef([]);
    const prevIndex = useRef<number | null>(0);
    const [currentActivity, setCurrentActivity] = useState<string | null>(null);
    const activeActivities = activities
        .filter((activity) => !activity.locked)
        .filter((activity) => Array.isArray(activity.questions) && activity.questions.length > 0);

    const activity = activeActivities.find(
        (activity) => activity.type === currentActivity,
    ) as AssignedActivity;
    const questions = useMemo(
        () =>
            activity?.questions?.filter(
                (question) => !question.actions.some((action) => action.type === 'section-change'),
            ) || [],
        [activity?.questions],
    );
    const currentIndex = questions.findIndex(
        (question) => question.uid === currentSectionUid && currentSectionUid !== null,
    );
    const numberOfQuestions = questions?.length || 0;

    useEffect(() => {
        let currentActivity = activeActivities[0];
        if (currentSectionUid) {
            currentActivity =
                activeActivities.find((activity) =>
                    activity.questions?.some((question) => question.uid === currentSectionUid),
                ) || currentActivity;
        }

        if (currentActivity) {
            setCurrentActivity(currentActivity.type);
            if (
                currentActivity.questions?.findIndex(
                    (question) => question.uid === currentSectionUid,
                ) === -1
            ) {
                setCurrentSectionUid(
                    dispatch,
                    currentActivity.questions.filter(
                        (question) => !question.uid.startsWith('sectionChange'),
                    )?.[0]?.uid,
                );
            }
        }
    }, [activeActivities, currentSectionUid, dispatch, setCurrentSectionUid]);

    const goToQuestion = useCallback(
        (sectionUid: string) => setCurrentSectionUid(dispatch, sectionUid),
        [dispatch, setCurrentSectionUid],
    );

    useEffect(() => {
        if (numberOfQuestions > 0) {
            const skipAhead = prevIndex.current !== currentIndex - 1;
            prevIndex.current = currentIndex;
            if (lastQuestionRef.current) {
                scrollIntoView(lastQuestionRef.current, {
                    behavior: skipAhead ? 'auto' : 'smooth',
                    block: 'start',
                });
            }
        }
    }, [currentIndex, numberOfQuestions]);

    useEffect(() => {
        if (
            ready &&
            numberOfQuestions > 0 &&
            firstQuestionUID &&
            !assessmentResumptionCheck &&
            currentSectionUid &&
            currentSectionUid !== firstQuestionUID
        ) {
            // Resume from last question
            goToQuestion(currentSectionUid);
            setAssessmentResumptionCheck(true);
        }
    }, [
        goToQuestion,
        currentSectionUid,
        assessmentResumptionCheck,
        firstQuestionUID,
        numberOfQuestions,
        ready,
    ]);

    const goToNextActivity = useCallback(() => {
        if (activity) {
            lockActivity(activity);
            const activityIndex = activeActivities.findIndex(
                (activeActivity) => activeActivity.id === activity.id,
            );
            if (activityIndex + 1 < activeActivities.length) {
                const nextSectionUid = activeActivities[activityIndex + 1]?.questions?.find(
                    (question) => !question.uid.startsWith('sectionChange'),
                )?.uid;
                if (nextSectionUid) {
                    setCurrentSectionUid(dispatch, nextSectionUid);
                }
            }
        }
    }, [activeActivities, activity, dispatch, lockActivity, setCurrentSectionUid]);

    const next = useCallback(
        (advance = true, goTo: UIDType[] = [], completeActivity = false): void => {
            // The timeout is needed to make sure changes to answers that may be needed for
            // showing or hiding questions needs to populate in the reducer.  This has the
            // added benefit of giving the button change animation to occur before scrolling to
            // the next question
            window.setTimeout(() => {
                if (currentIndex < questions.length - 1 && advance) {
                    if (completeActivity) {
                        goToNextActivity();
                    } else if (goTo.length) {
                        let sectionFound = false;
                        goTo.forEach((section) => {
                            // Can only skip to questions in the same module
                            const nextQuestion = questions.find(
                                (question) => question.uid === section,
                            );
                            if (nextQuestion) {
                                setCurrentSectionUid(dispatch, nextQuestion.uid);
                                sectionFound = true;
                            }
                        });
                        if (!sectionFound) {
                            setCurrentSectionUid(dispatch, questions[currentIndex + 1].uid);
                        }
                    } else {
                        setCurrentSectionUid(dispatch, questions[currentIndex + 1].uid);
                    }
                } else if (advance) {
                    goToNextActivity();
                }
            }, 250);
        },
        [currentIndex, dispatch, goToNextActivity, questions, setCurrentSectionUid],
    );

    useEffect(() => {
        if (assessmentResumptionCheck && activity && currentIndex === -1) {
            // TODO let's remove the section change questions in favor of a different delineation method
            if (!currentSectionUid?.startsWith('sectionChange')) {
                console.warn('CUI in Invalid State', activity, currentSectionUid);
                Sentry.captureException(
                    `CUI in invalid state.  Current section uid is ${currentSectionUid} and active activity is ${activity.id} ${activity.type} ${activity.status}`,
                );
            }
            next();
        }
    }, [activity, assessmentResumptionCheck, currentIndex, currentSectionUid, next]);

    return (
        <div className={styles.container} ref={containerRef}>
            <div className={styles.survey} ref={surveyRef}>
                {questions
                    ?.filter((val, idx) => idx <= currentIndex)
                    .map((questionGroup, idx) => {
                        return (
                            <Question
                                key={`${questionGroup.uid}-${idx}`}
                                activityId={activity?.id}
                                questionGroup={questionGroup}
                                idx={idx}
                                currentIndex={currentIndex}
                                lastQuestionRef={lastQuestionRef}
                                next={next}
                                uid={questionGroup.uid}
                                questions={questionsRef.current}
                                disableAnalytics={disableAnalytics}
                                activities={activeActivities}
                                lockActivity={lockActivity}
                                answers={answers}
                                getQuestions={getQuestions}
                                saveAnswers={saveAnswers}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default Questions;
