import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import Segment, { AnalyticNames } from 'lib/segment';

import TabbedView from './tabbed';
import CarouselView from './carousel';
import { Video } from 'state/types';
import useSharedStories from 'lib/useSharedStories';

export interface SelectedStory {
    name: string;
    videos: Video[];
}

type Props = {
    tabbed?: boolean;
};

const Stories = ({ tabbed = false }: Props) => {
    const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
    const [tab, setTab] = useState<'person' | 'topic'>('person');
    const [openThumbnail, setOpenThumbnail] = useState<string | null>(null);
    const [selectedStory, setSelectedStory] = useState<SelectedStory | null>(null);
    const stories = useSharedStories();
    const { people, topics } = stories;

    useEffect(() => {
        if (!selectedVideo) {
            Segment.track(AnalyticNames.VIDEO_PLAYER_CLOSED);
        }
    }, [selectedVideo]);

    const getTopicLabel = (name: string): string => {
        try {
            return name.split('-')[0];
        } catch {
            Sentry.captureMessage(`video tag ${name} is nor formatted correctly.`);
            return name;
        }
    };

    const getPersonLabel = (name: string): string => {
        try {
            return name.split('-')[1];
        } catch {
            Sentry.captureMessage(`video tag ${name} is nor formatted correctly.`);
            return name;
        }
    };

    if (!tabbed) {
        return (
            <CarouselView
                people={people}
                topics={topics}
                selectedVideo={selectedVideo}
                setSelectedVideo={setSelectedVideo}
                openThumbnail={openThumbnail}
                setOpenThumbnail={setOpenThumbnail}
                getPersonLabel={getPersonLabel}
                getTopicLabel={getTopicLabel}
            />
        );
    }
    return (
        <TabbedView
            people={people}
            topics={topics}
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            tab={tab}
            setTab={setTab}
            setOpenThumbnail={setOpenThumbnail}
            getPersonLabel={getPersonLabel}
            getTopicLabel={getTopicLabel}
            selectedStory={selectedStory}
            setSelectedStory={setSelectedStory}
        />
    );
};

export default Stories;
