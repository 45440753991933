import Modal, { Styles as ModalStyles } from 'react-modal';
import styles from './index.module.scss';

const privacyPolicyModalStyles: ModalStyles = {
    overlay: {
        display: 'flex',
        justifyContent: 'space-evenly',
        backgroundColor: 'rgba(45, 44, 63, 0.85)',
        zIndex: 10,
    },
    content: {
        position: 'static',
        display: 'flex',
        width: '90vw',
        minWidth: '800px',
        height: '90vh',
        minHeight: '523px',
        alignSelf: 'center',
    },
};

type Props = {
    open: boolean;
    onClickClose: () => void;
};

const TermsServiceModal = (props: Props) => {
    const { open, onClickClose } = props;

    return (
        <Modal
            isOpen={open}
            style={privacyPolicyModalStyles}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            aria={{
                labelledby: 'privacyModalHeading',
                describedby: 'privacyModalFullContent',
            }}
        >
            <div>
                <h1 id="privacyModalHeading">Terms of Service</h1>
                <div id="privacyModalFullContent">
                    <p>
                        <strong>Last Updated:</strong> Mar 19, 2024
                    </p>
                    <p>
                        IF YOU THINK YOU MAY HAVE A MEDICAL EMERGENCY OR ARE CONTEMPLATING ACTING
                        UPON THOUGHTS OF SUICIDE, CALL 911 OR THE NATIONAL SUICIDE PREVENTION
                        LIFELINE (988) IMMEDIATELY.
                    </p>

                    <h3>Agreement</h3>
                    <p>
                        These terms and conditions (“Service Terms”) govern your (“you” or “your”)
                        access to, and use of, the Jaspr Health website (the “Site”) and services,
                        including the Jaspr Health web application and/or any software applications
                        provided by Jaspr Health (collectively the “Services”) of Evidence-Based
                        Practice Institute, Inc., dba Jaspr Health (“Jaspr Health”). These Service
                        Terms do not alter in any way the terms of any written agreement signed by
                        you and Jaspr Health. By using the Services, you accept these Service Terms
                        and all agreements, documents, or policies incorporated by reference. If you
                        are using the Services on behalf of any entity, you represent and warrant
                        that you are authorized to accept these Service Terms on such entity's
                        behalf and the term “you” shall refer to you personally and such entity. The
                        Site and Services are both owned by Jaspr Health.
                    </p>
                    <p>
                        Jaspr Health reserves the right to change or modify any of the terms and
                        conditions contained in these Service Terms or any policy or guideline or to
                        modify or discontinue the Services, including any software, mobile or web
                        applications (“App(s)”), products, courses or other services offered by
                        Jaspr Health at any time and in its sole discretion. We will give you notice
                        of any changes or modification to these Service Terms or any policy or
                        guideline by posting the revised agreement on the applicable website(s) or
                        app(s), notifying you upon opening the applicable app or Service, and/or
                        sending you an email to the last email address you provided to us (if any).
                        Any changes or modification will go into effect after the new effective date
                        and by your continued use of Jaspr Health Services following the new
                        effective date will constitute your acceptance of such changes or
                        modifications. We may require you to provide consent to the updated
                        agreement(s) before further use of the Service(s) is permitted. Your
                        continued use of Jaspr Health Services following the posting of its changes
                        or modifications will constitute your acceptance of such changes or
                        modifications. Some of our services may have additional rules, policies, and
                        procedures (“Additional Terms”). Where Additional Terms apply, we will make
                        them available to you to read through in connection with your use of that
                        Service. By using that Service, you agree to the Additional Terms.
                    </p>
                    <p>
                        Our Services may incorporate, link to, or otherwise include information,
                        data, documentation, instructions, guides, or other content prepared by
                        Jaspr Health and/or third parties (“Content”). The use of Jaspr Health and
                        its Content is at your own risk. Jaspr Health, its contributors, and their
                        respective agents, representatives, affiliates, employees, heirs, and
                        successors in interest, assume no liability for, or relating to, the delay,
                        failure, interruption, or corruption of any data or other information
                        transmitted in connection with use of Jaspr Health services.
                    </p>
                    <h3>Eligibility</h3>
                    <p>
                        You agree to provide us with complete and current registration information.
                        You are not eligible to use our Services unless you are at least 13 years
                        old and otherwise have the legal capacity to enter into a binding contract
                        in your jurisdiction. Persons under 13 years of age are prohibited from
                        providing personal information on our Website(s) or via our App(s). Under no
                        circumstances may our Services be used by a child under 13 years old. If you
                        are an unemancipated minor over the age of 13, you may only use our Services
                        if such use has been approved of by your parent or legal guardian, you use
                        our Services under their or a designate's supervision, and your parent or
                        legal guardian has agreed to these Terms (and, if applicable, your User
                        Agreement) on your behalf. By using the Services you represent that you are
                        of legal age and authority to enter into these Service Terms.
                    </p>
                    <p>
                        If you are the parent, guardian or designate of an unemancipated minor, you
                        may use the Services and parents and guardians may enter into these Service
                        Terms on behalf of such minor. By doing so, you represent and warrant that
                        you have the legal capacity to act on behalf of such minor; and you
                        acknowledge and agree, in such capacity, that all provisions of these Terms
                        (and User Agreement, if applicable) that applicable to you are equally
                        applicable to such minor.
                    </p>
                    <h3>Privacy Policy and Feedback</h3>
                    <p>
                        Please refer to our Privacy Policy, which is fully incorporated herein by
                        reference, for information on how Jaspr Health collects, uses and discloses
                        personally identifiable and other information from its users. Jaspr Health
                        does not use information about a patient except as necessary to provide the
                        Services. Jaspr Health. You hereby agree to be bound by Jaspr Health's
                        Privacy Policy.
                    </p>
                    <p>
                        If you provide us with any feature requests, comments, suggestions or other
                        feedback (“Feedback”), you hereby: (i) agree that such Feedback is provided
                        on a non-proprietary and non-confidential basis, and (ii) grant us a
                        perpetual, non-exclusive, worldwide, royalty free and fully paid-up, freely
                        transferable, irrevocable license, with the right to sublicense through
                        infinite levels of sublicensees, to incorporate, copy, reproduce, adapt,
                        disclose, and use without limitation the Feedback for any purpose.
                    </p>
                    <h3>Registration for the Service</h3>
                    <p>
                        If you wish to utilize the Services, you will be required to register by
                        providing information about yourself (or your designee) and, if you are
                        registering on behalf of an entity, information about that entity (such as
                        identification, credit card information and contact details). You represent
                        that any such information you provide is accurate, complete and updated.
                        Failure to do so constitutes a breach to these Service Terms and may result
                        in a termination of your account and access to the Services. You are
                        responsible for maintaining the security and confidentiality of your account
                        password. You are also solely responsible for all activities that occur
                        through your User ID and password. You agree not to access or use, or
                        attempt to access or use, the Services or any part thereof using the
                        identity, User ID or password of any person other than you. You agree to
                        immediately notify Jaspr Health of any unauthorized use of your User ID or
                        password.
                    </p>
                    <h3>Intellectual Property</h3>
                    <p>
                        User Content & Data Repository. The Services allow you to add personal
                        and/or patient content (“User Content”). Jaspr Health has the right to
                        refuse any User Content. User Content does not become the property of Jaspr
                        Health. Jaspr Health may remove personally identifying data regarding users,
                        patients, health care organizations, and health treatment providers to
                        create anonymized data and use such anonymized data for purposes of research
                        and development. Anonymized data shall not be considered to be User Content.
                        Jaspr may use, disclose, market, license and sell anonymized data for
                        educational, professional, product improvement and research purposes without
                        restriction, and you have no interest in such information, or in the
                        proceeds of any sale, license, or other commercialization of anonymized
                        data. Jaspr Health will only use personally identifying data in accordance
                        with the terms of its Privacy Policy. You acknowledge that a significant
                        portion of your consideration for the provision of the Services is the
                        rights conferred by this Section, without which we would not enter into
                        these Service Terms.
                    </p>
                    <p>
                        If an account containing User Content is deactivated, Jaspr Health will
                        archive its User Content for a period of five years (or longer if required
                        by law) as necessary to comply with legal obligations, resolve disputes, and
                        enforce our agreements and other authorized uses under these Service Terms.
                        By adding User Content, you grant Jaspr Health and its affiliates the
                        perpetual, irrevocable, worldwide, royalty-free, assignable, non-exclusive
                        right to use, reproduce, transmit, display and distribute such User Content
                        as set forth in the Privacy Policy. Jaspr Health will not publicly display
                        your User Content that is protected health information except as is
                        permitted by the Health Insurance Portability and Accountability Act (HIPAA)
                        and the Health Information Technology for Economic and Clinical Health
                        (HITECH) Act to the extent it applies to our Services. Only agents who are
                        explicitly authorized by patients to have access to their healthcare
                        information may access protected health information within Jaspr Health.
                    </p>
                    <p>
                        If you are a healthcare provider or agent authorized by a healthcare
                        provider entering User Content on behalf of a patient, you agree that the
                        User Content you provide on the patient's behalf is accurate and thorough;
                        that the patient has agreed to allow you to use our Services and provided
                        you will all necessary consents; that you will review and update information
                        for accuracy and that you will immediately remove any User Content entered
                        on the patient's behalf when instructed to do so by the patient.
                    </p>
                    <p>
                        Because the information collected by Jaspr Health is intended to be used in
                        conjunction with health care and interpreted by a healthcare professional,
                        we recommend that patients work directly with healthcare providers when
                        viewing, updating, and deactivating User Content.
                    </p>
                    <p>
                        HIPAA grants patients certain rights to access and correct certain health
                        information their healthcare providers retain about them. Patients should
                        submit requests to access or correct their health information directly to
                        their healthcare providers.
                    </p>
                    <p>
                        You represent and warrant that (a) you own and control all of the rights to
                        the User Content that you add to Jaspr Health or you otherwise have the
                        right to post such User Content and to grant the rights granted herein; (b)
                        the User Content is accurate and not misleading; and (c) distribution of the
                        User Content you supply in accordance with your direction does not violate
                        these Service Terms and will not violate any rights of or cause injury to
                        any person or entity. You hereby waive any moral rights you may have in such
                        User Content only to the extent necessary to allow Jaspr Health to provide
                        the Services.
                    </p>
                    <p>
                        The Site and Services and all content, including, without limitation, the
                        Jaspr Health trademarks, Jaspr Health logos, and all designs, text,
                        graphics, images, information, data, software, documentation, sound files,
                        other files and the selection and arrangement thereof are the property of,
                        Jaspr Health and are protected by U.S., Canadian, and international
                        copyright, trade secret and other intellectual property laws. Unless
                        explicitly stated herein, nothing in these Terms of Service shall be
                        construed as conferring any license to intellectual property rights, whether
                        by estoppel, implication or otherwise.
                    </p>
                    <p>
                        You agree that Jaspr Health, at its sole discretion, has the right to
                        screen, reject, or remove any User Content, or portion thereof, saved on our
                        servers that infringes our, or any third party intellectual property rights.
                        Jaspr Health reserves the right to disclose any such material saved on our
                        servers as necessary to satisfy any applicable law, regulation, legal
                        process or governmental request.
                    </p>
                    <p>
                        You should save all of your User Content through alternate storage solutions
                        inasmuch as Jaspr Health does not warrant the security or reliability of its
                        Services.
                    </p>
                    <p>
                        You are responsible for your use of the Services, and for any use of the
                        Services made using your account. Our goal is to create a positive,
                        rewarding, and safe experience in connection with our Services. To promote
                        this goal, we prohibit certain kinds of conduct that may be harmful to other
                        users or to Jaspr Health. You agree not to engage in any of the following in
                        your use of the Services:
                    </p>
                    <ul>
                        <li>Violate any laws or regulation;</li>
                        <li>
                            Post content that is intentionally false, deceptive, deceitful,
                            harassing, threatening;
                        </li>
                        <li>
                            Post content that may infringe any patent, trademark, trade secret,
                            copyright or other intellectual or proprietary right of any party. By
                            posting any content, you represent and warrant that you have the right
                            to distribute and reproduce such content as provided under these Service
                            Terms;
                        </li>
                        <li>
                            Post content that impersonates any person or entity or otherwise
                            misrepresents your affiliation with a person or entity or perform any
                            other similar fraudulent activity, such as phishing;
                        </li>
                        <li>
                            Transmit any viruses, spyware, malware, corrupted data or other harmful,
                            disruptive or destructive files;
                        </li>
                        <li>
                            Use any means to scrape or crawl any Web pages or content contained in
                            the Site or Services (although Company may allow operators of public
                            search engines to use spiders to index materials from the Site for the
                            sole purpose of creating publicly available searchable indices of the
                            materials, and Company reserves the right to revoke these exceptions
                            either generally or in specific cases);
                        </li>
                        <li>Use automated methods to use the Site or Apps;</li>
                        <li>
                            Attempt to circumvent any technological measure implemented by Jaspr
                            Health or any of Jaspr Health's providers or any other third party
                            (including another user) to protect the Services, Site, App, or any
                            Jaspr Health system;
                        </li>
                        <li>
                            Resell, lease, distribute, publicly display, or rent the Site or the
                            Service;
                        </li>
                        <li>
                            Use the Site or Services for anything other than their intended purpose;
                        </li>
                        <li>
                            use of any portion of the Site or Service as a destination linked from
                            any unsolicited bulk messages or unsolicited commercial messages;
                        </li>
                        <li>
                            Make the Site or Services available to any third parties without
                            permission from Jaspr Health;
                        </li>
                        <li>
                            Attempt to decipher, decompile, disassemble, or reverse engineer any of
                            the software used to provide the Services; or
                        </li>
                        <li>
                            Advocate, encourage, or assist any third party in doing any of the
                            foregoing.
                        </li>
                    </ul>
                    <p>
                        Any use of the Site and/or Service that violates these Terms is strictly
                        prohibited and may result in your exclusion from the Site and/or Service.
                        Unauthorized use may also violate applicable laws including without
                        limitation copyright and trademark laws and applicable communications
                        regulations and statutes. You agree to use the Site and/or Service and any
                        information obtained from the Service and your use thereof solely for the
                        purposes authorized by these Terms of Service. You will not circumvent Jaspr
                        Health's intended limitations for any feature of the Service as reflected in
                        the Jaspr Health documentation from time to time. You will not encourage or
                        promote the use of the Service in any manner or for any purpose that is not
                        permitted under these Terms of Service. You will not use or attempt to use
                        the Service in any manner that is unfair, deceptive, or otherwise unlawful
                        or harmful to Jaspr Health, any Jaspr Health clients, or any other third
                        party.
                    </p>
                    <p>
                        If you elect to download the App, the following also applies: Jaspr Health
                        grants you a personal, revocable, non-exclusive, non-transferable license
                        (without a right to sublicense) to download, install and use a copy of the
                        App on a single mobile device or computer that you own or control solely for
                        your personal and professional use, subject at all times to these Service
                        Terms, including the restrictions on use, the acceptable use provisions and
                        our right to remove your Content (as defined below) at our discretion at any
                        time. Furthermore, with respect to any App accessed through or downloaded
                        from an App Store such as the Android Market or Apple App Store (an “App
                        Store Sourced Application”), you will only use the App Store Sourced
                        Application: (i) on a product that runs the operating system for which it
                        was intended and (ii) as permitted by the “Usage Rules” set forth in the
                        corresponding App Store. Use of the App from a third-party App Store is also
                        subject to the provisions of Section 10.
                    </p>
                    <h3>Links to Other Sites</h3>
                    <p>
                        Jaspr Health may provide links to third-party web sites. Jaspr Health also
                        may select certain sites as priority responses as a result of entries
                        provided by you when setting up the Jaspr Health App and/or other services.
                        Jaspr Health does not recommend and does not endorse the content on any
                        third-party sites. Jaspr Health does not monitor and is not responsible for
                        the content of linked third-party sites, sites framed within the Jaspr
                        Health Mobile Application and/or other services, third-party sites provided,
                        or third-party advertisements, and do not make any representations regarding
                        their content or accuracy. Your use of third-party sites is at your own risk
                        and subject to the terms and conditions of use for such sites. Jaspr Health
                        does not endorse any product, service, or treatment advertised on such
                        sites.
                    </p>
                    <h3>Payment</h3>
                    <p>
                        Services are provided to subscribers of Jaspr Health or provided to patients
                        and/or employees of subscribing organizations (e.g., hospitals, other health
                        care organizations). If you subscribe to the Services, you shall be charged
                        in advance for such amount as you select when subscribing for the Services
                        until such time as you or Jaspr Health terminate the Services. Termination
                        of your account will result in no further charges being made upon your
                        credit card or other account, but no refunds shall be issued for any reason
                        except where promised herein or within Jaspr Health's sole discretion. All
                        charges and payments shall be in U.S. dollars. Jaspr Health reserves the
                        right to collect certain taxes or other assessments from you in order to
                        comply with local, state, federal, or international laws and regulations, as
                        required now or later imposed. If you claim exemption from any taxes, you
                        shall provide Jaspr Health with documentation required by the taxing
                        authority to support an exemption. Jaspr Health reserves the right to change
                        the price of its Services upon (30) days' notice to you and you will be
                        given the opportunity to terminate the Services prior to such increase
                        becoming effective.
                    </p>
                    <h3>Consent to Receive Emails and Notice</h3>
                    <p>
                        As long as you maintain your registration, you may not “opt out” of
                        receiving account-related emails from Jaspr Health. The parties hereto may
                        give legal notice by means of electronic mail, which electronic mail shall
                        be considered delivered when sent. The notice address of Jaspr Health shall
                        be no-reply@jasprhealth.com or such other address as is provided by Jaspr
                        Health to you). Your address for the receipt of notices pursuant to these
                        Service Terms shall be the current email address listed by you in your
                        account profile.
                    </p>
                    <h3>Repeat Infringer Policy</h3>
                    <p>
                        In accordance with the Digital Millennium Copyright Act (DMCA) and other
                        applicable law, Jaspr Health has adopted a policy of terminating, in
                        appropriate circumstances and at Jaspr Health's sole discretion, access to
                        those who are deemed to be repeat infringers. Jaspr Health may also, at its
                        sole discretion, limit access to the Services and/or terminate the accounts
                        of any users who infringe any intellectual property rights of others.
                    </p>
                    <p>
                        We will respond to notices of alleged copyright infringement that comply
                        with applicable law and are properly provided to us. If you believe that
                        your copyrighted materials have been copied in a way that constitutes
                        copyright infringement, please provide us with the following information:
                        (i) a physical or electronic signature of the copyright owner or a person
                        authorized to act on their behalf; (ii) identification of the copyrighted
                        work claimed to have been infringed; (iii) identification of the material
                        that is claimed to be infringing or to be the subject of infringing activity
                        and that is to be removed or access to which is to be disabled, and
                        information reasonably sufficient to permit us to locate the material; (iv)
                        your contact information, including your address, telephone number, and an
                        email address; (v) a statement by you that you have a good faith belief that
                        use of the material in the manner complained of is not authorized by the
                        copyright owner, its agent, or the law; and (vi) a statement that the
                        information in the notification is accurate, and, under penalty of perjury,
                        that you are authorized to act on behalf of the copyright owner.
                    </p>
                    <p>
                        Our designated copyright agent for notice of alleged copyright infringement
                        appearing on the Services is:
                    </p>
                    <p>
                        Jaspr Health
                        <br />
                        9450 SW Gemini Dr
                        <br />
                        PMB 68735
                        <br />
                        Beaverton, Oregon 97008-7105
                    </p>
                    <p>Email address: copyright@jasprhealth.com</p>
                    <h3>App Store</h3>
                    <p>
                        When you download our Services or Apps, you may do so through a third
                        party's App Store. You acknowledge that the terms of these Service Terms are
                        between you and us and not with the owner or operator of the App Store (“App
                        Store Owner”). As between the App Store Owner and us, we, and not the App
                        Store Owner, are solely responsible for the Services, including the App, the
                        content, maintenance, support services, and warranty, and addressing any
                        claims relating thereto (e.g., product liability, legal compliance or
                        intellectual property infringement). In order to use the App, you must have
                        access to a wireless network, and you agree to pay all fees associated with
                        such access. You also agree to pay all fees (if any) charged by the App
                        Store Owner in connection with the Services, including the App. The
                        following applies to any App Store Sourced Application:
                    </p>
                    <ul>
                        <li>
                            Your use of the App Store Sourced Application must comply with the App
                            Store's “Terms of Service” or equivalent terms.
                        </li>
                        <li>
                            You acknowledge that the App Store Owner has no obligation whatsoever to
                            furnish any maintenance and support services with respect to the App
                            Store Sourced Application.
                        </li>
                        <li>
                            In the event of any failure of the App Store Sourced Application to
                            conform to any applicable warranty, you may notify the App Store Owner,
                            and the App Store Owner will refund the purchase price for the App Store
                            Sourced Application to you (if any) and to the maximum extent permitted
                            by applicable law, the App Store Owner will have no other warranty
                            obligation whatsoever with respect to the App Store Sourced Application.
                            As between Company and the App Store Owner, any other claims, losses,
                            liabilities, damages, costs or expenses attributable to any failure to
                            conform to any warranty will be the sole responsibility of Company.
                        </li>
                        <li>
                            You and we acknowledge that, in the event of any third-party claim that
                            the App Store Sourced Application or your possession and use of that App
                            Store Sourced Application infringes that third party's intellectual
                            property rights, as between Jaspr Health and the App Store Owner, Jaspr
                            Health, not the App Store Owner, will be solely responsible for the
                            investigation, defense, settlement and discharge of any such
                            intellectual property infringement claim to the extent required by these
                            Service Terms.
                        </li>
                        <li>
                            You and we acknowledge and agree that the App Store Owner, and the App
                            Store Owner's subsidiaries, are third-party beneficiaries of these
                            Service Terms as related to your license of the App Store Sourced
                            Application, and that, upon your acceptance of these Service Terms, the
                            App Store Owner will have the right (and will be deemed to have accepted
                            the right) to enforce the terms of these Service Terms as related to
                            your license of the App Store Sourced Application against you as a
                            third-party beneficiary thereof.
                        </li>
                        <li>
                            Without limiting any other terms in these Service Terms, you must comply
                            with all applicable third-party terms of agreement when using the App
                            Store Sourced Application.
                        </li>
                        <li>
                            You and we acknowledge that, as between Jaspr Health and the App Store
                            Owner, the App Store Owner is not responsible for addressing any claims
                            you have or any claims of any third party relating to the App Store
                            Sourced Application or your possession and use of the App Store Sourced
                            Application, including, but not limited to: (i) product liability
                            claims; (ii) any claim that the App Store Sourced Application fails to
                            conform to any applicable legal or regulatory requirement; and (iii)
                            claims arising under consumer protection or similar legislation.
                        </li>
                    </ul>
                    <p>
                        The JASPR HEALTH trademarks remain property of Jaspr Health, and you agree
                        that your use of the Site, Apps, or Services shall generate no rights in the
                        trademarks.
                    </p>
                    <h3>Indemnity and Hold Harmless</h3>
                    <p>
                        You agree to defend, indemnify and hold harmless Jaspr Health, its corporate
                        affiliates, independent contractors, service providers and consultants, and
                        each of their respective directors, employees and agents, from and against
                        any claims, damages, costs, liabilities and expenses (including, but not
                        limited to, reasonable attorneys' fees) arising out of or related to the
                        site, the materials, the services, your use or inability to use the
                        Services, or any User Content you post including without limitation any
                        actual or threatened suit, demand or claim arising out of or relating
                        thereto, your conduct, your violation of these Service Terms or your
                        violation of the rights of any third party.
                    </p>
                    <p>
                        Jaspr Health, its contributors, their licensors, their suppliers, their
                        respective agents, representatives, affiliates, employees, heirs, and
                        successors in interest, or any third parties mentioned on Jaspr Health
                        services are not liable for any personal injury, including death, caused by
                        your use or misuse of Jaspr Health, its services, or its content. Any claims
                        arising in connection with your use of Jaspr Health Services must be brought
                        within one (1) year of the date of the event giving rise to such action
                        occurred. Remedies under these Terms and Conditions are exclusive and are
                        limited to those expressly provided for in these Terms and Conditions.
                    </p>
                    <h3>Compliance with Laws</h3>
                    <p>
                        You represent and warrant that your use of the Services shall comply with
                        all local, state and federal laws.
                    </p>
                    <h3>Jurisdiction and Dispute Resolution</h3>
                    <p>
                        You expressly agree that exclusive jurisdiction for any dispute over Jaspr
                        Health, its services, or in any way relating to your use of Jaspr Health,
                        resides in the courts of either the State of Washington, and you further
                        agree and expressly consent to the exercise of personal jurisdiction in the
                        courts of State of Washington in connection with any such dispute including
                        any claim involving Jaspr Health, their licensors, their suppliers, their
                        respective agents, representatives, affiliates, employees, heirs, and/or
                        their successors in interest.
                    </p>
                    <p>
                        These Terms and Conditions are governed by the internal substantive laws of
                        the State of Washington, without respect to its conflict of laws principles.
                    </p>
                    <p>
                        In the event of any controversy between the parties, the parties hereto
                        shall consult and attempt to reach a solution satisfactory to both parties.
                        If they fail to do so within a period of thirty (30) days, then either party
                        may, by notice to the other, demand mediation under the mediation rules of
                        the American Arbitration Association. If resolution is not reached within
                        sixty (60) days after service of a written demand for mediation, any
                        unresolved controversy or claim shall be settled by arbitration in
                        accordance with the rules of the American Arbitration Association before a
                        single arbitrator in Seattle, Washington. The arbitrator shall render a
                        written opinion including findings of fact and law and the award and/or
                        determination of the arbitrator shall be binding upon the parties, and their
                        respective administrators and assigns, and shall not be subject to appeal.
                        Judgment may be entered upon the award of the arbitrator in any court of
                        competent jurisdiction. The expenses of the arbitration shall be shared
                        equally by the parties unless the arbitration determines that the expenses
                        shall be otherwise assessed and the prevailing party may be awarded its
                        attorneys' fees and expenses by the arbitrator. It is the intent of the
                        parties that, barring extraordinary circumstances, arbitration proceedings
                        shall be concluded within ninety (90) days from the date the arbitrator is
                        appointed. The arbitrator may extend this time limit only if failure to do
                        so would unduly prejudice the rights of the parties. Failure to adhere to
                        this time limit shall not constitute a basis for challenging the award.
                        Consistent with the expedited nature of arbitration, pre-hearing information
                        exchange shall be limited to the reasonable production of relevant,
                        non-privileged documents, carried out expeditiously. This Section shall not
                        apply to claims concerning the ownership, validity, infringement,
                        misappropriation, disclosure, misuse or enforceability of any confidential
                        information, patent right, copyright, mask work, trademark or any other
                        trade secret or intellectual property. To the fullest extent permitted by
                        applicable law, no arbitration or claim shall be joined to any other
                        arbitration or claim, and no class arbitration proceedings shall be
                        permitted. In no event shall any claim, action or proceeding by you related
                        in any way to the Service be instituted more than two (2) years after the
                        cause of action arose. In the event that any term of this Section
                        (Arbitration) is held to be in conflict with a mandatory provision of
                        applicable law, such conflicting term shall be modified automatically to
                        comply with such provision and the remainder of this Section shall not be
                        affected.
                    </p>
                    <h3>Term and Termination</h3>
                    <p>
                        These Service Terms shall continue until such time as either party
                        terminates the agreement. Upon Termination, the account holder must pay for
                        the Services through the date of termination. Jaspr Health has the right to
                        terminate the Services without cause only by providing thirty (30) days
                        prior notice to the email account listed by you in your user account
                        information. In such event, you shall be refunded any unearned fees paid in
                        advance. You agree and acknowledge that Jaspr Health will deactivate your
                        account and archive your data for a period of five (5) years (or longer if
                        required by law) as necessary to comply with legal obligations, resolve
                        disputes, and enforce our agreements and other authorized uses under these
                        Service Terms and will retain anonymized data for use as described above.
                    </p>
                    <h3>Termination for Cause</h3>
                    <p>
                        Jaspr Health may immediately terminate these Service Terms in the event of
                        your material breach of the terms or conditions of these Service Terms. Any
                        breach of your payment obligations or unauthorized use of your account will
                        be deemed a material breach of these Service Terms.
                    </p>
                    <h3>Assignment; Change in Control</h3>
                    <p>
                        These Service Terms may be assigned in its entirety by the account holder in
                        connection with the sale, transfer or reorganization of all or substantially
                        all of the practice or business to which these Service Terms relates;
                        provided that each of the following conditions are satisfied in full: (a) an
                        authorized representative of the transferor or transferee notifies us in
                        writing of the transfer, the legal name of the transferee, and date of
                        transfer; (b) the transferor or transferee submits to us such written
                        certifications, assurances (which may include a written opinion of your
                        counsel identifying us as beneficiaries entitled to rely on such opinion) or
                        instruments as we, in our sole discretion, may request; and (c) we are
                        satisfied, in our sole discretion, of the validity of the certifications,
                        assurances or instruments submitted pursuant to clause (d). Otherwise, these
                        Service Terms may not be assigned by you without the prior written approval
                        of Jaspr Health, and any assignment without such consent shall be void and
                        of no effect but may be assigned without your consent by Jaspr Health to (i)
                        a parent or subsidiary, (ii) an acquirer of assets, or (iii) a successor by
                        merger.
                    </p>
                    <h3>Waiver, Entire Agreement</h3>
                    <p>
                        No delay or omission by either party hereto to exercise any right or power
                        occurring upon any noncompliance or default by the other party with respect
                        to any of the terms of these Service Terms shall impair any such right or
                        power or be construed to be a waiver thereof. The terms and conditions of
                        these Service Terms may be waived or amended only in writing and only by the
                        party that is entitled to the benefits of the term(s) or condition(s) being
                        waived or amended. Unless stated otherwise, all remedies provided for in
                        these Service Terms shall be cumulative and in addition to and not in lieu
                        of any other remedies available to either party at law, in equity, or
                        otherwise. These Service Terms, together with the Privacy Policy,
                        constitutes the complete and exclusive statement of the agreement between
                        the parties with respect to the use of the Services and any acts or
                        omissions of Jaspr Health and supersedes any and all prior or
                        contemporaneous communications, representations, statements and
                        understandings, whether oral or written, between the parties.
                    </p>
                    <h3>Unenforceability</h3>
                    <p>
                        If any provision of these Service Terms or any word, phrase, clause,
                        sentence, or other portion thereof should be held to be unenforceable or
                        invalid for any reason, then such provision or portion thereof shall be
                        modified or deleted in such manner as to render these Service Terms as
                        modified legal and enforceable to the maximum extent permitted under
                        applicable laws.
                    </p>
                    <h3>Authority</h3>
                    <p>
                        The party entering into these Service Terms hereby acknowledges, represents
                        and warrants that he or she is expressly and duly authorized to enter into
                        these Service Terms and to legally bind said party to these Service Terms.
                        You agree that no joint venture, partnership, employment, or agency
                        relationship exists between you and Jaspr Health.
                    </p>

                    <h3>Notice to California Users</h3>
                    <p>
                        Under California Civil Code Section 1789.3, users of the Services from
                        California are entitled to the following specific consumer rights notice:
                        The Complaint Assistance Unit of the Division of Consumer Services of the
                        California Department of Consumer Affairs may be contacted in writing at
                        1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone
                        at (916) 445-1254 or (800) 952-5210.
                    </p>
                    <h3>Disclaimer of Warranties</h3>
                    <p>
                        Except as stated herein, the service, site, and apps are provided on an “as
                        is” and “as available” basis without warranties of any kind, either express
                        or implied. Except as stated above, Jaspr Health disclaims all warranties,
                        express or implied, including, without limitation, implied warranties of
                        merchantability, fitness for a particular purpose or intellectual property
                        infringement. Jaspr Health does not represent or warrant that the service,
                        app, or any of the information available in or on the site, the, or any
                        other part of the services, are accurate, complete, reliable, current or
                        error-free. Jaspr Health does not represent or warrant that it's servers or
                        any part of the site or service are free of viruses or other harmful
                        components.
                    </p>
                    <h3>Limitation of Liability</h3>
                    <p>
                        In no event shall Jaspr Health or any of its corporate affiliates,
                        independent contractors, service providers or consultants, or any of their
                        respective directors, employees and agents, be liable for any direct,
                        special, indirect or consequential damages, including but not limited to,
                        loss of use, loss of profits or loss of data, whether in an action in
                        contract, tort (including but not limited to negligence) or otherwise,
                        arising out of or in any way related to or connected with the service, site,
                        apps, or the materials contained in or accessed through the service,
                        including without limitation any damages, loss or injury caused by or
                        resulting from reliance on any information obtained from Jaspr Health, the
                        site, or any other part of the service, or that result from mistakes,
                        omissions, interruptions, deletion of files or email, errors, defects,
                        viruses, delays in operation or transmission or any failure of performance,
                        whether or not resulting from acts of god, communications failure, theft,
                        destruction or unauthorized access to Jaspr Health's records, programs or
                        services. The aggregate liability of Jaspr Health, whether in contract,
                        warranty, tort (including negligence, whether active, passive or imputed),
                        product liability, strict liability or any other theory, arising out of or
                        relating in any manner to the use of the site, any other part of the
                        service, shall not exceed any compensation you pay, if any, to Jaspr Health.
                    </p>
                    <p>
                        If you are a California resident, you waive California civil code section
                        1542, which says “a general release does not extend to claims that the
                        creditor or releasing party does not know or suspect to exist in his or her
                        favor at the time of executing the release and that, if known by him or her,
                        would have materially affected his or her settlement with the debtor or
                        released party” or other like provisions of similar import.
                    </p>
                    <h3>Notices</h3>
                    <p>You may contact us by writing or calling us at:</p>
                    <p>
                        Jaspr Health
                        <br />
                        9450 SW Gemini Dr
                        <br />
                        PMB 68735
                        <br />
                        Beaverton, Oregon 97008-7105
                        <br />
                    </p>
                    <p>Phone: (800) 275-1343</p>
                    <h3>Confidentiality</h3>
                    <p>
                        You acknowledge that your use of the Site and/or Service may result in Jaspr
                        Health disclosing certain of its Confidential Information to you.
                        “Confidential Information” refers to certain information that Jaspr Health
                        reasonably regards as proprietary or confidential relating to its business,
                        customers, products, proposed products, plans, inventions, processes and
                        techniques, including without limitation: (i) information, software,
                        designs, text, graphics, pictures, reviews, and sound files implemented or
                        used by Jaspr Health in its products or to support its business operations;
                        (ii) trade secrets, business plans, strategies, methods and/or practices;
                        (iii) computer systems architecture and configurations; (iv) information
                        which is governed by any now-existing or future non-disclosure agreement
                        between you and Jaspr Health; (v) any other information relating to Jaspr
                        Health that is not generally known to the public, including information
                        about personnel, products, customers, financial information, marketing and
                        pricing strategies, services or future business plans; and (vi) any and all
                        analyses, compilations, or notes prepared which contain or are based on any
                        of the above information.
                    </p>
                    <p>
                        You agree to hold in confidence and not use or disclose any Confidential
                        Information except in accordance with this Agreement. You may disclose
                        Confidential Information, on a need-to-know basis, to your personnel who
                        have agreed in writing to non-disclosure terms at least as protective as the
                        provisions of this Section, for purposes permitted in this Agreement,
                        subject to the condition that you shall be liable for their breach of this
                        Section.
                    </p>
                    <p>
                        The obligations set forth in this Section do not apply to Confidential
                        Information that (i) is in or enters the public domain without breach of
                        this Agreement, (ii) the receiving party lawfully receives from a third
                        party without restriction on disclosure and without breach of a
                        nondisclosure obligation, (iii) the receiving party knew prior to receiving
                        such information from the disclosing party or develops independently without
                        access or reference to the Confidential Information, (iv) is disclosed with
                        the written approval of the disclosing party. Notwithstanding the
                        Confidentiality Obligations set forth in this Section, each party may
                        disclose Confidential Information of the other party as permitted by law (i)
                        to the extent required by a court of competent jurisdiction or other
                        governmental authority or otherwise as required by law but only after
                        alerting the other party of such disclosure requirement and, prior to any
                        such disclosure, allowing (where practicable to do so) the other party a
                        reasonable period of time within which to seek a protective order against
                        the proposed disclosure, or (ii) on a “need-to-know” basis under an
                        obligation of confidentiality substantially similar in all material respects
                        to those confidentiality obligations in this Section to its legal counsel,
                        accountants, contractors, consultants, banks and other financing sources.
                    </p>
                    <p>
                        You agree that any unauthorized disclosure of Confidential Information may
                        cause immediate and irreparable injury to Jaspr Health and that, in the
                        event of such breach, Jaspr Health will be entitled, in addition to any
                        other available remedies, to immediate injunctive and other equitable
                        relief, without bond and without the necessity of showing actual monetary
                        damages.
                    </p>
                    <h3>Force Majeure</h3>
                    <p>
                        Except for the payment of fees, if the performance of any part of these
                        Terms by either party is prevented, hindered, delayed, or otherwise made
                        impracticable by reason of any flood, riot, fire, judicial or governmental
                        action, labor disputes, act of God, or any other causes beyond the control
                        of either party, that party shall be excused from such to the extent that it
                        is prevented, hindered, or delayed by such causes.
                    </p>
                    <p>
                        PLEASE PRINT A COPY OF THESE SERVICE TERMS FOR YOUR RECORDS AND PLEASE CHECK
                        BACK FREQUENTLY FOR ANY CHANGES TO THESE SERVICE TERMS.
                    </p>

                    <div className={styles.buttonGroup}>
                        <div className={styles.outlinedButton} onClick={onClickClose}>
                            close
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export { TermsServiceModal };
export default TermsServiceModal;
