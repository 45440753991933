import { useRef, useState } from 'react';
import useClickOutside from 'lib/useClickOutside';
import styles from './index.module.scss';

interface TooltipProps {
    tip: string;
}

const Tooltip = ({ tip }: TooltipProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    useClickOutside(containerRef, () => setShowTooltip(false), showTooltip);
    return (
        <div className={styles.tooltip} ref={containerRef}>
            <div className={styles.tipIcon} onMouseDown={() => setShowTooltip(true)}>
                i
            </div>
            {showTooltip && (
                <div className={styles.container}>
                    <div className={styles.text}>{tip}</div>
                    <button className={styles.close} onClick={() => setShowTooltip(false)}>
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
