import { useContext, useEffect } from 'react';
import { getStoriesVideos } from 'state/actions/stories';
import StoreContext from 'state/context/store';
import { StoriesReducerState } from 'state/reducers/stories';

type IUseSharedStoriesReturn = StoriesReducerState;

const useSharedStories = (): IUseSharedStoriesReturn => {
    const [store, dispatch] = useContext(StoreContext);
    const { stories } = store;

    useEffect(() => {
        if (!stories.storiesFetched) {
            getStoriesVideos(dispatch);
        }
    }, [dispatch, stories.storiesFetched]);

    return stories;
};

export default useSharedStories;
