import axios, { AxiosError, AxiosResponse } from 'axios';
import { errorInterceptor } from 'lib/api';
import config from 'config';
import { Dispatch, Interaction } from 'state/types';
import { InteractionsConstants } from 'state/constants';

export const saveInteraction = async (
    dispatch: Dispatch,
    interaction: Interaction,
): Promise<AxiosResponse | undefined> => {
    axios.interceptors.response.use(...errorInterceptor(dispatch));

    try {
        dispatch({
            type: InteractionsConstants.SET_INTERACTION,
            interaction,
        }); // Speed up UI.  TODO undo if request fails
        const response = await axios.post(
            `${config.apiRoot}/patient/patient-interactions`,
            interaction,
        );
        const json = response.data;
        dispatch({
            type: InteractionsConstants.SET_INTERACTION,
            interaction: json,
        });
        return response;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
            return axiosError.response;
        }
    }
};
