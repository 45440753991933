import { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { getInteractions, saveInteraction } from 'state/actions/interactions';
import { InteractionsConstants } from 'state/constants';
import StoreContext from 'state/context/store';
import { Interaction, Interactions, Patient } from 'state/types';

type IUseInteractionsReturn = [
    interactions: Interactions,
    saveInteraction: (interaction: Interaction) => Promise<AxiosResponse | undefined>,
    fetched: boolean,
];

const useInteractions = (): IUseInteractionsReturn => {
    const [store, dispatch] = useContext(StoreContext);
    const { interactions, fetched } = store.interactions;
    const { isDemoPatient, sessionLocked } = store.user as Patient;
    const [fetching, setFetching] = useState(false);

    const setInteraction = (interaction: Interaction) => {
        return saveInteraction(dispatch, interaction);
    };

    useEffect(() => {
        if (!fetching && !fetched && !isDemoPatient && !sessionLocked) {
            setFetching(true);
            getInteractions(dispatch).finally(() => setFetching(false));
        } else if (!fetched && isDemoPatient) {
            // Make sure fetched get's set to true
            dispatch({
                type: InteractionsConstants.SET_INTERACTIONS,
                interactions: [],
            });
        }
    }, [dispatch, fetched, fetching, isDemoPatient, sessionLocked]);

    return [interactions, setInteraction, fetched];
};

export default useInteractions;
