import { useMemo } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {
    AnswerKeyType,
    Questions,
    ActionType,
    SliderType,
    UIDType,
} from 'components/ConversationalUi/questions';
import { AssessmentAnswers } from 'state/types';
import styles from './slider.module.scss';

interface ChoiceQuestionProps {
    title: string;
    answers: Partial<AssessmentAnswers>;
    setAnswers: (answers: Partial<AssessmentAnswers>) => void;
    uid: UIDType;
    answerKey: AnswerKeyType;
    questions: Questions;
}

const SliderQuestion = ({
    title,
    answers,
    setAnswers,
    uid,
    answerKey,
    questions,
}: ChoiceQuestionProps) => {
    const matchedAction = useMemo(() => {
        const question = questions.find((question) => question.uid === uid);
        if (!question) {
            return undefined;
        }

        const isSliderType = (action: ActionType): action is SliderType => action.type === 'slider';

        return question.actions.find(
            (action) => isSliderType(action) && action.answerKey === answerKey,
        ) as SliderType | undefined;
    }, [answerKey, questions, uid]);

    const answer: number = answers[answerKey];

    if (!matchedAction) {
        return null;
    }

    const marks: Record<number, React.ReactNode> = {
        [matchedAction.min]: <div className={styles.mark} />,
        [matchedAction.max]: <div className={styles.mark} />,
    };

    if (answer !== null) {
        marks[answer] = {
            style: {
                color: '#3B6975',
                fontSize: '20px',
                fontWeight: 'bold',
                letterSpacing: 1.5,
                top: -51,
            },
            label: answer,
        };
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.sliderContainer}>
                <div className={styles.end}>
                    <span className={styles.value}>{matchedAction.min}</span>
                    <span className={styles.label}>{matchedAction.minLabel}</span>
                </div>
                <Slider
                    min={matchedAction.min}
                    max={matchedAction.max}
                    step={matchedAction.step}
                    defaultValue={0}
                    value={answer || 0}
                    trackStyle={{ backgroundColor: 'rgba(0,0,0,.59)', height: 2 }}
                    railStyle={{ backgroundColor: 'rgba(0,0,0,.59)', height: 2 }}
                    marks={marks}
                    handleStyle={{
                        height: 28,
                        width: 6,
                        backgroundColor: '#4E77A2',
                        borderRadius: 7,
                        marginTop: -13,
                        marginLeft: 0,
                        border: 'none',
                    }}
                    dotStyle={{
                        width: '8px',
                        height: '8px',
                        borderColor: '#979797',
                        backgroundColor: '#4E77A2',
                        top: '-3px',
                    }}
                    onChange={(value: number | number[]) => {
                        const answerValue = Array.isArray(value) ? value[0] : value;
                        setAnswers({
                            ...answers,
                            [answerKey]: answerValue,
                        });
                    }}
                />
                <div className={styles.end}>
                    <span className={styles.value}>{matchedAction.max}</span>
                    <span className={styles.label}>{matchedAction.maxLabel}</span>
                </div>
            </div>
        </div>
    );
};

export { SliderQuestion };
export default SliderQuestion;
