import { useContext, useEffect } from 'react';
import { getLocations } from 'state/actions/user';
import StoreContext from 'state/context/store';
import { Departments, Technician } from 'state/types';

type IUseSLocationsReturn = Departments;

const useLocations = (): IUseSLocationsReturn | undefined => {
    const [store, dispatch] = useContext(StoreContext);
    const { user } = store;
    const { locations, userType, token } = user as Technician;

    useEffect(() => {
        if (userType === 'technician' && token && !locations) {
            getLocations(dispatch);
        }
    }, [dispatch, locations, token, userType]);

    return locations;
};

export default useLocations;
