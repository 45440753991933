import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'lib/helpers';
import StoreContext from 'state/context/store';
import styles from './index.module.scss';
import EditPatientIcon from 'assets/edit-patient.svg';
import TrainingIcon from './training.icon';
import { Technician } from 'state/types';

type PatientInfoProps = {
    id: number;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    mrn?: string;
    departments: number[];
};

const PatientInfo = ({
    id,
    firstName,
    lastName,
    mrn,
    dateOfBirth,
    departments,
}: PatientInfoProps) => {
    const history = useHistory();
    const [store] = useContext(StoreContext);
    const { device, user } = store;
    const { inPatientContext } = device;
    const { locations } = user as Technician;

    return (
        <div className={styles.patientInfo}>
            <div className="typography--h4">
                {firstName} {lastName}
                {locations?.find((location) => location.id === departments?.[0])
                    ?.isTrainingDepartment && (
                    <span
                        className={styles.training}
                        data-tooltip-id="tooltip"
                        data-tooltip-html="This badge indicates that the<br />patient is in the Training<br />department. "
                    >
                        <TrainingIcon />
                    </span>
                )}
            </div>
            <div className={`typography--body3 ${styles.detail}`}>
                MRN: {mrn} &#183; DOB: {formatDate(dateOfBirth)}
                {!inPatientContext && (
                    <img
                        style={{
                            marginLeft: 5,
                            cursor: 'pointer',
                        }}
                        tabIndex={0}
                        aria-label="Edit the patient"
                        onClick={() => history.push(`/technician/patients/${id}/edit`)}
                        onKeyDown={(e) => {
                            if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
                                history.push(`/technician/patients/${id}/edit`);
                            }
                        }}
                        src={EditPatientIcon}
                        alt="Edit Patient"
                    />
                )}
            </div>
        </div>
    );
};

export default PatientInfo;
