import { useContext } from 'react';
import { Video } from 'state/types';
import StoreContext from 'state/context/store';
import { ReactComponent as Heart } from 'assets/heart.svg';
import { ReactComponent as HeartFilled } from 'assets/heartFilled.svg';
import styles from './index.module.scss';
import useInteraction from 'lib/useInteraction';

interface StoryTabProps {
    label: string;
    setSelectedVideo: (video: Video | null) => void;
    video: Video;
}

const StoryTab = (props: StoryTabProps) => {
    const { label, setSelectedVideo, video } = props;
    const [store] = useContext(StoreContext);
    const { fetched: interactionsFetched } = store.interactions;
    const [interaction, saveInteraction] = useInteraction(video?.id.toString());

    const saved = interaction?.saveForLater || false;
    const progress = interaction?.progress || null;

    const save = (): void => {
        saveInteraction({
            saveForLater: !interaction.saveForLater,
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div
                    className={styles.name}
                    onClick={() => setSelectedVideo(video)}
                    style={{ width: '190.5px' }}
                >
                    <span
                        className={styles.nameText}
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            lineHeight: '20px',
                        }}
                    >
                        {label} ›
                    </span>
                </div>
                {interactionsFetched && (
                    <div className={styles.save} onClick={save} style={{ cursor: 'pointer' }}>
                        {!saved && <Heart />}
                        {saved && <HeartFilled />}
                    </div>
                )}
            </div>
            <div className={styles.progress}>
                <div className={styles.track} style={{ width: `${progress || 0}%` }} />
            </div>
        </div>
    );
};

export default StoryTab;
