import { AssessmentAnswers } from 'state/types';
import styles from './index.module.scss';
import { AnswerKeyType } from 'components/ConversationalUi/questions';

interface QuestionSliderProps {
    action: {
        answerKey: AnswerKeyType;
    };
    question: { providerLabel?: string };
    assessment: AssessmentAnswers;
}

const QuestionSlider = ({ action, assessment, question }: QuestionSliderProps) => {
    return (
        <div className={styles.questionSlider}>
            <div className={styles.question}>{question.providerLabel}</div>
            <div className={styles.answer}>{assessment[action.answerKey]}</div>
        </div>
    );
};

export default QuestionSlider;
