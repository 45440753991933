import styles from './index.module.scss';

const OfflineModal = (): JSX.Element => {
    return (
        <div className={styles.offlineModal}>
            Your device is currently offline.
            <br />
            Your session will resume when your internet connection is restored.
        </div>
    );
};

export default OfflineModal;
