import React, { useCallback, useEffect } from 'react';
import { useQuestion } from '../../hooks/useQuestion';
import styles from './index.module.scss';
import { QuestionProps } from '../../question';

type PersonProps = Pick<QuestionProps, 'setAnswered'> & {
    personIndex: number;
    name: string;
    phone: string;
    setAnswer: (
        answer: { phone: string; name: string; type?: 'family' | 'distract' | 'professional' }[],
    ) => void;
    updatedAnswer: { phone: string; name: string; type?: 'family' | 'distract' | 'professional' }[];
};

const Person = (props: PersonProps) => {
    const { personIndex, name, phone, setAnswered, setAnswer, updatedAnswer } = props;

    const setName = (name: string): void => {
        const updatedAnswers = [...updatedAnswer];
        updatedAnswers[personIndex].name = name;
        setAnswer(updatedAnswers);
        setAnswered(false);
    };

    const setPhone = (phone: string): void => {
        const updatedAnswers = [...updatedAnswer];
        updatedAnswers[personIndex].phone = phone;
        setAnswer(updatedAnswers);
        setAnswered(false);
    };

    const remove = (): void => {
        const updatedAnswers = [...updatedAnswer];
        updatedAnswers.splice(personIndex, 1);
        setAnswer(updatedAnswers);
        setAnswered(false);
    };

    return (
        <div className={styles.row}>
            <div className={styles.person}>
                <input
                    value={name}
                    placeholder="Type name here"
                    onChange={(e) => setName(e.target.value)}
                    maxLength={45}
                />
                <input
                    value={phone}
                    placeholder="Type number here"
                    onChange={(e) => setPhone(e.target.value)}
                    maxLength={21}
                />
            </div>
            <div className={styles.close} onClick={remove}>
                &times;
            </div>
        </div>
    );
};

type SupportivePeopleQuestionProps = Pick<
    QuestionProps,
    'answered' | 'answerKey' | 'setAnswered' | 'answers'
> & { peopleType?: 'family' | 'distract' | 'professional' };

const SupportivePeopleQuestion = (props: SupportivePeopleQuestionProps) => {
    const { answered, answerKey = '', setAnswered, answers, peopleType = 'family' } = props;
    const defaultValue = [{ phone: '', name: '', type: peopleType }];
    const [, updatedAnswer, setAnswer, saveAnswer] = useQuestion<
        {
            phone: string;
            name: string;
            type: 'family' | 'distract' | 'professional';
        }[]
    >(answerKey, defaultValue, answers, false);

    const addPerson = useCallback(() => {
        setAnswer([...updatedAnswer, { phone: '', name: '', type: peopleType }]);
        setAnswered(false);
    }, [setAnswer, updatedAnswer, peopleType, setAnswered]);

    useEffect(() => {
        // Make sure there is at least one row by default to fill out
        if (updatedAnswer?.filter((person) => person.type === peopleType).length === 0) {
            addPerson();
        }
    }, [addPerson, updatedAnswer, peopleType]);

    useEffect(() => {
        if (answered) {
            // TODO Can't save if phone and name are empty.  Will need to filter out
            saveAnswer();
        }
    }, [answered, saveAnswer, updatedAnswer]);

    return (
        <div className={styles.container}>
            <div className={`${styles.row} ${styles.header}`}>
                <span>Name</span>
                <span>Phone Number</span>
            </div>
            {updatedAnswer.map((person, personIndex) => (
                <React.Fragment key={`person-${personIndex}`}>
                    {person.type === peopleType && (
                        <Person
                            {...props}
                            {...person}
                            personIndex={personIndex}
                            updatedAnswer={updatedAnswer}
                            // @ts-ignore
                            setAnswer={setAnswer}
                        />
                    )}
                </React.Fragment>
            ))}

            <div className={styles.addButton} onClick={addPerson}>
                Add another
            </div>
        </div>
    );
};

export { SupportivePeopleQuestion };
export default SupportivePeopleQuestion;
