import { InteractionsConstants } from 'state/constants';
import { Interaction, Interactions } from 'state/types';
import { ActionSetInteraction, ActionSetInteractions } from 'state/types/actions';

export type SkillsReducerType = ActionSetInteraction | ActionSetInteractions;

export type InteractionReducerState = {
    fetched: boolean;
    interactions: Interactions;
};

const initialState: InteractionReducerState = {
    fetched: false,
    interactions: [],
};

const InteractionsReducer = (
    state: InteractionReducerState = initialState,
    action: SkillsReducerType,
) => {
    const saveInteraction = (interactions: Interactions, interaction: Interaction) => {
        const updatedInteractions = [...interactions];
        const idx = interactions.findIndex(
            (originalInteraction) => originalInteraction.uid === interaction.uid,
        );
        if (idx > -1) {
            updatedInteractions[idx] = { ...updatedInteractions[idx], ...interaction };
        } else {
            updatedInteractions.push(interaction);
        }

        return updatedInteractions;
    };
    switch (action.type) {
        case InteractionsConstants.SET_INTERACTIONS:
            return {
                fetched: true,
                interactions: action.interactions,
            };
        case InteractionsConstants.SET_INTERACTION:
            return {
                ...state,
                interactions: saveInteraction(state.interactions, action.interaction),
            };
        default:
            return state;
    }
};

export { InteractionsReducer, initialState };
