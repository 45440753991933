import { Route, Redirect, Switch } from 'react-router-dom';
import PatientList from 'pages/technician/patients';
import Patient from 'pages/technician/patient';

import FreshdeskSSO from 'pages/technician/freshdesk/sso';
import useTechnicianIdleTimer from 'lib/idleTimer/technician';

const TechnicianRouter = () => {
    useTechnicianIdleTimer();

    return (
        <Switch>
            <Route
                path="/"
                exact
                render={() => (
                    <Redirect
                        to={{
                            pathname: '/technician/patients',
                        }}
                    />
                )}
            />
            <Route path="/technician/patients/:patientId" component={Patient} />
            <Route path="/technician/patients" component={PatientList} />
            <Route path="/freshdesk-sso" component={FreshdeskSSO} />
            <Route path="*" render={() => <Redirect to="/technician/patients" />} />
        </Switch>
    );
};

export default TechnicianRouter;
