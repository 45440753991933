import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import Checkbox from 'components/Checkbox';
import JasprModal from 'components/Modal';
import { Patient } from 'state/types';
import { setCurrentEncounterConsent, logout } from 'state/actions/user';
import StoreContext from 'state/context/store';
import Button from 'components/Button';
import logo from 'assets/logo.svg';
import styles from './index.module.scss';
import { PatientSerializer } from 'state/types/api/sharedSerializers';

const formatDate = (date: string) => {
    const processedDate = DateTime.fromISO(date);
    if (!processedDate.isValid) return '';
    return processedDate.toLocaleString(DateTime.DATE_MED);
};

interface ConfirmationProps {
    patient: PatientSerializer;
    technicianOperated: boolean;
    setPatient: (patient: PatientSerializer | null) => void;
}

const Confirmation = ({ patient, technicianOperated, setPatient }: ConfirmationProps) => {
    const [store, dispatch] = useContext(StoreContext);
    const [showConsentModal, setShowConsentModal] = useState(false);
    const [hasProvidedConsent, setHasProvidedConsent] = useState(false);
    const history = useHistory();

    const disableConfirmButton =
        patient?.requiresMinorConsent && !patient.acquiredConsentAt && !hasProvidedConsent;

    const onClickConfirm = () => {
        if (patient?.requiresMinorConsent) {
            setCurrentEncounterConsent(dispatch, true);
        }
        history.replace('/');
    };

    useEffect(() => {
        setHasProvidedConsent(!!patient?.acquiredConsentAt);
    }, [patient]);

    return (
        <>
            <JasprModal style={{ content: { maxHeight: '90%' } }} isOpen={showConsentModal}>
                <div style={{ overflowY: 'auto' }}>
                    <p style={{ whiteSpace: 'pre-line' }}>
                        {(store.user as Patient)?.minorConsentLanguage || ''}
                    </p>
                </div>
                <Button onClick={() => setShowConsentModal(false)}>close</Button>
            </JasprModal>
            <div className={styles.verification}>
                <section className={styles.section}>
                    <img src={logo} alt="" />
                    <h3>Verify Patient Information</h3>
                    <div className={styles.details}>
                        <div className={styles.row}>
                            <div className={styles.column}>
                                <span className={`${styles.field} typography--h5`}>
                                    Name of Patient
                                </span>
                                <span className={styles.value}>
                                    {patient.firstName} {patient.lastName}{' '}
                                    {!patient.firstName && !patient.lastName && '-'}
                                </span>
                            </div>
                            <div className={styles.column}>
                                <span className={`${styles.field} typography--h5`}>MRN</span>
                                <span className={styles.value}>{patient.mrn}</span>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.column}>
                                <span className={`${styles.field} typography--h5`}>
                                    Date of Birth
                                </span>
                                <span className={styles.value}>
                                    {formatDate(patient.dateOfBirth || '') || '-'}
                                </span>
                            </div>

                            <div className={styles.column}>
                                <span className={`${styles.field} typography--h5`}>Clinic</span>
                                <span
                                    className={styles.value}
                                >{`${patient.location?.clinic?.name} - ${patient.location?.department?.name}`}</span>
                            </div>
                        </div>
                        {patient.requiresMinorConsent && (
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <Checkbox
                                        disabled={!!patient?.acquiredConsentAt}
                                        onChange={() => {
                                            setHasProvidedConsent(
                                                (hasProvidedConsent) => !hasProvidedConsent,
                                            );
                                        }}
                                        label="Parental Consent Obtained"
                                        checked={hasProvidedConsent || !!patient?.acquiredConsentAt}
                                    />
                                    {!!patient?.acquiredConsentAt && (
                                        <span style={{ fontStyle: 'italic', color: 'lightgray' }}>
                                            {new Date(patient.acquiredConsentAt).toLocaleDateString(
                                                'en-us',
                                                {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric',
                                                },
                                            )}
                                        </span>
                                    )}
                                    <br />

                                    <span
                                        onClick={() =>
                                            setShowConsentModal(
                                                (showConsentModal) => !showConsentModal,
                                            )
                                        }
                                        className="typography--body2"
                                        style={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        view consent
                                    </span>
                                </div>
                                <div className={styles.column}></div>
                            </div>
                        )}

                        {technicianOperated && (
                            <div className={styles.row}>
                                <div className={styles.column}>
                                    <h6 style={{ margin: 0 }}>
                                        Going directly to Safety/Stability Planning
                                    </h6>
                                    <span
                                        className="typography--body2"
                                        style={{ color: 'rgba(184, 188, 204, 1)' }}
                                    >
                                        Patient will not use tablet
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className={styles.buttons}>
                            <button
                                className={styles.textButton}
                                onClick={() => {
                                    logout(dispatch);
                                    setPatient(null);
                                }}
                            >
                                Cancel
                            </button>
                            <Button
                                disabled={disableConfirmButton || false}
                                onClick={onClickConfirm}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </section>
                <section className={`${styles.section} ${styles.prompts}`}>
                    <h3>Jaspr Health Script</h3>
                    <span className="typography--h5">
                        Suggested Prompts for Introducing the App
                    </span>
                    <ol>
                        <li>"We're so glad you're here."</li>
                        <li>"This may be a while."</li>
                        <li>"Here is what you've told me."</li>
                        <li>"I think Jaspr may help."</li>
                        <li>"Would you like to give it a try?"</li>
                    </ol>
                </section>
            </div>
        </>
    );
};

export default Confirmation;
